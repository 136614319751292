import React from "react";
import type { NavInvoiceScreenParams } from "../features/Api";
import api from "../features/Api";
import "./InvoiceRosterViewLegendAirways.css";
import type { ImpersonalSalaryJournalRecord } from "../features/ExternalRostersApi";
import NavJournalRecordsTable from "./NavJournalRecordsTable";
import { HttpResponseError } from "../features/httpUtils";

function rebaseLalLinks(element: Element) {
    const src = element.getAttribute("src");
    if (src && src.startsWith("/")) {
        element.setAttribute("src", "https://lal.leon.aero" + src);
    }
    for (const child of element.children) {
        rebaseLalLinks(child);
    }
}

type Props = {
    locator: NavInvoiceScreenParams,
    onWarning: (message: string) => void,
};

type State = {
    rosterTable: null | HTMLTableElement,
    loading: boolean,
    journalRecords: ImpersonalSalaryJournalRecord[] | null,
    expectedError: string | null,
};

export default class InvoiceRosterViewLegendAirlines extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            rosterTable: null,
            loading: true,
            journalRecords: null,
            expectedError: null,
        };
        api.Roster.getLalRoster(this.props.locator).then(roster => {
            const div = document.createElement("div");
            div.innerHTML = roster.tableHtml;
            const table = div.children[0] as HTMLTableElement;
            if (table) {
                rebaseLalLinks(table);
                this.setState({
                    rosterTable: table,
                    journalRecords: roster.journalRecords,
                });
            } else {
                this.props.onWarning("Failed to extract table from Leon response");
            }
        }).catch(async error => {
            if (error instanceof HttpResponseError &&
                error.response.status === 406
            ) {
                this.setState({ expectedError: error.baseMessage });
            } else {
                this.props.onWarning(String(error));
            }
        }).finally(() => this.setState({ loading: false }));
    }

    render() {
        if (this.state.loading) {
            return <div className="status-loading-animated-ellipsis" style={{ textAlign: "center" }}>
                Roster Data Loading
            </div>;
        } else if (this.state.rosterTable) {
            return <div>
                <div
                    className="lal-roster-table-wrapper"
                    dangerouslySetInnerHTML={{ __html: this.state.rosterTable.outerHTML }}
                ></div>
                {!this.state.journalRecords || this.state.journalRecords.length === 0 ? undefined : <div className="preliminary-salary-journal-from-roster">
                    <h4 style={{ textAlign: "center" }}>Preliminary fees calculated from Leon Roster</h4>
                    <NavJournalRecordsTable calculatedPeople={[{
                        person: { navEntry: { No_: this.props.locator.employeeCode } },
                        journalRecords: this.state.journalRecords.map(jr => ({ ...jr, crew_code: this.props.locator.employeeCode })),
                    }]} />
                </div>}
            </div>;
        } else if (this.state.expectedError) {
            return <div style={{ textAlign: "center" }}>{this.state.expectedError}</div>;
        } else {
            return <div className="content-encountered-errors">Encountered errors during Roster data retrieval:</div>;
        }
    }
}
