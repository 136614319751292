import * as React from "react";
import type { InvoiceAction } from "../types/Api/Invoice";
import * as _ from "lodash";
import ErrorPage from "./ErrorPage";
import InvoiceMenu from "../components/InvoiceMenu";
import InvoiceUserTimesheet from "../components/InvoiceUserTimesheet";
import { ClipLoader } from "react-spinners";
import { getMonthFullName } from "../utils/dates";
import SubmitManualWorkInvoiceReminderModal from "../components/SubmitManualWorkInvoiceReminderModal";
import { type AllCompanies, getAllCompanies } from "../features/StaticData";
import api from "../features/Api";
import { GetInvoiceStatusClass } from "../utils/Helper";
import type { InvoiceStatusStr } from "../features/payrollUtils";
import { InvoiceStatus } from "../features/payrollUtils";
import { entries } from "../utils/typing";

export const views = {
  Table: 0,
  Form: 1
} as const;

type Props = {
  code: string,
  invoicesPerPage: "10",
  isAdmin: "True" | "False" | "",
  Fields?: unknown,
};

// should be inferred automaticlly
type State = {
  openReminderModal: boolean,
  invoiceId?: number,
  nameOrCrewcode?: never,
  nameDeptCrewcode: string,
  madeFirstCall: boolean,
  currentPage: number,
  employeesPerPage: number,
  invoiceMonth: number,
  invoiceYear: number,
  invoiceCompanyId: number | undefined,
  refreshing: boolean,
  Fields: Props["Fields"],
  sortBy: {
    column: "" | keyof InvoiceAction,
    order: "desc" | "asc",
  },
  allCompanies: null | AllCompanies,
  invoiceActions: null | InvoiceAction[],
  tickedStatuses: InvoiceStatusStr[],
};

const DEFAULT_MONTH = new Date().getMonth() > 0 ? new Date().getMonth() : 12;
const DEFAULT_YEAR = new Date().getMonth() > 0 ? new Date().getFullYear() : new Date().getFullYear() - 1;

export default class InvoicesUsersTimeSheets extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    require("./FormFields.css");
    require("./InvoicesUsersTimeSheets.css");
    this.state = {
      openReminderModal: false,
      nameDeptCrewcode: "",
      madeFirstCall: false,
      currentPage: 1,
      employeesPerPage: 10,
      invoiceMonth: DEFAULT_MONTH, // show previous month by default
      invoiceYear: DEFAULT_YEAR,
      invoiceCompanyId: undefined,
      refreshing: false,
      Fields: props.Fields,
      sortBy: {
        column: "",
        order: "asc"
      },
      allCompanies: null,
      invoiceActions: null,
      tickedStatuses: [],
    };
  };

  componentDidMount() {
    getAllCompanies().then(allCompanies => this.setState({ allCompanies }));
    const payload = {
      year: this.state.invoiceYear,
      month: this.state.invoiceMonth,
    };
    this.setState({ invoiceActions: null });
    api.Invoice.GetInvoicesForDashboard(payload)
        .then(invoiceActions => this.setState({ invoiceActions }));
    if (!this.state.madeFirstCall)
      this.setState({
        madeFirstCall: true,
      });
  };

  order(employees: InvoiceAction[]) {
    if (this.state.sortBy.column != "") {
      return _.orderBy(employees, [this.state.sortBy.column], [this.state.sortBy.order]);
    }

    return employees;
  };

  handleSort(e: React.MouseEvent<HTMLTableHeaderCellElement>) {
    const headerId = e.currentTarget.id;

    this.setState({
      sortBy: {
        column: headerId.slice(2) as keyof InvoiceAction,
        order: this.state.sortBy.order == "desc" ? "asc" : "desc"
      }
    });

    if (e.currentTarget.parentElement) {
      _.forEach(e.currentTarget.parentElement.children, function (c) {
        c.className = "";
      });
    }

    e.currentTarget.className = this.state.sortBy.order == "desc" ? "asc" : "desc";
  };

  _createInvoiceMenu() {
    return (<InvoiceMenu selected="List" />);
  };

  get navCompanyName() {
    return Object.values(this.state.allCompanies ?? {}).find(allCompany => {
      return allCompany.portalEntry && allCompany.portalEntry.Id === this.state.invoiceCompanyId;
    })?.COMPANYNAME;
  }

  _populateInvoiceMonthDropdown() {
    const self = this;

    const data = [];
    for (let i = 1; i <= 12; i++) {
      data.push(<option key={i} value={i}>{self._getMonthName(i)}</option>);
    }

    return data;
  };

  _getMonthName(month: number) {
    return !month ? "" : getMonthFullName(month);
  };

  _populateInvoiceYearDropdown() {

    const data = [];
    const thisYear = new Date().getFullYear();
    for (let i = thisYear; i >= thisYear - 10; i--) {
      data.push(<option key={i} value={i}>{i}</option>);
    }

    return data;
  };

  _populateCompanyDropdown() {
    const data = [];

    data.push(<option key={"Select Client"} value={0}>Select Client</option>);
    if (this.state.allCompanies) {
      for (const allCompany of Object.values(this.state.allCompanies)) {
        if (!allCompany.portalEntry || !allCompany.portalEntry.IsActive) {
          continue;
        }
        data.push(<option key={allCompany.COMPANYNAME}
          value={allCompany.portalEntry.Id}>
          {allCompany.navEntry?.DisplayName ?? allCompany.COMPANYNAME}
        </option>);
      }

    }

    return data;
  };

  _updateInvoiceMonthFilter(e: React.ChangeEvent<HTMLSelectElement>) {
    this.setState({ invoiceMonth: parseInt(e.target.value) });
    this._refreshInvoiceTable(this.state.invoiceYear, parseInt(e.target.value), this.state.invoiceCompanyId);
  };

  _updateInvoiceYearFilter(e: React.ChangeEvent<HTMLSelectElement>) {
    this.setState({ invoiceYear: parseInt(e.target.value) });
    this._refreshInvoiceTable(parseInt(e.target.value), this.state.invoiceMonth, this.state.invoiceCompanyId);
  };

  _updateInvoiceCompanyFilter(e: React.ChangeEvent<HTMLSelectElement>) {
    this.setState({ invoiceCompanyId: e.target.value ? parseInt(e.target.value) : undefined });
    this._refreshInvoiceTable(this.state.invoiceYear, this.state.invoiceMonth, Number(e.target.value));
  };

  private async _refreshInvoiceTable(year: number, month: number, companyId: number | undefined) {
    this.setState({
      currentPage: 1,
      invoiceId: undefined,
      refreshing: true,
      invoiceActions: null,
    });
    try {
      const invoiceActions = companyId
        ? await api.Invoice.GetInvoicesForDashboardByCompany({ year, month, companyId })
        : await api.Invoice.GetInvoicesForDashboard({ year, month });
      this.setState({ invoiceActions });
    } finally {
      this.setState({
        refreshing: false,
      });
    }
  };

  _filterByNameCrewDeptcode(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ nameDeptCrewcode: e.target.value });
  };

  _generateFilters(invoiceActions: InvoiceAction[]) {
    const statusToCount: Partial<Record<InvoiceStatusStr, number>> = {};
    for (const invoiceAction of invoiceActions) {
      const oldValue = statusToCount[invoiceAction.StatusStr] ?? 0;
      statusToCount[invoiceAction.StatusStr] = oldValue + 1;
    }

    return <div>
      <div className="SelectFilter">
        {/* {this.state.localProfile.IsAdmin && this.state.localProfile.IsManager ? this._generateFilterByInvoiceCompany() : null} */}

        <div className="option">
          <label className="dropdown-label" htmlFor="payroll-month-dropdown">Payroll Month</label>
          <div className="material-dropdown">
            <select id="payroll-month-dropdown" disabled={this.state.refreshing} onChange={(e) => this._updateInvoiceMonthFilter(e)} value={this.state.invoiceMonth}>
              {this._populateInvoiceMonthDropdown()}
            </select>
          </div>
        </div>

        <div className="option">
          <label className="dropdown-label" htmlFor="year-dropdown">Year</label>
          <div className="material-dropdown">
            <select id="year-dropdown" disabled={this.state.refreshing} onChange={(e) => this._updateInvoiceYearFilter(e)} value={this.state.invoiceYear}>
              {this._populateInvoiceYearDropdown()}
            </select>
          </div>
        </div>

        <div className="option">
          <label className="dropdown-label" htmlFor="client-dropdown">Client</label>
          <div className="material-dropdown">
            <select id="client-dropdown" disabled={this.state.refreshing} onChange={(e) => this._updateInvoiceCompanyFilter(e)} value={this.state.invoiceCompanyId ?? ""}>
              {this._populateCompanyDropdown()}
            </select>
          </div>
        </div>

        <div className="select search searchBox">
          <input type="text" disabled={this.state.refreshing} placeholder="Search" value={this.state.nameOrCrewcode}
            onChange={(e) => this._filterByNameCrewDeptcode(e)} />
        </div>

        {this.state.invoiceCompanyId && <button type="button" onClick={() => this.setState({ openReminderModal: true })} className="open-send-reminder-modal-button">
          Send Submit Manual Work Invoice Reminder
        </button>}
      </div>
      <ul className="filter-statuses-list">
        {entries(statusToCount).map(([status, count]) => <li key={status}>
          <label>
            <input type="checkbox" style={{ margin: "unset" }} checked={this.state.tickedStatuses.includes(status)} onChange={e => {
              const newState = new Set(this.state.tickedStatuses);
              if (e.target.checked) {
                  newState.add(status);
              } else {
                  newState.delete(status);
              }
              this.setState({ tickedStatuses: [...newState] });
            }}/>
            <span className={GetInvoiceStatusClass((InvoiceStatus as Record<InvoiceStatusStr, InvoiceStatus>)[status])}>{status}</span>
            <span> ({count})</span>
          </label>
        </li>)}
      </ul>
    </div>;
  };

  _ShowTimeSheets(invoice: InvoiceAction) {
    if (invoice.InvoiceId <= 0) {
      throw new Error("Selected invoice has no ID, please report to tech support: " + JSON.stringify(invoice));
    }
    this.setState({ invoiceId: invoice.InvoiceId });
  };

  ShowList() {
    this.setState({ invoiceId: undefined });
  };

  hasAdminPrivileges() {
    return this.props.isAdmin === "True";
  };

  closeReminderModal() {
    this.setState({ openReminderModal: false });
  };

  render() {
    if (!this.hasAdminPrivileges()) {
      return <ErrorPage />;
    }
    if (!this.state.invoiceActions) {
      return <div className="col-xs-12 center">
        {<ClipLoader
            loading={true}
        />}
      </div>;
    }
    let orderInvoiceActions = this.order(this.state.invoiceActions);
    orderInvoiceActions = orderInvoiceActions.filter((d: InvoiceAction) => {
      return d?.Employee.toUpperCase().includes(this.state.nameDeptCrewcode.toUpperCase())
          || d.EmployeeCode?.toUpperCase().includes(this.state.nameDeptCrewcode.toUpperCase())
          || d.StatusStr?.toUpperCase().includes(this.state.nameDeptCrewcode.toUpperCase())
          || d.JobTitle?.toUpperCase().includes(this.state.nameDeptCrewcode.toUpperCase())
          || (d.DepartmentCode != null && d.DepartmentCode.toUpperCase().includes(this.state.nameDeptCrewcode.toUpperCase()));
    });
    const statusActions = orderInvoiceActions.filter(ia => {
      return this.state.tickedStatuses.includes(ia.StatusStr);
    });
    if (statusActions.length > 0) {
      orderInvoiceActions = statusActions;
    }

    let Show = "hideMe";
    const invoiceActions = orderInvoiceActions.length > 0 ? orderInvoiceActions.map((i: InvoiceAction, index: number) => {
      Show = "";

      return (<InvoiceActionLine _ShowTimeSheets={this._ShowTimeSheets.bind(this)} invoiceAction={i} key={index} />);
    }) : <tr>
      <td colSpan={6}>missing</td>
    </tr>;

    let content;

    if (!this.state.invoiceId) {
      content = <div className="table-responsive">
        <div className="table">
          <table style={{ display: "inline-table" }}
            className={"invoices table table-striped table-condensed " + Show}>
            <thead>
              <tr>
                <th id="thEmployee" className="sort noSort" onClick={(e) => this.handleSort(e)}>Name</th>
                <th id="thEmployeeCode" className="sort noSort" onClick={(e) => this.handleSort(e)}>Code</th>
                <th id="thDepartmentCode" className="sort noSort" onClick={(e) => this.handleSort(e)}>Dept.</th>
                <th id="thCompanyId" className="sort noSort" onClick={(e) => this.handleSort(e)}>Client</th>
                <th id="thJobTitle" className="sort noSort" onClick={(e) => this.handleSort(e)}>Job Title</th>
                {/* <th></th> */}
                <th className="noSort">Status</th>
                <th id="thJobTitle" className="noSort">Comments</th>
                <th className="noSort" />
              </tr>
            </thead>
            <tbody>
              {invoiceActions}
            </tbody>
          </table>
          <div className={(Show == "hideMe" ? "" : "hideMe")}>
            <h2>No employee work invoice</h2>
          </div>
        </div>
      </div>;
    } else {
      const invoice = this.state.invoiceActions.find((element) => {
        return element.InvoiceId === this.state.invoiceId;
      });
      if (!invoice) {
        throw new Error("Selected invoice #" + this.state.invoiceId + " is missing in current page data");
      }
      content = <InvoiceUserTimesheet
          key={this.state.invoiceId}
          invoice={invoice}
          onCancel={() => this.ShowList()}
      />;
    }

    return (<div className="invoice-wrapper admin-wrapper">
      <div className="invoice-header">
        <div className="container">
          <div className="row">
            <div className="header">
              <h1>Work Invoices</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="column-container">
          <div className="invocie-menu">
            {this._createInvoiceMenu()}
          </div>

          <div className="filtertablecontainer">
            {this._generateFilters(this.state.invoiceActions)}

            {content}
          </div>

        </div>
      </div>
      {this.state.openReminderModal && this.state.invoiceCompanyId && this.navCompanyName && <SubmitManualWorkInvoiceReminderModal
          navCompanyName={this.navCompanyName}
          invoiceMonth={this.state.invoiceMonth}
          invoiceYear={this.state.invoiceYear}
          onRequestClose={this.closeReminderModal.bind(this)}  />}
    </div >);

  }
}

export type InvoiceActionLineProps = {
  invoiceAction: InvoiceAction,
  _ShowTimeSheets: (invoice: InvoiceAction) => void,
};

export const InvoiceActionLine = (props: InvoiceActionLineProps) => {
  const i = props.invoiceAction;

  const invoiceStatusClass = GetInvoiceStatusClass(i.Status);
  const comments = i.Comments
      .filter(c => c.Text && c.Text.trim().toLowerCase() !== "approved")
      .sort((a,b) => b.Id - a.Id)
      .map(c => c.Text);
  return (<tr>
    <td>{i.Employee}</td>
    <td className="text-center">{i.EmployeeCode?.toUpperCase()}</td>
    <td className="text-center">{i.DepartmentCode}</td>
    <td>{i.CompanyName}</td>
    <td>{i.JobTitle}</td>
    <td className="text-center"><span className={invoiceStatusClass}>{i.StatusStr}</span></td>
    <td
        className="joined-invoice-comments"
        title={comments.map(c => "¤ " + c).join("\n")}
    >{(comments.length > 1 ? "(" + comments.length + ") " : "") + comments.join(" ¤ ")}</td>
    <td className="text-center"><a
      onClick={(e) => props._ShowTimeSheets(i)}>View work
      invoice</a></td>
  </tr>);

};
