import React from "react";
import type { ManualInvoiceDetails } from "../types/Api/Invoice";
import { confirmAlert } from "react-confirm-alert";
import { neverNull, typed } from "../utils/typing";
import type WiseCodeValue from "../types/WiseCodeValue";
import api from "../features/Api";
import { getFieldsToDisplay, isEmptyValue } from "../utils/manualInvoiceUtils";

export type Field = keyof ManualInvoiceDetails;

export type SetupField = Field | "TimeIn" | "TimeOut" | "AddDay" | "PaymentONOFF";

function normalizeSetupField(field: SetupField): Field {
    if (field === "TimeIn") {
        return "TimeIn1";
    } else if (field === "TimeOut") {
        return "TimeOut1";
    } else if (field === "AddDay") {
        return "AdditionalDay";
    } else if (field === "PaymentONOFF") {
        return "PaymentOnOff";
    } else {
        return field;
    }
}

export function getFieldTitle(field: Field): string {
    const titles: Partial<Record<Field, string>> = {
      "Activity"         : "Activity",
      "AddonFee"         : "Activity 2",
      "Base"             : "Base",
      "Project"          : "Project",
      "TimeIn1"          : "Time In",
      "TimeOut1"         : "Time Out",
      "TimeIn2"          : "Time In 2",
      "TimeOut2"         : "Time Out 2",
      "FlightNumber"     : "Flight number",
      "AircraftReg"      : "Aircraft Reg",
      "DailyHours"       : "Daily Hours",
      "Over12Hours"      : "Over 12Hours",
      "OverTime"         : "Overtime HH:MM",
      "BlockHours"       : "Block Hours",
      "AdditionalDay"    : "ADD. Day",
      "PerDiem"          : "Per diem",
      "PaymentOnOff"     : "On Pay",
      "PositioningHours" : "Position Hours",
      "Flying"           : "Flying",
      "SoldOffDay"       : "Sold Off Day",
      "FlyingHoursStatus": "Flight Status",
    };
    return titles[field] ?? field;
}

function shouldCenter(field: Field) {
    const centeredFields: Field[] = [
        "AdditionalDay",
        "PerDiem",
        "PaymentOnOff",
        "PositioningHours",
        "Flying",
        "SoldOffDay",
    ];
    return centeredFields.includes(field);
}

type Props = {
    rosterData: ManualInvoiceDetails[],
    editRow: (detail: ManualInvoiceDetails) => void,
    displayedFieldsList?: SetupField[],
    canEdit: boolean,
    year: number,
    month: number,
};

function getInitialState(props: Props) {
    return {
        toggled: false,
        toggledRow: 0,
        projects: typed<undefined | WiseCodeValue[]>(undefined),
        deletionsInProgress: new Set<ManualInvoiceDetails>(),
        remainingEntries: props.rosterData,
    };
}

type State = ReturnType<typeof getInitialState>;

declare global {
    interface Navigator {
        userAgentData?: {
            mobile: boolean,
        },
    }
    interface Window {
        safari?: {
            pushNotification: boolean,
        },
    }
}

export default class ManualInvoiceDetailsTable extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = getInitialState(props);
    }

    private get isDesktop() {
      const isIOS =
        /iPad|iPhone|iPod/.test(navigator.platform) ||
        (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);
      let isMobile = false;
      let isFirefox = false;
      if ("InstallTrigger" in window) {
        // Gecko platform, InstallTrigger available
        isFirefox = "InstallTrigger" in window && typeof window.InstallTrigger !== "undefined";
      }
      const isSafari =
        /constructor/i.test(String(window.HTMLElement)) ||
        (function (p: boolean) {
          return p.toString() === "[object SafariRemoteNotification]";
        })(
          !("safari" in window && window["safari"]) ||
          (typeof window.safari !== "undefined" && window["safari"].pushNotification)
        );
      if (isSafari || isFirefox || isIOS) {
        isMobile = false;
      } else {
        if (navigator.userAgent.indexOf("Mobile") > -1) {
          isMobile = navigator.userAgentData ? navigator.userAgentData.mobile : true;
        }
      }
      return !isMobile && !isIOS;
    }

    getColumnClasses(column: string, list: string[]) {
      return list.indexOf(column) > -1 ? " noSort" : " hideMe";
    };

    toggleRow(invoiceDay: ManualInvoiceDetails) {
      this.setState({
        toggled: !this.state.toggled,
        toggledRow: invoiceDay.Id,
      });

      return false;
    };

    //Returns the project name
    _getSelectedProjectByCode(selProject: string) {
      let result = "";
      if (this.state.projects != null) {
        const resultItem = this.state.projects.find(
          ({ Code }) => Code === selProject
        );
        if (resultItem != null) {
          result = resultItem.Name;
        }
      }
      return result;
    };

    //Gisli
    //Show the edit/delete column only if the invoice has the apropriate status and the date is legal for submit.
    //Return true if the invoice can be edited otherwise false.
    _showEditDeleteColumn() {
      //If the invoice submit period has passed or not begun. Return false.
      // if (this.props.displayActions == false) { return false; } //Commentaði aðeins út

      return this.props.canEdit;
    };

    private get displayedFieldsList(): Field[] {
        if (!this.props.displayedFieldsList ||
            this.props.displayedFieldsList.length === 0
        ) {
            return getFieldsToDisplay(this.props.rosterData);
        }
        return this.props.displayedFieldsList.map(normalizeSetupField);
    }

    _createHeader() {
      const list: (keyof ManualInvoiceDetails)[] = this.displayedFieldsList;
      return (
        <tr>
          <th className="numberColumn noSort"/>
          <th>Activity Date</th>
          {this.displayedFieldsList.map(field => <th
              key={field}
              className={this.getColumnClasses(field, list)}
              data-id={field}
          >{getFieldTitle(field)}</th>)}
          {/* TODO: Tók út í smá tíma */}
          <th className="noSort noSortWider"
            style={{
              display: this.props.canEdit
                ? "table-cell"
                : "none",
            }}
          />
        </tr>
      );
    };

    formatAddonFee(addonFee: string) {
      return addonFee.replaceAll(";", ", ");
    };

    formatValue(r: ManualInvoiceDetails, field: keyof ManualInvoiceDetails) {
        if (isEmptyValue(r, field)) {
            return "";
        } else if (field === "Project") {
            return this._getSelectedProjectByCode(r.Project);
        } else if (field === "AddonFee") {
            return this.formatAddonFee(r[field]);
        } else if (r[field] === true) {
            return <img style={{ height: "22px" }} src="/img/check-green.png"/>;
        } else {
            return r[field];
        }
    }

    private renderRosterRow(invoiceDay: ManualInvoiceDetails, rowNum: number) {
      const self = this;
      const togglerClass = self.state.toggled
        ? "expanded"
        : "collapsed";

      //Gisli Make line red if both the invoice and the lina have been rejected
      let lineStyle = "";
      if (invoiceDay.Status === 1) {
        lineStyle = "yellowInvoiceDetailLine";
      } else if (invoiceDay.Rejected == true) {
        lineStyle = "redInvoiceDetailLine";
      } else {
        const wasBeforePastMonth =
            Date.parse(invoiceDay.ActivityDate) <
          new Date(this.props.year, this.props.month - 1, 1).getTime();
        lineStyle = wasBeforePastMonth
            ? "blueInvoiceDetailLine"
            : "";
      }

      const naStyle =
        this._showEditDeleteColumn() == false
          ? { display: "none" }
          : { display: "" }; //Gisli

      const isToggledRow = this.state.toggled && this.state.toggledRow === invoiceDay.Id;
      const showExtraColumns = isToggledRow || this.isDesktop;
      return <tr key={invoiceDay.Id} className={lineStyle} data-status={invoiceDay.Status ?? ""}>
        <td className="numberColumn">
          {rowNum}
          <a
            className={togglerClass + " row-toggler"}
            onClick={() => self.toggleRow(invoiceDay)}
          />
        </td>
        <td>{invoiceDay.ActivityDateStr ? invoiceDay.ActivityDateStr : ""}</td>

        {!showExtraColumns
            ? <td colSpan={this.displayedFieldsList.length}></td>
            : this.displayedFieldsList.map(field => <td key={field} className={shouldCenter(field) ? "center" : undefined}>
                <span className="mobile-only">{getFieldTitle(field)}</span>{
                    this.formatValue(invoiceDay, field)
                }
            </td>)}
        {showExtraColumns ? <td style={naStyle}>
            <a
              className={"btn"}
              onClick={(e) => this.props.editRow(invoiceDay)}
            >
              <img data-id={invoiceDay.Id} className={"halfOpacity"} src="/img/edit.svg"/>
            </a>
            &nbsp;
            <a
              className={"btn red halfOpacity"}
              onClick={(e) => this.attemptToDeleteManualInvoiceDetails(invoiceDay)}
            >
              <img data-id={invoiceDay.Id} src="/img/delete.svg" alt="delete"/>
            </a>
          </td> : <td/>}
      </tr>;
    }

    deleteManualInvoiceDetails(detail: ManualInvoiceDetails) {
      this.setState(state => ({
          deletionsInProgress: new Set([...state.deletionsInProgress, detail]),
      }));
      const id = detail.Id ?? neverNull();
      api.Invoice.DeleteManualInvoiceDetails({ mid: id }).then(() => this.setState({
          remainingEntries: this.state.remainingEntries.filter(e => e !== detail)
      })).finally(() => this.setState(state => {
          const deletionsInProgress = new Set([...state.deletionsInProgress]);
          deletionsInProgress.delete(detail);
          return { deletionsInProgress };
      }));
    }

    attemptToDeleteManualInvoiceDetails(detail: ManualInvoiceDetails) {
      if (this.isDesktop) {
        this.deleteManualInvoiceDetails(detail);
        return;
      }
      confirmAlert({
        title: "Confirm to delete",
        message: "Are you sure you want to delete entry " + detail.ActivityDate,
        buttons: [
          {
            label: "Delete",
            onClick: () => this.deleteManualInvoiceDetails(detail)
          },
          { label: "Cancel", onClick: () => {} },
        ]
      });
    };

    render() {
        const header = this._createHeader();
        const records = this.state.remainingEntries
            .filter(r => !this.state.deletionsInProgress.has(r))
            .map((r, i) => this.renderRosterRow(r, i + 1));
        const invoiceRecordsBody = <tbody>{records}</tbody>;
        return this.isDesktop ? <table
            id="tableToSort"
            className="tablesorter manual invoices table table-striped table-condensed no-border-bottom bs-select"
        >
            <thead>{header}</thead>
            {invoiceRecordsBody}
        </table> : <table
            className="tablesorter manual invoices table table-striped table-condensed no-border-bottom"
        >
            {invoiceRecordsBody}
        </table>;
    }
}