import type { InvoiceAction } from "../types/Api/Invoice";
import * as React from "react";
import { GetInvoiceStatusClass } from "../utils/Helper";

type InvoiceActionLineProps = {
    invoiceAction: InvoiceAction,
    _ShowTimeSheets: (invoiceAction: InvoiceAction) => void,
};

export const InvoiceActionLine = (props: InvoiceActionLineProps) => {
    const i = props.invoiceAction;

    const invoiceStatusClass = GetInvoiceStatusClass(props.invoiceAction.Status);
    const employeeCode = i.EmployeeCode.toUpperCase();
    return (<tr key={i.InvoiceId}>
        <td><span>{i.Employee}</span></td>
        <td>{i.EmployeeCode.toUpperCase()}</td>
        <td data-field-name="CompanyName">{i.CompanyName}</td>
        <td>{i.DepartmentCode}</td>
        <td>{i.JobTitle}</td>
        {/* <td>{i.ModifiedBy}</td> */}
        <td className="text-center"><span className={invoiceStatusClass}>{i.StatusStr}</span></td>
        <td className="text-center"><a className="specific-person-invoice-link" data-code={employeeCode} data-id={i.EmployeeId} data-invoiceid={i.InvoiceId} data-job-title={i.JobTitle}
                                       onClick={(e) => props._ShowTimeSheets(props.invoiceAction)}>View work invoice</a>
        </td>
    </tr>);
};