import {combineReducers} from 'redux'
import {activitySlice} from "./Activity";
import {companySlice} from "./Company";
import {invoiceSlice} from "./Invoice";
import {profileSlice} from "./Profile";

export const rootReducer = combineReducers({
  activity: activitySlice.reducer,
  company: companySlice.reducer,
  invoice: invoiceSlice.reducer,
  profile: profileSlice.reducer,
})