

import * as React from "react";

import * as _ from "lodash";
import ErrorPage from "./ErrorPage";
import InvoiceMenu from "../components/InvoiceMenu";
import { typed } from "../utils/typing";
import type { CompanyWise } from "../features/Api";
import api from "../features/Api";
import type { AllCompanies, AllCompany } from "../features/StaticData";
import { getAllCompanies } from "../features/StaticData";
import { useState } from "react";

type Flag = boolean | null;

type UpdateCompanyPayload = {
  companyName: string,
  companyCode: string,
  isSOE: Flag,
  isDepartment: Flag,
  isActive: Flag,
  isTimeLog: Flag,
};

export const views = {
  Table: 0,
  Form: 1
};

type Props = {
  isAdmin: string, // "True"/"False"
};

function getInitialState(props: Props) {
  return {
    nameOrCrewcode: "",
    madeFirstCall: false,
    currentPage: 1,
    employeesPerPage: 10,
    isAdmin: props.isAdmin,
    viewForm: views.Table,
    sortBy: {
      column: "",
      order: typed<"asc" | "desc">("asc")
    },
    loading: typed<boolean | undefined>(undefined),
    view: typed<"All" | undefined>(undefined),
    allCompanies: typed<null | AllCompanies>(null),
    allNavCompanyNames: typed<null | string[]>(null),
  };
}

type State = ReturnType<typeof getInitialState>;

export default class InvoicesUsersClient extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = getInitialState(props);
  };

  componentDidMount() {
    this.loadClients();
  };

  loadClients() {
    if (!this.state.madeFirstCall)
      this.setState({
        madeFirstCall: true,
        view: "All",
      });
    getAllCompanies().then(allCompanies => this.setState({ allCompanies }));
  }

  order(companies: CompanyWise[]) {
    if (this.state.sortBy.column != "") {
      return _.orderBy(companies, [this.state.sortBy.column], [this.state.sortBy.order]);
    }

    return companies;
  };

  handleSort(e: React.MouseEvent<HTMLTableCellElement>) {
    const headerId = e.currentTarget.id;

    this.setState({
      sortBy: {
        column: headerId.substr(2),
        order: this.state.sortBy.order == "desc" ? "asc" : "desc"
      }
    });

    if (e.currentTarget.parentElement) {
      _.forEach(e.currentTarget.parentElement.children, function (c) {
        c.className = "";
      });
    }

    e.currentTarget.className = this.state.sortBy.order == "desc" ? "asc" : "desc";
  };

  _getHeader() {
    return (<div className="invoice-header">
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <h1>Client List - set up</h1>
          </div>
        </div>
      </div>
    </div>);
  };

  _createInvoiceMenu() {
    return (<InvoiceMenu selected="Client"/>);
  };

  _filterByNameCrewDeptcode(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ nameOrCrewcode: e.target.value });
  };

  _generateFilters() {
    return (
      <div>
        <div className="col-xs-4 select search">
          <input type="text" placeholder="Search for client" value={this.state.nameOrCrewcode}
                 onChange={(e) => this._filterByNameCrewDeptcode(e)}/>
        </div>
      </div>
    );
  };

  hasAdminPrivileges() {
    const cleanIsAdmin = this.state.isAdmin === "True" || this.state.isAdmin === "true";
    return cleanIsAdmin;
  };

  private get companyActionsRows() {
    const { allCompanies } = this.state;
    if (!allCompanies) {
      return null;
    }

    const namePart = this.state.nameOrCrewcode.toUpperCase();
    return Object.values(allCompanies).filter(ac => {
      return ac.navEntry?.DisplayName.toUpperCase().includes(namePart)
          || ac.COMPANYNAME.toUpperCase().includes(namePart);
    }).map((allCompany, index) => {
      return (<CompanyLine
          allCompany={allCompany}
          updateCompany={async (payload) => {
            const id = await api.Company.UpdateCompany({
              company: payload.companyName,
              code: payload.companyCode,
              isSOE: payload.isSOE,
              isDepartment: payload.isDepartment,
              isActive: payload.isActive,
              isTimeLog: payload.isTimeLog,
            });
            this.setState({
              allCompanies: {
                ...allCompanies,
                [allCompany.COMPANYNAME.toUpperCase()]: {
                  ...allCompany,
                  portalEntry: {
                    ...allCompany.portalEntry ?? {},
                    Id: id,
                    IsActive: payload.isActive ?? allCompany.portalEntry?.IsActive ?? false,
                    IsDepartment: payload.isDepartment ?? allCompany.portalEntry?.IsDepartment ?? false,
                    IsSEO: payload.isSOE ?? allCompany.portalEntry?.IsSEO ?? false,
                    IsTimelog: payload.isTimeLog ?? allCompany.portalEntry?.IsTimelog ?? false,
                  },
                },
              },
            });
          }}
          key={allCompany.COMPANYNAME}
      />);
    });
  }

  render() {
    if (!this.hasAdminPrivileges()) {
      return <ErrorPage/>;
    }

    if (!this.state.madeFirstCall) {
      return (<div className="invoice-wrapper admin-wrapper">
        {this._getHeader()}
        <div className="container">
          <div className="row">

            <div className="col-xs-3 invocie-menu">
              {this._createInvoiceMenu()}
            </div>
            <div className="col-xs-9">
              <h1
                className="loading">{!this.state.madeFirstCall ? "Loading list of Clients..." : "Loading list of Clients..."}</h1>
            </div>
          </div>
        </div>
      </div>);
    } else if (!this.state.madeFirstCall || !this.companyActionsRows?.length) {
      return (<div className="invoice-wrapper admin-wrapper">
        {this._getHeader()}
        <div className="container">
          <div className="row">
            <div className="col-xs-3 invocie-menu">
              {this._createInvoiceMenu()}
            </div>
            <div className="col-xs-5">
              {this._generateFilters()}
            </div>
            <div className="col-xs-9">
              <h1
                className="loading">{!this.state.madeFirstCall ? "Loading list of Clients..." : "No Clients found..."}</h1>
            </div>
          </div>
        </div>
      </div>);
    } else
      return ((<div className="invoice-wrapper admin-wrapper">
          {this._getHeader()}
          <div className="container">
            <div className="row">
              <div className="col-xs-3 invocie-menu">
                {this._createInvoiceMenu()}
              </div>
              <div className="col-xs-5">
                {this._generateFilters()}
              </div>
              <div className="col-xs-9">
                <div className="table-responsive">
                  <table className="invoices invoice-months table table-striped table-condensed">
                    <thead>
                    <tr>
                      <th className="center noSort" id="thFirstName">ID</th>
                      <th id="thName" className="noSort" onClick={(e) => this.handleSort(e)}>Name</th>
                      <th id="thIsDepartment" className="center noSort">Salary Journal</th>
                      <th id="thIsDepartment" className="center noSort" onClick={(e) => this.handleSort(e)}>More than
                        one
                        approver
                      </th>
                      <th id="thTimeLog" className="center noSort" onClick={(e) => this.handleSort(e)}>Work Invoice</th>
                      <th id="thSEO" className="center noSort" onClick={(e) => this.handleSort(e)}>SoE</th>
                      <th id="thIsActive" className="center noSort" onClick={(e) => this.handleSort(e)}>Active</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.companyActionsRows}
                    </tbody>
                  </table>
                </div>
                <div className="row col-xs-12">
                  <div className="col-xs-3">
                  </div>
                  <div className="col-xs-9 text-right">

                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>)
      );
  }
}

type CompanyLineProps = {
    allCompany: AllCompany, // e
    updateCompany: (payload: UpdateCompanyPayload) => Promise<void>,
};

export const CompanyLine = (props: CompanyLineProps) => {
  const [submitting, setSubmitting] = useState(false);

  const updateCompany = (company: string, code: string, isSOE: Flag, isDepartment: Flag, isActive: Flag, isTimeLog: Flag) => {
    const payload = {
      companyName: company,
      companyCode: code,
      isSOE: isSOE,
      isDepartment: isDepartment,
      isActive: isActive,
      isTimeLog: isTimeLog
    };
    setSubmitting(true);
    props.updateCompany(payload).finally(() => setSubmitting(false));
  };

  const handleTimeLog = (e: React.ChangeEvent<HTMLInputElement>, name: string, code: string) => {
    updateCompany(name, code, null, null, null, e.target.checked);
  };

  const handleIsActive = (e: React.ChangeEvent<HTMLInputElement>, name: string, code: string) => {
    updateCompany(name, code, null, null, e.target.checked, null);
  };

  const handleDepartment = (e: React.ChangeEvent<HTMLInputElement>, name: string, code: string) => {
    updateCompany(name, code, null, e.target.checked, null, null);

  };

  const handleSEO = (e: React.ChangeEvent<HTMLInputElement>, name: string, code: string) => {
    updateCompany(name, code, e.target.checked, null, null, null);
  };

  const name = props.allCompany.navEntry?.DisplayName ?? props.allCompany.COMPANYNAME;
  const code = props.allCompany.COMPANYNAME;
  let isDepartment = false;
  let isSEO = false;
  let isTimeLog = false;
  let isActive = false;
  let localId = -1;
  if (props.allCompany.portalEntry) {
    localId = props.allCompany.portalEntry.Id;
    isActive = props.allCompany.portalEntry.IsActive;
    isDepartment = props.allCompany.portalEntry.IsDepartment;
    isSEO = props.allCompany.portalEntry.IsSEO;
    isTimeLog = props.allCompany.portalEntry.IsTimelog;
  }

  return <tr>
    <td>{localId}</td>
    <td>{name}</td>
    <td>{["AIRBORNE - MALTA", "HEL - AAIUSD", "D2W - AAIUSD"].includes(code.toUpperCase()) && <a target="_blank" href={"/Home/InvoiceRosterCalculationAirAtlanta?" + new URLSearchParams({
      COMPANYNAME: code,
    })}>Calculate</a>}</td>
    <td className="center">
      <input style={{ display: "inline-block" }} disabled={submitting}
                                  type="checkbox" onChange={(e) => handleDepartment(e, name, code)}
                                  checked={isDepartment}/>
    </td>
    <td className="center">
      <input style={{ display: "inline-block" }} disabled={submitting}
             type="checkbox" onChange={(e) => handleTimeLog(e, name, code)}
             checked={isTimeLog}/>
    </td>
    <td className="center">
      <input style={{ display: "inline-block" }} disabled={submitting}
                                  type="checkbox" onChange={(e) => handleSEO(e, name, code)}
                                  checked={isSEO}/>
    </td>
    <td className="center">
      <input style={{ display: "inline-block" }} disabled={submitting}
                                  type="checkbox" onChange={(e) => handleIsActive(e, name, code)}
                                  checked={isActive}/>
    </td>

  </tr>;
};
