import * as React from "react";
import "./ApproverScreenHeader.css";

export function ApproverScreenHeader() {
    return <div className="approve-work-invoices-heading">
        <h1>Approve Work invoices</h1>
        <div className="invoice-tabs">
            <a href="/Home/CurrentTimesheets?approveType=1"
                    className={window.location.pathname === "/Home/CurrentTimesheets" ? "active" : ""}>Manual Submissions
            </a>
            <a href="/Home/FeeStatementInvoicesListForApprover"
                    className={window.location.pathname === "/Home/FeeStatementInvoicesListForApprover" ? "active" : ""}>Fee Statements
            </a>
        </div>
    </div>;
}