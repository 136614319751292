
import * as React from "react";
import { useEffect, useState } from "react";
import type {
    CompanyInvoice,
    HrComment,
    RdbContractRecord,
    SalaryJournalWageContractRecord
} from "../features/ExternalRostersApi";
import externalRostersApi from "../features/ExternalRostersApi";
import type { NavContractRecord } from "../types/EmployeeInfo";
import { formatNavDate } from "../features/payrollUtils";
import CompanyFilterSelect from "../components/CompanyFilterSelect";
import type { COMPANYNAME, CompanyName,CREWCODE } from "../features/Api";
import type { RdbClient } from "../features/Api";
import api from "../features/Api";
import { IsolatedReact } from "../utils/IsolatedReact";
import { ImmutableMap } from "../utils/ImmutableMap";
import { fromEntries,keys,toCOMPANYNAMEUC,toCrewCodeUc } from "../utils/typing";
import type { AbsoluteMonth } from "../utils/dates";
import  { getDatePart } from "../utils/dates";
import { decrementMonth, formatZonelessDate, getPastMonth, strcmp } from "../utils/dates";
import type { OfficerFlightDeckFeesPerson } from "../types/Api/Contract";
import { isAirAtlanta, isFlightDeckDepartment } from "../features/roster/air_atlanta/FeeCalculatorUtils";
import WageContractEditForm from "../components/WageContractEditForm";
import ReactModal from "react-modal";
import { deepCopy } from "../utils/dataHelpers";
import type { CrewCode } from "@mhc/utils/types/nav";

const TODAY = new Date();

function isInPast(dateIso: string) {
    return new Date(dateIso).getTime() < TODAY.getTime();
}

function computeCompanyPeople(
    navPeople: NavContractRecord[],
    filterCompany: undefined | CompanyName
) {
    if (!filterCompany) {
        return navPeople;
    } else {
        return navPeople.filter(navPerson => toCOMPANYNAMEUC(navPerson.CompanyName) === filterCompany.toUpperCase());
    }
}

function isSamePerson(navPerson: NavContractRecord, rdbPerson: RdbContractRecord, sameClient: boolean) {
    return sameClient && navPerson.No_.toUpperCase() === rdbPerson.PayrollNo?.toUpperCase()
        || navPerson.FirstName + " " + navPerson.LastName === rdbPerson.PersonName + " " + rdbPerson.Surname
        || navPerson.EMail === rdbPerson.Email;
}

function makeRdbLink(personId: number) {
    return <a
        href={"https://backoffice.mhcaviation.com/views/rdb_t821ht83/bscan_person.html?personId=" + personId}
        key={personId}
        target="_blank"
    >{personId}</a>;
}

// should probably make a class NavMatrix with get() method that would encapsulate
// the .toUpperCase() normalization instead of repeating it again and again everywhere...
// or maybe just do normalization on server side...
function get2d<T>(
    mapping: NavMatrix<T> | undefined,
    navPerson: { CompanyName: CompanyName, No_: CrewCode } | undefined
): T | null {
    if (!mapping || !navPerson) {
        return null;
    }
    const COMPANYNAME = toCOMPANYNAMEUC(navPerson.CompanyName);
    const CODE = toCrewCodeUc(navPerson.No_);
    return mapping[COMPANYNAME]?.[CODE] ?? null;
}

function delete2d<T>(mapping: NavMatrix<T> | undefined, navPerson: NavContractRecord | undefined) {
    if (!mapping || !navPerson) {
        return null;
    }
    const COMPANYNAME = toCOMPANYNAMEUC(navPerson.CompanyName);
    const CODE = toCrewCodeUc(navPerson.No_);
    const result = mapping[COMPANYNAME]?.[CODE] ?? null;
    if (result) {
        delete mapping[COMPANYNAME][CODE];
    }
    return result;
}

export type PersonRow = {
    ClientId: number,
    Key: string,
    FirstName: string,
    LastName: string,
    No_?: CrewCode,
    Department?: string,
    CompanyName?: CompanyName,
    NavPerson?: NavContractRecord,
    OfficerFees?: OfficerFlightDeckFeesPerson,
    RdbMatches: RdbContractRecord[],
};

function contractEnded(personRow: PersonRow) {
    return personRow.RdbMatches.length > 0
        && personRow.RdbMatches.every(contract => {
            return contract.ToDate
                && getDatePart(contract.ToDate) < getDatePart(TODAY);
        });
}

function computeClientIdToRows(
    rdbContractors: RdbContractRecord[],
    companyPeople: NavContractRecord[],
    navToRdbClientId: Record<COMPANYNAME, number> | undefined,
    rdbContractorToNavPerson: ReadonlyMap<RdbContractRecord, NavContractRecord>,
    navPersonToRdbPeople: ReadonlyMap<NavContractRecord, RdbContractRecord[]>,
    xlsxPeople: XlsxPeople | undefined
): ReadonlyMap<number, PersonRow[]> {
    const clientIdToRows = new Map<number, PersonRow[]>();
    xlsxPeople = deepCopy(xlsxPeople ?? {});
    for (const rdbContract of rdbContractors) {
        if (rdbContract.ClientID === 14149) {
            continue; // MHC Aviation internal employees
        }
        const hasInNav = rdbContractorToNavPerson.has(rdbContract);
        if (hasInNav) {
            continue;
        }
        const clientId = rdbContract.ClientID || -1;
        let rows = clientIdToRows.get(clientId);
        if (!rows) {
            rows = [];
            clientIdToRows.set(clientId, rows);
        }
        rows.push({
            ClientId: clientId,
            Key: "rdb_" + rdbContract.WorkHistoryId,
            FirstName: rdbContract.PersonName,
            LastName: rdbContract.Surname,
            No_: rdbContract.PayrollNo,
            RdbMatches: [rdbContract],
        });
    }
    for (const navPerson of companyPeople) {
        if (navPerson.CompanyName === "F2R - F2REUR" ||
            navPerson.CompanyName === "APM - APMUSD" ||
            navPerson.CompanyName === "D2W - D2WEUR"
        ) {
            continue; // MHC Aviation internal employees
        }
        const clientId = navToRdbClientId?.[toCOMPANYNAMEUC(navPerson.CompanyName)] ?? -1;
        const rdbMatches = (navPersonToRdbPeople.get(navPerson) ?? []);
        let rows = clientIdToRows.get(clientId);
        if (!rows) {
            rows = [];
            clientIdToRows.set(clientId, rows);
        }
        rows.push({
            ClientId: clientId,
            Key: "nav_" + navPerson.CompanyName + "_" + navPerson.No_ + "_" + navPerson.WageContract,
            FirstName: navPerson.FirstName,
            LastName: navPerson.LastName,
            No_: navPerson.No_,
            Department: navPerson.Department,
            CompanyName: navPerson.CompanyName,
            NavPerson: navPerson,
            OfficerFees: delete2d(xlsxPeople, navPerson) ?? undefined,
            RdbMatches: rdbMatches,
        });
    }
    if (xlsxPeople) for (const COMPANYNAME of keys(xlsxPeople)) {
        const clientId = navToRdbClientId?.[COMPANYNAME] ?? -1;
        for (const CODE of keys(xlsxPeople[COMPANYNAME])) {
            const officerFees = xlsxPeople[COMPANYNAME][CODE];
            let rows = clientIdToRows.get(clientId);
            if (!rows) {
                rows = [];
                clientIdToRows.set(clientId, rows);
            }
            rows.push({
                ClientId: clientId,
                Key: "xlsx_" + officerFees.employeeCode,
                FirstName: officerFees.fullName?.split(/\s+/)[0],
                LastName: officerFees.fullName?.split(/\s+/).slice(1).join(" "),
                No_: officerFees.employeeCode,
                Department: officerFees.department,
                CompanyName: COMPANYNAME,
                OfficerFees: officerFees,
                RdbMatches: [],
            });
        }
    }
    for (const rows of clientIdToRows.values()) {
        rows.sort((a,b) => {
            const aEnded = contractEnded(a);
            const bEnded = contractEnded(b);
            if (aEnded && !bEnded) {
                return 1;
            } else if (bEnded && !aEnded) {
                return -1;
            }
            const aDepartment = a.NavPerson?.Department || "0";
            const bDepartment = b.NavPerson?.Department || "0";
            return +aDepartment - +bDepartment
                || a.RdbMatches.length - b.RdbMatches.length
                || strcmp(a.NavPerson?.CompanyName ?? "", b.NavPerson?.CompanyName ?? "")
                || strcmp(a.NavPerson?.WageContract ?? "", b.NavPerson?.WageContract ?? "")
                || strcmp(a.NavPerson?.Scale ?? "", b.NavPerson?.Scale ?? "")
                || (a.NavPerson?.WageContractLevel ?? 0) - (b.NavPerson?.WageContractLevel ?? 0);
        });
    }
    return clientIdToRows;
}

type NavMatrix<T> = Record<COMPANYNAME, Record<CREWCODE, T>>;

type LastPayroll = NavMatrix<CompanyInvoice>;
type WageContractEntries = NavMatrix<SalaryJournalWageContractRecord[]>;
type HrComments = NavMatrix<HrComment[]>;

function computeFilteredClientPersonRows(clientIdToPersonRows: ReadonlyMap<number, PersonRow[]>, filterClientId: number | undefined) {
    return [...clientIdToPersonRows].filter(e => !filterClientId || Number(e[0]) === filterClientId);
}

function computeAvailableDepartments(filteredClientIdToPersonRows: [number, PersonRow[]][]): Record<string, number> {
    const departments: Record<string, number> = {};
    for (const [clientId, personRows] of filteredClientIdToPersonRows) {
        for (const personRow of personRows) {
            if (personRow.Department) {
                departments[personRow.Department] = departments[personRow.Department] ?? 0;
                departments[personRow.Department] += 1;
            }
        }
    }
    return departments;
}

function computeFilterDepartments(availableDepartments: Record<string, number>, tickedDepartments: string[]): ReadonlySet<string> {
    return new Set(tickedDepartments.filter(department => department in availableDepartments));
}

function computeDepartmentPersonRows(filteredClientIdToPersonRows: [number, PersonRow[]][], filterDepartments: ReadonlySet<string>) {
    return filteredClientIdToPersonRows
        .flatMap(([clientId, personRows]) => personRows)
        .filter(personRow => {
            return filterDepartments.size === 0
                || personRow.Department && filterDepartments.has(personRow.Department);
        });
}

function computePostFilteredPersonRows(
    departmentPersonRows: PersonRow[],
    filterCrewCode: string,
    filterPersonName: string,
    displayLimit: number
) {
    let filtered = departmentPersonRows;
    if (filterCrewCode) {
        filtered = departmentPersonRows.filter(pr => {
            return pr.No_?.toUpperCase()
                .startsWith(filterCrewCode.toUpperCase());
        });
    }
    if (filterPersonName) {
        const words = filterPersonName.toUpperCase().trim().split(/\s+/);
        filtered = departmentPersonRows.filter(pr => {
            return words.every(word => {
                return pr.FirstName?.toUpperCase().includes(word)
                    || pr.LastName?.toUpperCase().includes(word);
            });
        });
    }
    return filtered.slice(0, displayLimit);
}

function computeFeeEntryTypeRecords(
    departmentPersonRows: PersonRow[],
    wageContractEntries: WageContractEntries | undefined
) {
    if (!wageContractEntries) {
        return [];
    }
    const typeToLastRecord: Record<number, SalaryJournalWageContractRecord> = {};
    for (const personRow of departmentPersonRows) {
        if (!personRow.NavPerson) {
            continue;
        }
        const COMPANYNAME = toCOMPANYNAMEUC(personRow.NavPerson.CompanyName);
        const CODE = toCrewCodeUc(personRow.NavPerson.No_);
        const personWageContractEntries = wageContractEntries[COMPANYNAME]?.[CODE] ?? [];
        for (const entry of personWageContractEntries) {
            typeToLastRecord[entry.fee_entry_type] = entry;
        }
    }
    return Object.values(typeToLastRecord);
}

async function fetchNavToRdbClientId() {
    const whenClientsMapping = api.Company.get_clients_mapping();
    const clientsMappingEntries = await whenClientsMapping;
    return fromEntries(clientsMappingEntries
        .flatMap(cme => {
            const { navision_code } = cme;
            if (navision_code) {
                return [{ ...cme, navision_code }];
            } else {
                return [];
            }
        })
        .map(cme => [cme.navision_code.toUpperCase(), cme.rdb_id]));
}

async function fetchWageContractEntries() {
    const wageContractEntriesList = await externalRostersApi.nav.getAllCompaniesWageContractEntries();
    const wageContractEntries: WageContractEntries = {};
    for await (const entry of wageContractEntriesList) {
        wageContractEntries[toCOMPANYNAMEUC(entry.COMPANYNAME)] = wageContractEntries[toCOMPANYNAMEUC(entry.COMPANYNAME)] ?? {};
        wageContractEntries[toCOMPANYNAMEUC(entry.COMPANYNAME)][toCrewCodeUc(entry.crew_code)] = wageContractEntries[toCOMPANYNAMEUC(entry.COMPANYNAME)][toCrewCodeUc(entry.crew_code)] ?? [];
        wageContractEntries[toCOMPANYNAMEUC(entry.COMPANYNAME)][toCrewCodeUc(entry.crew_code)].push(entry);
    }
    return wageContractEntries;
}

async function fetchHrComments() {
    const hrCommentsList = await externalRostersApi.nav.getAllActivePeopleComments();
    const hrComments: HrComments = {};
    for await (const entry of hrCommentsList) {
        hrComments[toCOMPANYNAMEUC(entry.COMPANYNAME)] = hrComments[toCOMPANYNAMEUC(entry.COMPANYNAME)] ?? {};
        hrComments[toCOMPANYNAMEUC(entry.COMPANYNAME)][toCrewCodeUc(entry.No_)] = hrComments[toCOMPANYNAMEUC(entry.COMPANYNAME)][toCrewCodeUc(entry.No_)] ?? [];
        hrComments[toCOMPANYNAMEUC(entry.COMPANYNAME)][toCrewCodeUc(entry.No_)].push(entry);
    }
    return hrComments;
}

async function fetchLastPayroll(lastPaidPayrollMonth: AbsoluteMonth) {
    const invoices = await externalRostersApi.nav.getAllCompaniesPayroll(lastPaidPayrollMonth);
    const lastPayroll: LastPayroll = {};
    for await (const invoice of invoices) {
        lastPayroll[toCOMPANYNAMEUC(invoice.COMPANYNAME)] = lastPayroll[toCOMPANYNAMEUC(invoice.COMPANYNAME)] ?? {};
        lastPayroll[toCOMPANYNAMEUC(invoice.COMPANYNAME)][toCrewCodeUc(invoice.Kennitala)] = invoice;
    }
    return lastPayroll;
}

async function fetchXlsxPeople(pastMonth: AbsoluteMonth) {
    const officerTable = await api.Contract.GetOfficerFlightDeckFeesTable(pastMonth);
    const xlsxPeople: XlsxPeople = {};
    for (const officerRow of officerTable) {
        const untypedCOMPANYNAME = (officerRow.partnerCompany && {
            "AP": "AIRBORNE - MALTA",
            "HELO": "HEL - AAIUSD",
            "D2W CONTRACT": "D2W - AAIUSD",
        }[officerRow.partnerCompany.toUpperCase()]) || "AIRBORNE - MALTA";
        const COMPANYNAME = untypedCOMPANYNAME as COMPANYNAME;
        xlsxPeople[COMPANYNAME] = xlsxPeople[COMPANYNAME] ?? {};
        xlsxPeople[COMPANYNAME][toCrewCodeUc(officerRow.employeeCode)] = officerRow;
    }
    return xlsxPeople;
}

type XlsxPeople = Record<COMPANYNAME, Record<CREWCODE, OfficerFlightDeckFeesPerson>>;

function computeLastPaidPayrollSum(personRow: PersonRow, lastPayroll: LastPayroll) {
    const { No_, CompanyName } = personRow;
    const invoice = No_ && CompanyName && get2d(lastPayroll, { No_, CompanyName });
    if (invoice) {
        return (+invoice["Amount to Pay I"] + +invoice["Amount to Pay II"]).toFixed(2);
    }
    return "";
}

function getOfficerRowFee(feeEntryType: number, officerFees: OfficerFlightDeckFeesPerson) {
    return feeEntryType === 108 ? Number(officerFees.hardDaysFee || "0") :
        feeEntryType === 200 ? Number(officerFees.dailyFee || "0") :
        feeEntryType === 209 ? Number(officerFees.safetyOfficerFee || "0") :
        feeEntryType === 300 ? Number(officerFees.overtimeFeePerHour || "0") :
        feeEntryType === 500 ? Number(officerFees.perDiemFee || "0") :
        feeEntryType === 670 ? Number(officerFees.instructorFee || "0") :
        feeEntryType === 4005 ? Number(officerFees.telecommunicationsAllowance || "0") :
        null;
}

// TODO: add person-specific fixed lines from [Sare_NAV601].[dbo].[Airborne - Malta$MT-Launabokarlina]
export default function WageContractsDashboard() {
    const [loading, setLoading] = useState(true);
    const [generatingXlsx, setGeneratingXlsx] = useState(false);
    const [filterClientId, setFilterClientId] = useState<number>();
    const [filterCompany, setFilterCompany] = useState<CompanyName>();
    const [filterCrewCode, setFilterCrewCode] = useState("");
    const [filterPersonName, setFilterPersonName] = useState("");
    const [displayLimit, setDisplayLimit] = useState(500);
    const [editing, setEditing] = useState<PersonRow & { NavPerson: NavContractRecord }>();
    const [navPeople, setNavPeople] = useState<NavContractRecord[]>([]);
    const [lastPayroll, setLastPayroll] = useState<LastPayroll>();
    const [wageContractEntries, setWageContractEntries] = useState<WageContractEntries>();
    const [hrComments, setHrComments] = useState<HrComments>();
    const [rdbContractors, setRdbContractors] = useState<RdbContractRecord[]>([]);
    const [xlsPeople, setXlsPeople] = useState<XlsxPeople>();
    const [idToRdbClient, setIdToRdbClient] = useState<Record<number, RdbClient>>();
    const [navToRdbClientId, setNavToRdbClientId] = useState<Record<CompanyName, number>>();
    const [tickedDepartments, setTickedDepartments] = useState<string[]>([]);
    const [navPersonToRdbPeople, setNavPersonToRdbPeople] = useState(
        ImmutableMap.create<NavContractRecord, RdbContractRecord[]>()
    );
    const [rdbContractorToNavPerson, setRdbContractorToNavPerson] = useState(
        ImmutableMap.create<RdbContractRecord, NavContractRecord>()
    );
    const companyPeople = IsolatedReact.useMemo(
        computeCompanyPeople, [navPeople, filterCompany]
    );
    const clientIdToPersonRows = IsolatedReact.useMemo(computeClientIdToRows, [
        rdbContractors, companyPeople, navToRdbClientId,
        rdbContractorToNavPerson, navPersonToRdbPeople, xlsPeople,
    ]);
    const filteredClientIdToPersonRows = IsolatedReact.useMemo(
        computeFilteredClientPersonRows, [clientIdToPersonRows, filterClientId]
    );
    const availableDepartments = IsolatedReact.useMemo(
        computeAvailableDepartments, [filteredClientIdToPersonRows]
    );
    const filterDepartments = IsolatedReact.useMemo(
        computeFilterDepartments, [availableDepartments, tickedDepartments]
    );
    const departmentPersonRows = IsolatedReact.useMemo(
        computeDepartmentPersonRows, [filteredClientIdToPersonRows, filterDepartments]
    );
    const feeEntryTypeRecords = IsolatedReact.useMemo(
        computeFeeEntryTypeRecords, [departmentPersonRows, wageContractEntries]
    );
    const postFilteredPersonRows = IsolatedReact.useMemo(
        computePostFilteredPersonRows, [departmentPersonRows, filterCrewCode, filterPersonName, displayLimit]
    );

    const pastMonth = getPastMonth(new Date());
    const lastPaidPayrollMonth = decrementMonth(pastMonth);

    useEffect(() => {
        require("./WageContractsDashboard.css");

        const searchParams = new URLSearchParams(window.location.search);
        const clientId = searchParams.get("clientId");
        if (clientId) {
            setFilterClientId(Number(clientId));
        }
        setTickedDepartments(searchParams.getAll("department"));

        setLoading(true);
        const whenNavPeople = externalRostersApi.nav.getActiveEmployees();
        const whenRdbContractors = api.Employee.GetAllActiveRdbContractors();
        const whenNavToRdbClientId = fetchNavToRdbClientId();
        const whenRdbClients = api.Company.GetRdbClients();

        whenRdbContractors.then(async rdbContractors => {
            setRdbContractors(rdbContractors);
            const navPeople = await whenNavPeople;
            const navToRdbClientId = await whenNavToRdbClientId;
            setNavToRdbClientId(navToRdbClientId);

            for await (const navPerson of navPeople) {
                setNavPeople(prevState => [...prevState, navPerson]);
                const rdbClientId = navToRdbClientId[toCOMPANYNAMEUC(navPerson.CompanyName)] ?? null;
                const rdbMatches = rdbContractors.filter(rdbPerson => {
                    const sameClient = rdbClientId && rdbPerson.ClientID === rdbClientId || false;
                    return isSamePerson(navPerson, rdbPerson, sameClient);
                });
                setNavPersonToRdbPeople(prevState => prevState.set(navPerson, rdbMatches));
                for (const rdbContractor of rdbMatches) {
                    setRdbContractorToNavPerson(prevState => prevState.set(rdbContractor, navPerson));
                }
            }
        }).finally(() => setLoading(false));

        whenRdbClients.then(rdbClients => setIdToRdbClient(
            Object.fromEntries(rdbClients.map(rc => [rc.ClientID, rc]))
        ));
        fetchLastPayroll(lastPaidPayrollMonth).then(setLastPayroll);
        fetchWageContractEntries().then(setWageContractEntries);
        fetchHrComments().then(setHrComments);
        fetchXlsxPeople(pastMonth).then(setXlsPeople);
    }, []);

    function countClientContractors(clientId: number) {
        return (clientIdToPersonRows.get(clientId) ?? []).length;
    }

    function getFeeEntryCell(rec: SalaryJournalWageContractRecord, personRow: PersonRow): Cell {
        const { CompanyName, Department, No_ } = personRow;
        if (!CompanyName || !No_) {
            return ["", (value: string) => <td>{value}</td>];
        }
        const personWageContractEntries = get2d(wageContractEntries, { CompanyName, No_ }) ?? [];
        const officerFees = personRow.OfficerFees;
        const wceValue = personWageContractEntries
            .find(wce => wce.fee_entry_type === rec.fee_entry_type)
            ?.payment_per_unit ?? 0;
        const officerValue = !officerFees ? 0 : getOfficerRowFee(rec.fee_entry_type, officerFees) ?? 0;
        const hasDiscrepancy = isAirAtlanta(CompanyName) &&
            Department &&
            isFlightDeckDepartment(Department) &&
            Math.abs(wceValue - officerValue) > 0.01;
        return [!wageContractEntries ? "..." : wceValue ? wceValue.toFixed(2) : hasDiscrepancy ? "0.00" : "", (value: string) => <td
            className={"alphanumeric-column" + (hasDiscrepancy ? " has-discrepancy" : "")}
            key={rec.fee_entry_type}
        >
            {<span className="wage-contract-fee-value" title="Value in Wage Contract">
                {value}
            </span>}
            {!hasDiscrepancy ? "" : <span className="officer-table-fee-value" title="Value in SharePoint .xlsx">
                /{officerValue.toFixed(2)}
            </span>}
        </td>];
    }

    type Cell = readonly [string, (title: string) => React.ReactElement];

    function getHeadersRow(): Cell[] {
        const columns: Cell[] = [];
        columns.push(["#"     , title => <th className="alphanumeric-column" style={{ textAlign: "right" }}>{title}</th>]);
        columns.push(["3/4LC" , title => <th className="alphanumeric-column">{title}</th>]);
        columns.push(["Name"  , title => <th>{title}</th>]);
        if (!filterCompany) {
            columns.push(["NAV Company", (title: string) => <th>{title}</th>]);
        }
        columns.push(["Paid " + lastPaidPayrollMonth.year + "-" + lastPaidPayrollMonth.month, title => <th className="alphanumeric-column">{title}</th>]);
        if (!filterClientId) {
            columns.push(["Client", (title: string) => <th>{title}</th>]);
        }
        columns.push(["RDB Link"     , title => <th className="alphanumeric-column">{title}</th>]);
        columns.push(["Dep"          , title => <th>{title}</th>]);
        columns.push(["Start Date"   , title => <th className="alphanumeric-column">{title}</th>]);
        columns.push(["End Date"     , title => <th className="alphanumeric-column">{title}</th>]);
        columns.push(["Status"       , title => <th>{title}</th>]);
        columns.push(["Wage Contract", title => <th>{title}</th>]);
        columns.push(["Lvl"          , title => <th className="alphanumeric-column">{title}</th>]);
        if (filterClientId || filterCompany || filterDepartments.size > 0) {
            for (const rec of feeEntryTypeRecords) {
                columns.push([String(rec.fee_entry_type), (title: string) => <th
                    className="alphanumeric-column"
                    key={rec.fee_entry_type}
                    title={rec.description + " " + rec.fee_entry_type_group}
                >{title}</th>]);
            }
        }
        columns.push(["Notes" , title => <th>{title}</th>]);
        columns.push([""      , title => <th>{title}</th>]);
        return columns;
    }

    function getValuesRow(personRow: PersonRow, i: number): Cell[] {
        const { NavPerson } = personRow;
        const navStartDate = !NavPerson ? "" : formatNavDate(NavPerson.ContractStartDate);
        const navEndDate = !NavPerson ? "" : formatNavDate(NavPerson.ContractEndDate);
        const rdbStartDate = personRow.RdbMatches.map(rdbPerson => rdbPerson.FromDate && formatZonelessDate(rdbPerson.FromDate)).join(" ");
        const rdbEndDate = personRow.RdbMatches.map(rdbPerson => rdbPerson.ToDate && formatZonelessDate(rdbPerson.ToDate)).join(" ");
        const officerFees = personRow.OfficerFees;
        const allRowHrComments = get2d(hrComments, personRow.NavPerson) ?? [];
        const rowHrComments = allRowHrComments.filter(hrc => hrc.Comment.trim());
        const officerNotes = [officerFees?.comment, officerFees?.notes, officerFees?.payrollComments].filter(a => a).join(" | ");
        const previewNotes = officerNotes || rowHrComments
            .filter(hrc => !hrc.Comment.startsWith("BD ") && !hrc.Comment.includes(" BD ") && hrc.Date > "2023-01-01")
            .map(hrc => hrc.Date.slice(2, 10) + " " + hrc.Comment).reverse()[0];
        const fullNotes = [officerNotes, ...rowHrComments
            .map(hrc => hrc.Date.slice(2, 10) + " " + hrc.Comment).reverse()
        ].filter(a => a).join("\n");
        const reviewListStatus = personRow.RdbMatches.map(rm => rm.Status).filter(s => s).join("/");
        const cells: Cell[] = [];
        cells.push([String(i + 1), value => <td className="alphanumeric-column" style={{ textAlign: "right" }}>{value}</td>]);
        cells.push([personRow.No_ ?? "", value => <td className="alphanumeric-column crew-code-field">{personRow.CompanyName && personRow.No_ ? <a href={"/Home/FeeStatementInvoiceConfirmation?" + new URLSearchParams({
            company: personRow.CompanyName,
            year: String(pastMonth.year),
            month: String(pastMonth.month),
            employeeCode: personRow.No_,
        })}>{value}</a> : value}</td>]);
        cells.push([personRow.FirstName + " " + personRow.LastName, value => <td style={{
            maxWidth: "150px",
            overflowX: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
        }} title={"First Name: " + personRow.FirstName + "\nLast Name: " + personRow.LastName}>
            {value}
        </td>]);
        if (!filterCompany) {
            cells.push([NavPerson?.CompanyName ?? "", value => <td style={{ maxWidth: "150px" }}>{value}</td>]);
        }
        cells.push([
            !lastPayroll ? "..." : computeLastPaidPayrollSum(personRow, lastPayroll),
            value => <td className="alphanumeric-column" style={{ textAlign: "right" }}>{value}</td>,
        ]);
        if (!filterClientId) {
            const clientId = Number(personRow.ClientId);
            cells.push([
                String(clientId === -1 ? "" : idToRdbClient?.[personRow.ClientId]?.Company ?? personRow.ClientId),
                value => <td style={{ maxWidth: "180px" }}>{clientId > 0 ? <img
                    alt={value}
                    title={value}
                    loading="lazy"
                    className="cell-client-logo"
                    src={"https://hiportal.eu/Secure/api/Job/GetClientLogoFromDb?clientId=" + clientId}
                /> : value}</td>,
            ]);
        }
        cells.push([personRow.RdbMatches.map(rm => rm.ApplicantID).join(" "), value => <td className="alphanumeric-column" style={{ maxWidth: "60px" }}>
            <div className="rdb-links-list">{[...new Set(personRow.RdbMatches.map(rm => rm.ApplicantID))].map(makeRdbLink)}</div>
        </td>]);
        cells.push([NavPerson ? NavPerson.Department : [...new Set(personRow.RdbMatches.map(rm => rm.Position).filter(p => p))].join("/"), value => <td>{value}</td>]);
        cells.push([rdbStartDate, value => <td className="alphanumeric-column" style={{ maxWidth: "60px" }} title={"NAV: " + navStartDate}>{value}</td>]);
        cells.push([rdbEndDate, value => <td className={"alphanumeric-column" + (contractEnded(personRow) ? " expired-contract-end-date" : "")} title={"NAV: " + navEndDate}>{value}</td>]);
        cells.push([reviewListStatus, value => <td title={value} style={{
            maxWidth: "120px",
            overflowX: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
        }}>{value}</td>]);
        cells.push([NavPerson?.WageContract ?? "", value => <td title={"Scale: " + (NavPerson?.Scale ?? "")}>{value}</td>]);
        cells.push([String(NavPerson?.WageContractLevel ?? ""), value => <td className="alphanumeric-column">{value}</td>]);
        if (filterClientId || filterCompany || filterDepartments.size > 0) {
            for (const rec of feeEntryTypeRecords) {
                cells.push(getFeeEntryCell(rec, personRow));
            }
        }
        cells.push([previewNotes, value => <td className="notes-field" title={fullNotes}>{value}</td>]);
        cells.push(["Edit", value => <td>{NavPerson && <button onClick={() => setEditing({ ...personRow, NavPerson })}>{value}</button>}</td>]);
        return cells;
    }

    async function downloadXlsx() {
        setGeneratingXlsx(true);
        try {
            const ExcelJS = await window.WHEN_EXCELJS;
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet("Contractors");
            worksheet.columns = getHeadersRow().map(column => ({ header: column[0] }));
            let i = 0;
            for (const personRow of departmentPersonRows) {
                const cells = getValuesRow(personRow, ++i);
                worksheet.addRow(cells.map(c => c[0]));
            }
            worksheet.autoFilter = "A1:Z1";

            const filled = await workbook.xlsx.writeBuffer();
            const blob = new Blob([filled], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const dataUrl = URL.createObjectURL(blob);
            window.location.href = dataUrl;
        } finally {
            setGeneratingXlsx(false);
        }
    }

    return <div>
        <h2>Contractors Dashboard</h2>
        <div className="filters-sides">
            <div className="filters-left-side">
                <label>
                    <span>Client: </span>
                    {!idToRdbClient ? <span className="status-loading-animated-ellipsis">Loading Clients</span> : <select
                        value={filterClientId ?? ""}
                        onChange={e => setFilterClientId(Number(e.target.value))}
                    >
                        <option value="">All ({[...clientIdToPersonRows.values()].reduce((sum, rows) => sum + rows.length, 0)})</option>
                        {Object.values(idToRdbClient)
                            .filter(rdbClient => countClientContractors(rdbClient.ClientID) > 0)
                            .map(rdbClient => <option
                                key={rdbClient.ClientID}
                                value={rdbClient.ClientID}
                            >{rdbClient.Company} ({countClientContractors(rdbClient.ClientID)})</option>)}
                        <option value={-1}>Others ({countClientContractors(-1)})</option>
                    </select>}
                </label>
                <CompanyFilterSelect
                    allowNull={true}
                    company={filterCompany ?? null}
                    onChange={value => setFilterCompany(value ?? undefined)}
                />
                <label>
                    <span>Person Name:</span>
                    <input type="text" value={filterPersonName} onChange={e => setFilterPersonName(e.target.value)} />
                </label>
                <label>
                    <span>3/4 Letter Code:</span>
                    <input type="text" size={6} maxLength={6} pattern={/^[a-z][A-Z]{0,6}$/.source} value={filterCrewCode} onChange={e => setFilterCrewCode(e.target.value)} />
                </label>
            </div>
            <div className="filters-right-side">
                <span>Departments: </span>
                <span>
                    {Object.entries(availableDepartments).map(([department, count]) => <label className="department-option" key={department}>
                        <div className="department-option-centering">
                            <span>{department} ({count})</span>
                            <input type="checkbox" checked={tickedDepartments.includes(department)} onChange={e => {
                                const newState = new Set(tickedDepartments);
                                if (e.target.checked) {
                                    newState.add(department);
                                } else {
                                    newState.delete(department);
                                }
                                setTickedDepartments([...newState]);
                            }}/>
                        </div>
                    </label>)}
                </span>
            </div>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <button type="button" disabled={generatingXlsx} onClick={downloadXlsx}>Download .xlsx</button>
        </div>
        {loading && <div className="status-loading-animated-ellipsis">Loading Data</div>}
        <table>
            <thead>
                {React.createElement("tr", {},
                    ...getHeadersRow().map(column => column[1](column[0]))
                )}
            </thead>
            <tbody>
                {postFilteredPersonRows.map((personRow, i) => {
                    return React.createElement("tr", {
                        key: personRow.Key,
                        className: editing && editing.NavPerson === personRow.NavPerson ? "row-being-edited" : undefined,
                    }, ...getValuesRow(personRow, i).map(cell => cell[1](cell[0])));
                })}
            </tbody>
            <tfoot>
                <tr>
                    <td>
                        <input style={{ textAlign: "right" }} type="number" min={1} step={1} value={displayLimit} max={99999}
                            onChange={e => setDisplayLimit(Math.max(1, e.target.value.match(/^\d+$/) ? +e.target.value : 0))}
                        />
                    </td>
                    <td colSpan={999}>Rows Displayed out of {postFilteredPersonRows.length}</td>
                </tr>
            </tfoot>
        </table>
        {!editing ? null : <ReactModal
            isOpen={true}
            ariaHideApp={false}
            className={"wage-contract-edit-view-modal"}
        >
            {!wageContractEntries ?
                <div className="status-loading-animated-ellipsis">Loading Wage Contract Data</div> :
                <WageContractEditForm
                    personRow={editing}
                    wageContractEntries={get2d(wageContractEntries, editing.NavPerson) ?? []}
                    onRequestClose={() => setEditing(undefined)}
                />}
        </ReactModal>}
    </div>;
}