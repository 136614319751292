import React from "react";
import FeeCalculatorFlightDeckTest from "../tests/FeeCalculatorFlightDeckTest";

export default class TestsDashboard extends React.Component<{}, {}> {
    constructor(props: {}) {
        super(props);
        FeeCalculatorFlightDeckTest();
    }

    render() {
        return <div>Tests execution in progress...</div>;
    }
}
