import api from "../features/Api";
import { HttpResponseError, isObscuredTransportError } from "../features/httpUtils";
import { neverNull } from "./typing";

/**
 * many libraries throw objects that do not extend Error, this function attempts
 * to extract the message from any kind of error object using popular conventions
 * like having `toString()` implementation or `message` property
 */
export function stringifyError(error: unknown): string {
    if (!error) {
        return "(empty error)";
    } else if (isObscuredTransportError(error)) {
        return "No connection to server: " + (error as { message: string }).message;
    } else if (error && typeof error === "object"
            && "message" in error
            && typeof (error as { message: unknown }).message === "string"
    ) {
        return (error as { message: string }).message;
    } else if (error && typeof error === "object"
            && "errorMessage" in error
            && typeof (error as { errorMessage: unknown }).errorMessage === "string"
    ) {
        return (error as { errorMessage: string }).errorMessage;
    } else if (typeof error === "string") {
        return error;
    } else if (error + "" !== "[object Object]") {
        return error + "";
    } else {
        return "Unknown format error: " + JSON.stringify(error);
    }
}

export const globalJsErrorHandler = (event: Omit<ErrorEvent, "error"> & { error: unknown }) => {
    const { message, filename, lineno, colno, error } = event;
    const asErrorClass = error instanceof Error ? error : null;
    if (asErrorClass && (
        asErrorClass.stack?.includes("chrome-extension") ||
        asErrorClass.message.includes("googletag")
    )) {
        return; // stupid browsers
    }
    console.error("global js error", { message, filename, lineno, colno, error });
    const formattedMessage = message + " at " + filename.replace(/.*\//, "");
    api.System.ReportJsError({
        message: message,
        pathname: window.location.pathname + window.location.search,
        stack: filename + ":" + lineno + ":" + colno + "\n" + (asErrorClass?.stack ?? ""),
        buildTime: process.env.BUILD_TIME ?? neverNull(),
    }).catch(error => console.error("Failed to report JS error due to", error)); // catch errors inside error handler to avoid deadloops
    if (window.toastr) {
        window.toastr.error(formattedMessage, "", {
            timeOut: 20000,
        });
    } else {
        alert(formattedMessage);
    }
};

export function globalUnhandledRejectionHandler(event: Omit<PromiseRejectionEvent, "reason"> & { reason: unknown }) {
    const { promise, reason } = event;
    console.info("Got an unhandled promise rejection somewhere in code", reason);
    const message = stringifyError(event.reason) + " (UPR)";
    const asErrorClass = event.reason instanceof Error ? event.reason : null;
    if (message.includes("@webkit-masked-url://hidden/") ||
        String(asErrorClass?.stack).includes("chrome-extension") ||
        String(asErrorClass?.stack).includes("@webkit-masked-url://hidden/")
    ) {
        return; // see https://github.com/getsentry/sentry-javascript/discussions/5875
    }
    console.error(reason);
    if (!(reason instanceof HttpResponseError) || !reason.expected) {
        api.System.ReportJsError({
            message: message,
            pathname: window.location.pathname + window.location.search,
            stack: asErrorClass?.stack ?? null,
            buildTime: process.env.BUILD_TIME ?? neverNull(),
        }).catch(error => console.error("Failed to report JS error due to", error)); // catch errors inside error handler to avoid deadloops
    }
    if (window.toastr) {
        window.toastr.error(message, "", {
            timeOut: 20000,
        });
    } else {
        alert(message);
    }
}
