
import * as React from "react";

type Props = {
  selected: "Client" | "Activity" | "Fields" | "List" | "Selected" | "Deadline",
};

export default class InvoiceMenu extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  };


  render() {
    let client = "";
    let activity = "";
    let fields = "";
    let list = "";
    let selected = "";
    let deadline = "";
    if (this.props.selected != undefined) {
      if (this.props.selected == "Client")
        client = "Selected";
      if (this.props.selected == "Activity")
        activity = "Selected";
      if (this.props.selected == "Fields")
        fields = "Selected";
      if (this.props.selected == "List")
        list = "Selected";
      if (this.props.selected == "Selected")
        selected = "Selected";
      if (this.props.selected == "Deadline")
        deadline = "Selected";

    }

    return (
      <div>
        <h3>
          <ul className="borderBottom">
            <li className="borderTop">Clients</li>
            <ul>
              <li>- <a className={client} href="/Home/InvoicesUsersClient">Client List Set-Up</a></li>
              <li>- <a className={activity} href="/Home/InvoicesUsersActivities">Activities Set-Up</a></li>
              <li>- <a className={fields} href="/Home/InvoicesUsersFields">Fields Set-Up</a></li>
              <li>- <a className={deadline} href="/Home/InvoicesDeadlineDays">Deadline Days</a></li>
            </ul>
            <li className="borderTop">Contractors</li>
            <ul>
              <li>- <a href="/Home/FeeStatementInvoicesListForOfficer">List of Navision Invoices</a></li>
              <li>- <a className={list} href="/Home/InvoicesUsersTimeSheets">List of Work Invoices</a></li>
              <li>- <a href="/Home/WageContractsDashboard">Contractors Dashboard</a></li>
            </ul>
          </ul>
        </h3>
      </div>
    );
  }
}
