import "./FeeStatementInvoiceConfirmationMessaging.css";
import * as React from "react";
import { type FormEvent, useEffect, useState } from "react";
import api from "../../features/Api";
import type {
    INavInvoiceConfirmationBase,
    NavInvoiceMessageModel, NavInvoiceMessageModelBase
} from "../../types/Api/Invoice";
import FeeStatementInvoiceConfirmationReplyForm
    from "./FeeStatementInvoiceConfirmationReplyForm";
import FeeStatementInvoiceConfirmationReportToClientForm from "./FeeStatementInvoiceConfirmationReportToClientForm";
import type { PayslipSummary } from "../../types/NavisionPayslip";
import { deadlinePassed } from "../../utils/feeStatementInvoiceUtils";
import type { InvoiceConfirmationData } from "../../types/feeStatementTypes";
import { getPartnerCompanyName } from "../../features/payrollUtils";
import { formatLocalDateTime } from "../../utils/dates";
import { NAV_INVOICE_STATUS_DB_LABELS } from "../../utils/invoiceListingUtils";

type FeeStatementInvoiceConfirmationMessagingProps = {
    confirmationData: InvoiceConfirmationData,
    setLastConfirmationStatus: (status: INavInvoiceConfirmationBase) => void,
    deadline: Date | null,
};

export default function FeeStatementInvoiceConfirmationMessaging(
    { confirmationData, setLastConfirmationStatus, deadline }: FeeStatementInvoiceConfirmationMessagingProps
) {
    const {
        locator,
        fullPayslip,
        partyConfirmations,
    } = confirmationData;
    const [messages, setMessages] = useState<NavInvoiceMessageModel[]>();
    const [discrepancyDescription, setDiscrepancyDescription] = useState<string>("");

    useEffect(() => {
        api.Invoice.GetNavInvoiceMessagesHistory(locator)
            .then((messages) => {
                setMessages(messages);
            });
    }, []);

    const appendNewMessage = (params: NavInvoiceMessageModelBase) => {
        setMessages((prevMessages) => [
            {
                ...params,
                id: -100,
                author_name: "You",
                message_time: new Date().toISOString(),
                only_for: null,
            },
            ...(prevMessages || []),
        ]);
    };

    const makeStatusUpdateParams = (bankInfo: PayslipSummary) => {
        return {
            year: locator.year,
            month: locator.month,
            employeeCode: locator.employeeCode,
            company: locator.company,
            accessToken: locator.accessToken,
            confirmationTotal: bankInfo.Upphaed,
        };
    };

    const makeDiscrepancyReportParams = (bankInfo: PayslipSummary) => {
        return  {
            ...makeStatusUpdateParams(bankInfo),
            discrepancyDescription: discrepancyDescription,
        };
    };

    const processStatusUpdate = (
        whenSuccess: Promise<unknown>,
        confirmation: INavInvoiceConfirmationBase
    ) => {
        const oldStatus = partyConfirmations?.[confirmation.confirming_party]?.status ?? "SENT_TO_CONTRACTOR_FOR_CONFIRMATION";

        setLastConfirmationStatus({
            confirming_party: confirmation.confirming_party,
            status: "SUBMITTING",
        });
        return whenSuccess.then(
            () => {
                setLastConfirmationStatus(confirmation);
            },
            error => {
                setLastConfirmationStatus({
                    confirming_party: confirmation.confirming_party,
                    status: oldStatus,
                });
                throw error;
            },
        );
    };

    const processNewStatusMessage = async (
        whenSuccess: Promise<unknown>,
        confirmation: INavInvoiceConfirmationBase,
        newMessage: NavInvoiceMessageModelBase
    ) => {
        await processStatusUpdate(whenSuccess, confirmation);
        return appendNewMessage(newMessage);
    };

    const confirmInvoiceAsContractor = async (bankInfo: PayslipSummary) => {
        const params = makeStatusUpdateParams(bankInfo);
        const whenSuccess = api.Invoice.ConfirmNavInvoice(params);
        await processStatusUpdate(whenSuccess, {
            confirming_party: "CONTRACTOR",
            status: "CONFIRMED",
        });
    };

    const confirmInvoiceAsClient = async (bankInfo: PayslipSummary) => {
        const params = makeStatusUpdateParams(bankInfo);
        const whenSuccess = api.Invoice.ClientConfirmNavInvoice(params);
        await processStatusUpdate(whenSuccess, {
            confirming_party: "CLIENT",
            status: "CONFIRMED_BY_CLIENT",
        });
    };

    const reportDiscrepancyAsContractor = async (event: FormEvent, bankInfo: PayslipSummary) => {
        event.preventDefault();
        const params = makeDiscrepancyReportParams(bankInfo);
        const whenSuccess = api.Invoice.ReportInconsistencyInNavInvoice(params);
        await processNewStatusMessage(whenSuccess, {
            confirming_party: "CONTRACTOR",
            status: "DISCREPANCY_REPORTED",
        }, {
            author_role: "CONTRACTOR",
            message_text: discrepancyDescription,
        });
    };

    const reportDiscrepancyAsClient = async (event: FormEvent, bankInfo: PayslipSummary, onlyFor: "OFFICER" | null) => {
        event.preventDefault();
        const params = { ...makeDiscrepancyReportParams(bankInfo), onlyFor };
        if (!params.discrepancyDescription.trim()) {
            throw new Error("Please, enter text describing the discrepancy");
        }
        const whenSuccess = api.Invoice.ClientReportInconsistencyInNavInvoice(params);
        await processNewStatusMessage(whenSuccess, {
            confirming_party: "CLIENT",
            status: "DISCREPANCY_REPORTED_BY_CLIENT",
        }, {
            author_role: "CLIENT",
            message_text: discrepancyDescription,
        });
    };

    const contractorCanConfirm = () => {
        if (!partyConfirmations) {
            return false;
        }
        const lastConfirmation = partyConfirmations.CONTRACTOR;
        return !lastConfirmation
            || lastConfirmation.status === "SENT_TO_CONTRACTOR_FOR_CONFIRMATION"
            || lastConfirmation.status === "DISCREPANCY_FIXED"
            || lastConfirmation.status === "CONFIRMED_BY_CLIENT";
    };

    const contractorCanReject = () => {
        if (partyConfirmations && !partyConfirmations.CONTRACTOR) {
            return !deadlinePassed(deadline);
        } else {
            return contractorCanConfirm();
        }
    };

    const showClientReportedDiscrepancyActionsForm = () => {
        return isAdmin()
            && partyConfirmations?.CLIENT?.status === "DISCREPANCY_REPORTED_BY_CLIENT";
    };

    const showClientActionButtons = () => {
        if (!partyConfirmations) {
            return false;
        }
        const lastConfirmation = partyConfirmations.CLIENT;
        return isClient() && !isAdmin() && (!lastConfirmation ||
            lastConfirmation.status === "CONFIRMED_BY_CLIENT" ||
            lastConfirmation.status === "DISCREPANCY_FIXED" ||
            lastConfirmation.status === "SENT_TO_CONTRACTOR_FOR_CONFIRMATION");
    };

    const isAdmin = () => {
        return window.EMPLOYEE_INFO ? window.EMPLOYEE_INFO.IsAdmin : false;
    };

    const isClient = () => {
        return window.EMPLOYEE_INFO ? window.EMPLOYEE_INFO.IsClient : false;
    };

    const showCommentsForm = () => {
        const canContractorAct = contractorCanConfirm() || contractorCanReject();
        const canActAsContractor = canContractorAct && locator.locatesSelf;
        const canActAsClient = showClientActionButtons();
        const canReplyToClient = showClientReportedDiscrepancyActionsForm();
        return !canActAsContractor
            && !canActAsClient
            && !canReplyToClient;
    };

    return <div className="fee-statement-invoice-confirmation-comments-section">
        {(fullPayslip.bankInfo && deadline && locator.locatesSelf) && <div
            className="actionButtonsSection"
        >
            <div className="approveSection">
                <p>By pressing "Confirm" you agree that the data in the invoice corresponds to the services
                    provided and accept that the total amount will be transferred to your account as the
                    service fee. Note, if upon further check or reconciliation by either parties the
                    calculations prove to be incorrect the amendments will be made within future
                    payments or by invoicing of the overpaid amounts, if any.</p>
                <button
                    disabled={!contractorCanConfirm()}
                    className="approveBtn"
                    onClick={() => confirmInvoiceAsContractor(fullPayslip.bankInfo)}
                >{partyConfirmations?.CONTRACTOR?.status === "CONFIRMED" ? "Confirmed" : "Confirm"}</button>
            </div>
            <form onSubmit={(e) => reportDiscrepancyAsContractor(e, fullPayslip.bankInfo)}
                  className="rejectSection">
                <textarea
                    disabled={!contractorCanReject()}
                    required={true}
                    value={discrepancyDescription}
                    onChange={e => setDiscrepancyDescription(e.target.value)}
                    placeholder="What are the discrepancies in this invoice?"
                ></textarea>
                <button
                    disabled={!contractorCanReject()}
                    type="submit"
                    className="rejectBtn"
                >{partyConfirmations?.CONTRACTOR?.status === "DISCREPANCY_REPORTED" ? "Discrepancy  Reported" : "Report Discrepancy"}</button>
            </form>
        </div>}
        {showClientActionButtons() && <div className="actionButtonsSection">
            {partyConfirmations?.CLIENT?.status !== "CONFIRMED_BY_CLIENT" && <div className="approveSection">
                <p>Everything is correct.</p>
                <button
                    className="approveBtn"
                    onClick={() => confirmInvoiceAsClient(fullPayslip.bankInfo)}
                >Confirm
                </button>
            </div>}
            <form onSubmit={(e) => reportDiscrepancyAsClient(e, fullPayslip.bankInfo, "OFFICER")}
                  className="rejectSection">
                <textarea
                    required={true}
                    rows={5}
                    value={discrepancyDescription}
                    onChange={e => setDiscrepancyDescription(e.target.value)}
                    placeholder="Please, describe here what are the discrepancies in this invoice if any..."
                ></textarea>
                <div>Report to...</div>
                <button
                    type="submit"
                    className="rejectBtn safer-option"
                >To {getPartnerCompanyName(confirmationData.locator.company) ?? "Payroll Officer"}</button>
                <button
                    type="button"
                    className="rejectBtn"
                    onClick={(e) => reportDiscrepancyAsClient(e, fullPayslip.bankInfo, null)}
                >To Everyone</button>
            </form>
        </div>}
        {showCommentsForm() && partyConfirmations && <FeeStatementInvoiceConfirmationReplyForm
            confirmationData={{ ...confirmationData, partyConfirmations }}
            appendNewMessage={appendNewMessage}
            processNewStatusMessage={processNewStatusMessage}
        />}
        {showClientReportedDiscrepancyActionsForm() && <FeeStatementInvoiceConfirmationReportToClientForm
            confirmationData={confirmationData}
            processNewStatusMessage={processNewStatusMessage}
        />}
        {messages ?
            <div className="messagesList">
                {messages.map(msg => <div
                    className={"navInvoiceMessage" + (!msg.message_text ? " has-no-text" : "")}
                    key={msg.id}
                    data-author-role={msg.author_role}
                >
                    <div className="messageHeader">
                        <span className="messageAuthorName"> {msg.author_name} </span>
                        <span className="messageOnlyFor">{msg.only_for && <> Only For {msg.only_for} </>}</span>
                        <span className="messageTime"> {formatLocalDateTime(new Date(msg.message_time))}</span>
                    </div>
                    <div className="messageText">{msg.message_text}</div>
                    {msg.new_status && msg.new_status !== msg.old_status && <div className="messageStatusFooter">
                        <span className="colored-nav-invoice-status-holder" data-confirmation-status={msg.old_status}>
                            <span className="statusField">{!msg.old_status ? "(No Status)" : NAV_INVOICE_STATUS_DB_LABELS[msg.old_status] ?? msg.old_status}</span>
                        </span>
                        <span>→</span>
                        <span className="colored-nav-invoice-status-holder" data-confirmation-status={msg.new_status}>
                            <span className="statusField">{NAV_INVOICE_STATUS_DB_LABELS[msg.new_status] ?? msg.new_status}</span>
                        </span>
                    </div>}
                </div>)}
            </div> :
            <div className="status-loading-animated-ellipsis messages-loader">Discussion Loading</div>
        }
    </div>;
}