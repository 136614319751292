import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "./Api";

export const loadActivitiesWise = createAsyncThunk(
  "companyActivitySetup/loadActivitiesWise", async (companyId, thunkAPI) => {
    const activities = await api.Activity.GetActivitiesWise();
    return activities;
  }
);
export const activitySlice = createSlice({
  name: "activity",
  /** @type {ActivityReducerState} */
  initialState: {
    activities: [],
    activitySetup: [],
    loading: false,
    error: null
  },
  extraReducers: builder => {
    builder.addCase(loadActivitiesWise.pending, (state, action) => {
      state.loading = true;
    }),
      builder.addCase(loadActivitiesWise.fulfilled, (state, action) => {
        state.loading = false;
        state.activities = action.payload;
      }),
      builder.addCase(loadActivitiesWise.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
});

export default activitySlice.reducer;
