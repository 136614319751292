
import DatePicker from "react-multi-date-picker";
import SoeDocumentFilePreview from "../components/SoeDocumentFilePreview";
import type { CompanyName,CurrencyIsoCode, Receipt } from "../features/Api";
import api from "../features/Api";
import type  ClaimFromServer from "../types/Claim";
import type { ClaimCategory ,IPresentationClaim } from "../types/Claim";
import type OpenExchangeRates from "../types/OpenExchangeRates";
import type { ChangeEvent } from "react";
import * as React from "react";

import * as _ from "lodash";
import DateObject from "react-date-object";
import { getEquivalent } from "../utils/currency";
import type Currency from "../types/Currency";
import type { EmployeeInfoWise } from "../types/EmployeeInfo";
import { getAllNavBases, getNavCompanyProjects } from "../features/StaticData";
import type WiseCodeValue from "../types/WiseCodeValue";
import { DisplayEmployeeInfoSmall, getDiffDays } from "../utils/Helper";
import { brand } from "../utils/typing";
import type { IsoDate,IsoDateTime } from "../types/utility";

type Props = {
  claimId: number,
  approve: boolean,
  code: string,
  email: string,
  employeeId: string,
  adminId: string,
  isAdmin: boolean,
  isSOEApproval: boolean,
  companyName: CompanyName,
};

type EditableClaimFromServer = ClaimFromServer<IsoDate | IsoDateTime | "">;

type State = {
  userfilenames: string[],
  NewComment: string,
  submitting: boolean,
  claim: EditableClaimFromServer | undefined,
  currencyExchangeRates?: OpenExchangeRates,
  claimCategories?: ClaimCategory[],
  files?: Receipt[],
  loading?: boolean,
  error?: string | null,
  profile?: EmployeeInfoWise,
  fileUploadError?: null | string,
  fileDragHover?: boolean,
  soeFields?: string[],
  allNavBases?: WiseCodeValue[],
  navCompanyProjects?: WiseCodeValue[],
  currencyOptions?: Currency[],
};

type InputChangeEvent = React.ChangeEvent & {
  target: HTMLInputElement | HTMLSelectElement,
};

export default class UpdateClaim extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    require("./FormFields.css");
    require("./Claim.css");
    require("./UpdateClaim.css");
    this.state = {
      userfilenames: [],
      NewComment: "",
      submitting: false,
      claim: undefined,
    };
  };

  componentDidMount() {
    const payload = {
      employeeId: Number(this.props.employeeId),
    };
    api.Employee.LoadProfile(payload).then(async profile => {
        this.setState({ profile });
        const soeFields = !profile.MainJobTitle ? [] : await api.Company
            .GetCompanyFieldSetupByCompanyAndJobtitleNew({
              company: this.props.companyName,
              jobtitle: profile.MainJobTitle,
              type: 2
            });
        if (soeFields.indexOf("Project") > -1) {
            const navCompanyProjects = await getNavCompanyProjects(this.props.companyName);
            this.setState({ navCompanyProjects });
        }
        if (soeFields.indexOf("Base") > -1) {
            const allNavBases = await getAllNavBases();
            this.setState({ allNavBases });
        }
        this.setState({ soeFields });
      }).finally(() => this.setState({ loading: false }));

    api.Claim.GetClaim({ claimId: this.props.claimId }).then((claim) => this.setState({ claim }));

    api.Claim.getCurrencyExchangeRates()
      .then((currencyExchangeRates) => this.setState({ currencyExchangeRates }));

    api.Claim.GetClaimCategories().then(claimCategories => this.setState({ claimCategories }));
    api.Claim.GetFiles({ claimId: this.props.claimId }).then(files => this.setState({ files }));
    api.Claim.GetAllCurrencies().then(currencyOptions => this.setState({ currencyOptions }));
  };

  _updateClaim(e: React.MouseEvent | React.FormEvent, action = "Save") {
    e.preventDefault();
    if (!this.state.claim) {
      window.toastr.error("Please wait, document is still loading...");
      return;
    }
    const claim: EditableClaimFromServer = _.cloneDeep(this.state.claim);
    claim.ModifiedById = parseInt(this.props.employeeId);
    claim.Action = action;
    if (claim.Action == "Rejected" && this.state.NewComment == "") {
      this.setState({ error: "Enter Comments to Reject" });
      this._createErrors();
      return;
    } else if (claim.Action == "FurtherInfo" && this.state.NewComment == "") {
      this.setState({ error: "Enter Comments to ask for further information" });
      this._createErrors();
      return;
    } else if (claim.Action == "Save") {
      let soeFields: string[] = [];
      if (this.state.soeFields != undefined) {
        soeFields = this.state.soeFields;
      }
      if (claim.CategoryId == "") {
        this.setState({ error: "Select Category" });
        return;
      } else if (!claim.Date) {
        this.setState({ error: "Select Date Of Receipt" });
        return;
      } else if (claim.FromCurrency == "") {
        this.setState({ error: "Select Currency" });
        return;
      } else if (claim.FromAmount == 0) {
        this.setState({ error: "Enter Amount" });
        return;
      } else if (claim.Project == null && (soeFields.indexOf("Project") > -1)) {
        this.setState({ error: "Select Project" });
        return;
      } else if (claim.AcCode == null && (soeFields.indexOf("AcCode") > -1)) {
        this.setState({ error: "Select AC Code" });
        return;
      } else if (claim.Base == null && (soeFields.indexOf("Base") > -1)) {
        this.setState({ error: "Select Base" });
        return;
      }
    }
    claim.Comments =
      window.EMPLOYEE_INFO?.LocalName +
      ": " +
      this.state.NewComment +
      "\n" +
      this.state.claim.Comments;

    this.setState({ submitting: true });
    api.Claim.UpdateClaim(claim)
      .then((response) => {
        this._goBack();
      })
      .catch((error) => {
        this.setState({ submitting: false });
        throw error;
      });
  };

  _updateComments(e: React.ChangeEvent<HTMLTextAreaElement>) {
    this.setState({ NewComment: e.target.value, error: null });
  };

  _goBack() {
    if (window.location.href.includes("ApproveClaim")) {
      window.location.reload();
      window.location.href = "/Home/CurrentTimesheets?approveType=2";
    } else {
      window.history.back();
    }
  };

  _createActions() {
    if (!this.state.claim) {
      return;
    }
    if (this.props.approve) {
      return (
        <div className="updateClaimButtonActions">
          {this.canChangeClaimStatus() && this.state.claim?.Status !== 1 ? (
            <button
              className="btn approveSoeBtn"
              onClick={(e) => this._updateClaim(e, "Approved")}
              type="button"
              disabled={this.state.submitting}
            >
              Approve
            </button>
          ) : null}
          {this.canChangeClaimStatus() ? (
            <button
              className="btn rejectSoeBtn"
              onClick={(e) => this._updateClaim(e, "Rejected")}
              type="button"
              disabled={this.state.submitting}
            >
              Reject
            </button>
          ) : null}
          {this.canChangeClaimStatus() ? (
            <button
              className="btn furtherInfoNeededBtn"
              onClick={(e) => this._updateClaim(e, "FurtherInfo")}
              type="button"
              disabled={this.state.submitting}
            >
              Further info needed
            </button>
          ) : null}
        </div>
      );
    } else if (this.state.claim.Status == 3 || this.state.claim.Status == 0) {
      return (
        <div className="submit-button">
          {this.canEditClaim() ? (
            <button
              type="submit"
              className="btn-submit"
              disabled={this.state.submitting}
            >
              Save Changes
            </button>
          ) : null}
        </div>
      );
    } else null;
  };

  _createErrors() {
    if (this.state.error != null)
      return (
        <span className="control-label alert alert-danger">
          {this.state.error}
        </span>
      );
    else return null;
  };

  canEditClaim() {
    if (this.props.approve || !this.state.claim) {
      return false;
    }
    if (this.state.claim.Status === 3 || this.state.claim.Status === 0) {
      //if (this.props.profile.profile.IsAdmin || this.props.profile.profile.IsClient) {
      return this.props.employeeId === this.props.adminId;
      //}
      //return true;
    }
    return false;
  };

  canChangeClaimStatus() {
    if (!this.state.claim) {
      return false;
    }
    if (this.state.claim.Status === 1) {
      // let admins unaprove accidentally approved SoEs
      return this.props.isAdmin;
    }
    if (this.state.claim.Status === 0) {
      if (this.props.isAdmin) {
        return true;
      }
      if (this.props.isSOEApproval) {
        return (
          this.state.profile?.ApproveOwnSoe ||
          this.props.employeeId !== this.props.adminId
        );
      }
    }
    return false;
  };

  _getSubCategories(categoryId: number | string, claimCategories: ClaimCategory[]) {
    const subCategories = [];
    subCategories.push(<option key="SC0" value="">Select Sub Category</option>);

    if (categoryId) {
      claimCategories
          .filter((category) => category.ParentCategoryId === +categoryId)
          .map((subcategory) => <option
              key={subcategory.CategoryId}
              value={subcategory.CategoryId}
          >{subcategory.Category}</option>)
          .forEach(subcategory => subCategories.push(subcategory));
    }
    return subCategories;
  };

  _getClaimCategories(claimCategories: ClaimCategory[]): React.JSX.Element[] {
      const categories = claimCategories.filter(
        (category) => category.ParentCategoryId == null
      );
      return _.sortBy(categories, "Category")
          .map((category) => <option
              key={category.CategoryId}
              value={category.CategoryId}
          >{category.Category}</option>);
  };

  _selectCategory(claim: EditableClaimFromServer, e: React.ChangeEvent<HTMLSelectElement>, claimCategories: ClaimCategory[]) {
    const Claim = _.cloneDeep(claim);
    Claim.CategoryId = e.target.value;
    Claim.Category = claimCategories.filter(
      (category) => category.CategoryId == Number(e.target.value)
    )[0].Category;
    Claim.SubCategoryId = "";
    this.setState({ claim: Claim, error: null });
  };

  _updateDescription(claim: EditableClaimFromServer, e: React.ChangeEvent<HTMLTextAreaElement>) {
    const Claim = _.cloneDeep(claim);
    Claim.Description = e.target.value;
    this.setState({ claim: Claim, error: null });
  };

  _updateAmount(claim: EditableClaimFromServer, e: React.ChangeEvent<HTMLInputElement>) {
    const Claim = _.cloneDeep(claim);
    Claim.FromAmount = e.target.value;
    this.setState({ claim: Claim, error: null });
  };

  _selectCurrency(claim: EditableClaimFromServer, e: React.ChangeEvent<HTMLSelectElement>) {
    const Claim = _.cloneDeep(claim);
    Claim.FromCurrency = brand<CurrencyIsoCode>(e.target.value);
    this.setState({ claim: Claim, error: null });
  };

  _selectSubCategory(claim: EditableClaimFromServer, e: InputChangeEvent, claimCategories: ClaimCategory[]) {
    const Claim = _.cloneDeep(claim);
    Claim.SubCategoryId = e.target.value;
    Claim.SubCategory = claimCategories.filter(
      (category) => category.CategoryId == Number(e.target.value)
    )[0].Category;
    this.setState({ claim: Claim, error: null });
  };

  _selectDate(claim: EditableClaimFromServer, date: DateObject | DateObject[] | null) {
    const Claim = _.cloneDeep(claim);
    Claim.Date = date instanceof DateObject
      ? date.format("YYYY-MM-DD") as IsoDate
      : "";
    this.setState({ claim: Claim, error: null });
  };

  _selectTravelDate(claim: EditableClaimFromServer, date: DateObject | DateObject[] | null) {
    const Claim = _.cloneDeep(claim);
    Claim.TravelDate = date instanceof DateObject
      ? date.format("YYYY-MM-DD") as IsoDate
      : undefined;
    this.setState({ claim: Claim, error: null });
  };

  _selectProject(claim: EditableClaimFromServer, e: InputChangeEvent) {
    const Claim = _.cloneDeep(claim);
    Claim.Project = e.target.value;
    this.setState({ claim: Claim, error: null });
  };

  _selectAcCode(claim: EditableClaimFromServer, e: InputChangeEvent) {
    const Claim = _.cloneDeep(claim);
    Claim.AcCode = e.target.value;
    this.setState({ claim: Claim, error: null });
  };

  _selectCrewBase(claim: EditableClaimFromServer, e: InputChangeEvent) {
    const Claim = _.cloneDeep(claim);
    if (e.target.value != "other") {
      Claim.Base = e.target.value;
      this.setState({ claim: Claim, error: null });
    } else {
      Claim.Base = "";
      this.setState({ claim: Claim, error: null });
    }
  };

  _getProjects() {
    const projects = [];
    projects.push(
      <option key="PRJ00" value="">
        Select Project
      </option>
    );

    _.map(_.sortBy(this.state.navCompanyProjects, "Code"), function (p, index) {
      projects.push(
        <option key={"PRJ" + index} value={p.Code}>
          {p.Name}
        </option>
      );
    });

    return projects;
  };

  // TODO: copypaste, move to reusable helper function
  _getCurrencies() {
    if (!this.state.currencyOptions) {
        return <option disabled={true} className="status-loading-animated-ellipsis">Loading Currencies</option>;
    }
    const currencies = [];
    currencies.push(<option key="CUR00" value="">Not Specified</option>);

    _.sortBy(this.state.currencyOptions, "Name").forEach((c) => {
        currencies.push(<option key={c.Code} value={c.Code}>{c.Name} - {c.Code}</option>);
    });

    return currencies;
  };

  _getCrewBases() {
    const bases = [];
    bases.push(
      <option key="CB00" value="">
        Select Base
      </option>
    );

    _.map(_.sortBy(this.state.allNavBases, "BaseCode"), function (c, index) {
      bases.push(
        <option key={"CB" + index} value={c.Code}>
          {c.Code}
        </option>
      );
    });
    bases.push(
      <option key={"CBOTHER"} value={"other"}>
        Other
      </option>
    );
    return bases;
  };

  _getApprover(claim: EditableClaimFromServer) {
    if (claim.ApproverName && (this.props.isAdmin || this.props.isSOEApproval)) {
      return (
        <div>
          <label className="control-label claimAttributeHeading">Approver: </label>
          <span className="text-details field-value">
            {claim.ApproverName}
          </span>
        </div>
      );
    } else {
      return null;
    }
  };

  _getCategory(claim: EditableClaimFromServer, claimCategories: ClaimCategory[]) {
    if (!this.canEditClaim()) {
      return (
        <div>
          <label className="control-label claimAttributeHeading">
            Category:{" "}
          </label>
          <span className="field-value">
            <span>{claim.Category}</span>
          </span>
        </div>
      );
    } else {
      return (
        <div>
          <label className="dropdown-label" htmlFor="category-dropdown">Category<span className="mandatoryFieldMark">*</span>{" "}</label>
          <div className="material-dropdown">
            <select
              id="category-dropdown"
              onChange={(e) => this._selectCategory(claim, e, claimCategories)}
              value={claim.CategoryId}
            >
              {this._getClaimCategories(claimCategories)}
            </select>
          </div>
        </div>
      );
    }
  };

  _getSubCategory(claim: EditableClaimFromServer, claimCategories: ClaimCategory[]) {
    if (!this.canEditClaim()) {
      return !claim.CategoryId ? undefined : (
        <div>
          <label className="control-label claimAttributeHeading">
            Sub Category:{" "}
          </label>
          <span className="field-value">
            <span>{claim.SubCategory}</span>
          </span>
        </div>
      );
    } else {
      return (
        <div>
          <label className="dropdown-label" htmlFor="sub-category-dropdown">Sub Category</label>
          <div className="material-dropdown">
            <select
              id="sub-category-dropdown"
              onChange={(e) => this._selectSubCategory(claim, e, claimCategories)}
              value={claim.SubCategoryId ?? ""}
            >
              {this._getSubCategories(claim.CategoryId, claimCategories)}
            </select>
          </div>
        </div>
      );
    }
  };

  _getDateOfReceipt(claim: EditableClaimFromServer, claimDateClass: string) {
    if (!this.canEditClaim()) {
      return (
        <div>
          <label className="control-label claimAttributeHeading">
            Date of Receipt:{" "}
          </label>
          <label className="control-label">
            <span className={claimDateClass}>{claim.DateStr}</span>
          </label>
        </div>
      );
    } else {
      return (
        <div className="calendar-container">
          <label className="calendar-field">Date of Receipt<span className="mandatoryFieldMark">*</span></label>
          <div className="claimGroup">
            <DatePicker className="rmdp-prime"
              format="DD.MM.YYYY"
              value={claim.DateStr}
              onChange={(e) => this._selectDate(claim, e)}
              maxDate={new Date()}
              weekStartDayIndex={1}
            />
          </div>
        </div>
      );
    }
  };

  _getTravelDate(claim: EditableClaimFromServer, claimDateClass: string) {
    if (!this.canEditClaim()) {
      return !claim.TravelDateStr ? undefined : (
        <div>
          <label className="control-label claimAttributeHeading">
            Date of Travel:{" "}
          </label>
          <label className="control-label">
            <span className={claimDateClass}>
              {claim.TravelDateStr}
            </span>
          </label>
        </div>
      );
    } else {
      return (
        <div className="calendar-container">
          <label className="calendar-field">Date of Travel<span className="mandatoryFieldMark">*</span></label>
          <div className="claimGroup">
            <DatePicker className="rmdp-prime"
              format="DD.MM.YYYY"
              value={claim.TravelDateStr}
              onChange={(e) => this._selectTravelDate(claim, e)}
              weekStartDayIndex={1}
            />
          </div>
        </div>
      );
    }
  };

  _getProject(claim: EditableClaimFromServer) {
    if (!this.canEditClaim()) {
      return (
        <div>
          <label className="control-label claimAttributeHeading">
            Project:{" "}
          </label>
          <span className="field-value">
            {claim.Project}{" "}
          </span>
        </div>
      );
    } else {
      return (
        <div>
          <label className="dropdown-label" htmlFor="project-dropdown">Project<span className="mandatoryFieldMark">*</span></label>
          <div className="material-dropdown">
            <select
              id="project-dropdown"
              onChange={(e) => this._selectProject(claim, e)}
              value={claim.Project ?? ""}
            >
              {this._getProjects()}
            </select>
          </div>
        </div >
      );
    }
  };

  _getCrewBase(claim: EditableClaimFromServer) {
    if (!this.canEditClaim()) {
      return (
        <div>
          <label className="control-label claimAttributeHeading">
            Base:{" "}
          </label>
          <span className="field-value">
            {claim.Base}{" "}
          </span>
        </div>
      );
    } else {
      return (
        <div>
          <label className="dropwdown-label" htmlFor="base-dropdown">Base<span className="mandatoryFieldMark">*</span></label>
          <div className="material-dropdown">
            <select
              id="base-dropdown"
              onChange={(e) => this._selectCrewBase(claim, e)}
              value={claim.Base ?? ""}
            >
              {this._getCrewBases()}
            </select>
          </div>
        </div>
      );
    }
  };

  _getCurrency(claim: EditableClaimFromServer) {
    if (!this.canEditClaim()) {
      return (
        <div>
          <label className="control-label claimAttributeHeading">
            Currency:{" "}
          </label>
          <span className="field-value">
            {claim.FromCurrency}{" "}
          </span>
        </div>
      );
    } else {
      return (
        <div>
          <label className="dropdown-label" htmlFor="currency-dropdown">Currency<span className="mandatoryFieldMark">*</span></label>
          <div className="material-dropdown">
            <select
              id="currency-dropdown"
              onChange={(e) => this._selectCurrency(claim, e)}
              value={claim.FromCurrency}
            >
              {this._getCurrencies()}
            </select>
          </div>
        </div>
      );
    }
  };

  _getAmount(claim: EditableClaimFromServer) {
    if (!this.canEditClaim()) {
      return (
        <div>
          <label className="control-label claimAttributeHeading">
            Amount:{" "}
          </label>
          <span className="field-value">
            {claim.FromAmount}{" "}
          </span>
        </div>
      );
    } else {
      return (
        <div className="number-field">
          <label htmlFor="numberInput">Amount<span className="mandatoryFieldMark">*</span></label>
          <input
            id="numberInput"
            type="number"
            onChange={(e) => this._updateAmount(claim, e)}
            value={claim.FromAmount}
          />
        </div>
      );
    }
  };

  _getEquivalentAmount(claim: EditableClaimFromServer) {
    // 1 = Airborne - Malta
    // 87 = SO Tech (USD)
    // 95 = HEL - AAIUSD
    const targetCurrency = [1, 87, 95].includes(claim.CompanyId) ? "USD" : "EUR";
    if (!this.state.currencyExchangeRates ||
        claim.FromCurrency === targetCurrency
    ) {
      return undefined;
    }
    const targetEquivalent = getEquivalent(
        claim.FromCurrency, claim.FromAmount,
        targetCurrency, this.state.currencyExchangeRates
    );
    if (!targetEquivalent) {
      return undefined;
    }
    return (
      <div className={"currency-equivalent-section" + (targetEquivalent > 1000 ? " large-amount" : "")}>
        <div>
          <label className="control-label claimAttributeHeading">
            {targetCurrency} Equivalent:{" "}
          </label>
          <span className="currency-equivalent-section field-value">
            {targetEquivalent.toFixed(2)}{" "}
          </span>
        </div>
        <div className="forInformationalPurposesOnlyNote">(for informational purposes only)</div>
      </div>
    );
  }

  _getDescription(claim: EditableClaimFromServer) {
    if (!this.canEditClaim()) {
      return (
        <div>
          <label>
            Description <br />{" "}
          </label>
          <div>
            {claim.Description}
          </div>
        </div>
      );
    } else {
      return (
        <div className="material-text-field text-field">
          <textarea
            id="descriptionInput"
            placeholder=" "
            onChange={(e) => this._updateDescription(claim, e)}
            value={claim.Description ?? ""}
          />
          <label htmlFor="descriptionInput">Description</label>
        </div>
      );
    }
  };

  _getFileNames() {
    return this.state.userfilenames.map(function (file: string) {
      return <span key={file} className="badge">{file}</span>;
    });
  };

  getComments(claim: EditableClaimFromServer) {
    if (claim.Comments != null) {
      const comments: string[] = claim.Comments.split("\n");
      if (comments.length > 0) {
        if (comments.slice(-1)[0] === "") {
          comments.splice(-1);
        }
        return comments.map((comment, index) => {
          return <div key={index} className="comment">{comment}</div>;
        });
      }
    }
    return null;
  };

  _uploadReceipt(e: ChangeEvent<HTMLInputElement>, requestClaim: EditableClaimFromServer) {
    const input = e.target;
    const files = input.files;
    if (files && files[0] != null) {
      this.setState({ fileUploadError: null });
      const fileNames = this.state.userfilenames;
      for (let i = 0; i < files.length; i++) {

        api.Claim.UploadFile(files[i])
          .then((response: { newName: string, formRecognizerOperationLocation: string | null }) => {
            const claim = _.cloneDeep(this.state.claim ?? requestClaim);
            claim.Receipts.push(response.newName);

            fileNames.push(files[i].name);
            this.setState({ loading: false, claim: claim });
          })
          .catch((error) => {
            this.setState({ fileUploadError: String(error) });
          });
      }
    }
  };

  _getReceipts(claim: EditableClaimFromServer) {
    if (!this.canEditClaim()) {
      return (
        <div className="claimDataContainer">
          <p className="receiptHeading">Receipts</p>
          <div className="border-upload">{this.renderReceipts()}</div>
        </div>);
    }
    return (
        <div>
          <div className="claimDataContainer">
            <p className="receiptHeading">Receipts</p>
          </div>

          <div className="button-wrapper">
            {/* fileDragHover is never set =/ */}
            <label className={"btn-upload " + (this.state.fileDragHover ? "file-drag-over" : "")}>
              <span className="or-take-photo-suffix">📷/</span><span>Upload Receipt File</span>
              <input
                  className="form-control hidden"
                  type="file"
                  onChange={(e) => this._uploadReceipt(e, claim)}
                  accept=".jpeg, .jpg, .png, .pdf, .xls, .xlsx"
                  multiple
              />
            </label>
            <span className="tooltip-span">Allowed file types: .jpeg, .jpg, .png, .pdf, .xls and .xlsx</span>
          </div>
          <div>{this._getFileNames()}</div>
          <span className="error-span">{this.state.fileUploadError}</span> <br/>
          <div className="text-details">Previously added receipts</div>
          <div className="border-upload">{this.renderReceipts()}</div>
        </div>
    );
  };

  private renderReceipts(): React.JSX.Element[] {
    const files = this.state.files;
    return !files ? [] : files.map((file, index) => {
      const fileLocator = new URLSearchParams({
        claimId: String(this.props.claimId),
        receiptNumber: String(index),
      });
      const downloadUrl = "/api/claim/downloadfile?" + fileLocator;
      const viewUrl = `/api/claim/viewfilebyname?${new URLSearchParams({
         fileName: file.Name,
         accessToken: file.AccessToken,
         email: file.Email
      })}`;
      const receipt: React.JSX.Element = (
        <div key={index} className="form-group receiptContainer">
          <div className="receiptFileHeaderRow">
            <div className="receiptFileName">{file.Name}</div>
            {files.length < 2 ? undefined : (
              <div className="receiptFileName">{+index + 1} / {files.length}</div>
            )}
            <div className="group-number-button">
              <div className="innerReceiptContainer" key={index}>
                <a
                  className="btn btn-default downloadButton"
                  href={downloadUrl}
                ><img className="downloadSign" src="/img/downloadsign.png" />
                  <p>Download</p>
                </a>
              </div>
            </div>
          </div>
          <SoeDocumentFilePreview fileName={file.Name} viewUrl={viewUrl} />
        </div>
      );
      return receipt;
    });
  }

  render() {
    if (!this.state.claim) {
      return <div className="status-loading-animated-ellipsis">Loading</div>;
    }

    const actions = this._createActions();

    const errors = this._createErrors();

    let claimStatusClass = "label ";
    switch (this.state.claim.Status) {
      case 0:
        claimStatusClass += "label-primary";
        break;
      case 1:
        claimStatusClass += "label-success";
        break;
      case 2:
        claimStatusClass += "label-danger";
        break;
      case 3:
        claimStatusClass += "label-info";
        break;
    }
    let claimFlagClass = "";
    switch (this.state.claim.Flag) {
      case 0:
        claimFlagClass += "";
        break;
      case 1:
        claimFlagClass += "alert alert-warning";
        break;
    }

    const linkedClaims = this.state.claim.SimilarClaims
      ? this.state.claim.SimilarClaims.map((dupe: IPresentationClaim) => {
        return (
          <span key={dupe.Id} className="litem">
            <a
              href={"ApproveClaim?claimId=" + dupe.Id}
              target="_blank"
              key={dupe.Id}
            >
              claim {dupe.Id}
            </a>
          </span>
        );
      })
      : [];

    let claimDateClass = "";
    if (getDiffDays(new Date(), new Date(this.state.claim.Date)) > 90) {
      claimDateClass = "label label-warning";
    }
    let list: string[] = [];
    if (this.state.soeFields != undefined) {
      list = this.state.soeFields;
    }
    return (
      <div className={"updateClaimContainer" + (this.canEditClaim() ? " edit-mode" : " view-mode")}>
        <div className="invoice-wrapper">
          <div className="view-claim-header">
            <img onClick={(e) => this._goBack()} className="back back2" src="/img/back.png" alt={"back"} />
            <div className="Title-and-info">
                <h1 className="View-Claim-Heading-Text">
                  <b>
                    {this.canChangeClaimStatus()
                      ? "View Contractor"
                      : "View Contractor"}{" "}
                    SOE
                  </b>
                </h1>
            </div>
          </div>

          <div className="container-view-claim">
            <div className="row">
              {DisplayEmployeeInfoSmall(this.state.profile)}
            </div>
          </div>

          <div className="body-container">
            <div className="row">
              {claimFlagClass == "" ? null : (
                <div className="col-xs-12">
                  <div className={claimFlagClass}>
                    Possible duplicates found ({linkedClaims})
                  </div>
                </div>
              )}
              <div className="col-xs-12">
                <div className="soeContainer">
                  <form className="contain-field" onSubmit={(e) => this._updateClaim(e)}>
                    <div className="statusBox">
                      <div className="soe-status-line">
                        <label className="control-label claimAttributeHeading">
                          Status:{" "}
                        </label>
                        <div className="field-value">
                        <span className={claimStatusClass}>
                          {this.state.claim.StatusStr}{" "}
                        </span>
                        </div>
                      </div>

                      {this._getApprover(this.state.claim)}
                    </div>

                    <div className="grey-box claimGroup">
                      <div className="blue-box">
                        {!this.state.claimCategories ? <div className="status-loading-animated-ellipsis">Categories Loading</div> : <>
                          {this._getCategory(this.state.claim, this.state.claimCategories)}
                          {this._getSubCategory(this.state.claim, this.state.claimCategories)}
                        </>}
                        {list.indexOf("Project") > -1 ? this._getProject(this.state.claim) : null}
                        {list.indexOf("Base") > -1 ? this._getCrewBase(this.state.claim) : null}
                      </div>

                      <div className="blue-box">
                        {this._getDateOfReceipt(this.state.claim, claimDateClass)}
                        {this._getTravelDate(this.state.claim, claimDateClass)}
                      </div>

                      <div className="blue-box">
                        {this._getCurrency(this.state.claim)}
                        {this._getAmount(this.state.claim)}
                        {this._getEquivalentAmount(this.state.claim)}
                      </div>
                    </div>

                    <div className="form-addtext">
                      {this._getDescription(this.state.claim)}
                      {this.state.claim.Comments == null ||
                        this.state.claim.Comments === ""
                      }
                      <div className="line-blue"></div>
                      <div className={"text-field" + (this.canChangeClaimStatus() || this.canEditClaim() ? " editable-comment-textfield" : "")}>
                        {this.canChangeClaimStatus() || this.canEditClaim() ? <div>
                          <textarea
                            id="comment-input"
                            placeholder=" "
                            onChange={(e) => this._updateComments(e)}
                            value={this.state.NewComment}
                          />
                          <label htmlFor="comment-input">Comment</label>
                        </div> : undefined}
                        <div className="commentsList">{this.getComments(this.state.claim)}</div>
                      </div>
                      <div className="claimDataContainer">
                        <div className="">
                          {errors}
                          {actions}
                        </div>
                      </div>
                    </div>
                  </form>

                  <div className="outerReceiptContainer">
                    {/*{list.indexOf("Receipt") > -1 ? this._getReceipts(receipts) : null}*/}
                    {this._getReceipts(this.state.claim)}
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    );
  }
  ;
}
