import React from "react";
import InvoiceRosterViewAirAtlanta from "./InvoiceRosterViewAirAtlanta";
import InvoiceRosterViewLegendAirlines from "./InvoiceRosterViewLegendAirlines";
import "./InvoiceRosterView.css";
import type { NavInvoiceScreenParams, RdbPersonData } from "../features/Api";
import api from "../features/Api";
import { HttpResponseError } from "../features/httpUtils";
import InvoiceRosterViewLeonGraphql from "./InvoiceRosterViewLeonGraphql";
import type { SignedDocumentRow } from "../types/Api/Contract";
import { isAirAtlanta, parseContract } from "../features/roster/air_atlanta/FeeCalculatorUtils";

type Props = {
    locator: NavInvoiceScreenParams,
    contractFileLink?: string,
};

type SubProps = Props & {
    latestContract: null | SignedDocumentRow,
    rdbData: null | RdbPersonData,
    onWarning: (message: string) => void,
};

type State = {
    warnings: string[],
    rdbData: null | RdbPersonData,
    latestContract: null | SignedDocumentRow,
};

export function getCompanyRenderer(company: string): null | ((subProps: SubProps) => React.JSX.Element) {
    company = company.toUpperCase();
    if (isAirAtlanta(company)) {
        return subProps => <InvoiceRosterViewAirAtlanta {...subProps} />;
    } else if (company === "F2R - LALEUR"
            || company === "F2R - LALUSD"
            || company === "HEL - LALEUR"
            || company === "D2W - LALEUR"
    ) {
        return subProps => <InvoiceRosterViewLegendAirlines {...subProps} />;
    } else if (company === "F2R - ETFEUR"
            || company === "F2R - JUPEUR"
            || company === "F2R - ASTEUR"
            || company === "F2R - STMEUR"
            || company === "F2R - RPDEUR"
    ) {
        return subProps => <InvoiceRosterViewLeonGraphql {...subProps} />;
    } else {
        return null;
    }
}

export default class InvoiceRosterView extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            warnings: [],
            rdbData: null,
            latestContract: null,
        };

        api.Employee.GetRdbData(this.props.locator).then(async rdbData => {
            this.setState({ rdbData });
            const latestContract = await api.Contract.GetLatestContractData(rdbData);
            this.setState({ latestContract });
        }).catch(error => {
            if (error instanceof HttpResponseError &&
                error.response.status === 501
            ) {
                // this API only works for clients that are linked between Navision and RDB
                // for others we just don't show anything
                return;
            }
            this.setState({
                warnings: [
                    ...this.state.warnings,
                    "Failed to fetch Contract Data - " + error,
                ],
            });
        });
    }

    private get parsedContract() {
        if (this.state.latestContract) {
            return parseContract(this.state.latestContract);
        }
        return null;
    }

    render() {
        const subProps: SubProps = {
            ...this.props,
            latestContract: this.state.latestContract,
            rdbData: this.state.rdbData,
            onWarning: (message: string) => this.setState({
                warnings: [
                    ...this.state.warnings,
                    message,
                ],
            }),
        };
        const renderer = getCompanyRenderer(this.props.locator.company);
        const content = !renderer ? null : renderer(subProps);
        const rdbDataSection = <div className="rdb-data-section">
            <div className="rdb-data-section-row">
                {!this.state.rdbData?.rdbLink ? null : <a target="_blank" href={this.state.rdbData.rdbLink}>RDB Link</a>}
                {!this.parsedContract?.dailyFee ? null : <span>Contract Daily Fee: {this.parsedContract.dailyFee}</span>}
                {!this.parsedContract?.nia ? null : <span>NIA in Contract: {this.parsedContract.nia}</span>}
                {!this.props.contractFileLink ? null : <a target="_blank" href={this.props.contractFileLink}>Contract Link</a>}
            </div>
            {this.parsedContract?.title && <div className="rdb-data-section-row">{this.parsedContract.title}</div>}
        </div>;
        return <div className="invoice-roster-view">
            {rdbDataSection}
            {content}
            <ul className="warnings-list">
                {this.state.warnings.map((w,i) => <li key={i}>{w}</li>)}
            </ul>
        </div>;
    }
}
