
export const getDiffDays = (date1, date2) => {
  const diffTime = Math.abs(date2.getTime() - date1.getTime());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

export const CreateHeader = (list) => {
  return (<tr>
    <th className="numberColumn noSort"/>
    <th>Activity Date</th>
    <th className={(list.indexOf("Activity") > -1) ? "" : "hideMe"}>Activity</th>
    <th className={(list.indexOf("AddonFee") > -1) ? "" : "hideMe"}>Activity 2</th>
    <th className={(list.indexOf("Base") > -1) ? "" : "hideMe"}>Base</th>
    <th className={(list.indexOf("Project") > -1) ? "" : "hideMe"}>Project</th>
    <th className={(list.indexOf("TimeIn") > -1) ? "" : "hideMe"}>Time In</th>
    <th className={(list.indexOf("TimeOut") > -1) ? "" : "hideMe"}>Time Out</th>
    <th className={(list.indexOf("TimeIn2") > -1) ? "" : "hideMe"}>Time In 2</th>
    <th className={(list.indexOf("TimeOut2") > -1) ? "" : "hideMe"}>Time Out 2</th>
    <th className={(list.indexOf("FlightNumber") > -1) ? "" : "hideMe"}>Flight Number</th>
    <th className={(list.indexOf("AircraftReg") > -1) ? "" : "hideMe"}>Aircraft Reg</th>
    <th className={(list.indexOf("DailyHours") > -1) ? "" : "hideMe"}>Daily Hours</th>
    <th className={(list.indexOf("Over12Hours") > -1) ? "" : "hideMe"}>Over 12Hours</th>
    <th className={(list.indexOf("OverTime") > -1) ? "" : "hideMe"}>Over Time</th>
    <th className={(list.indexOf("BlockHours") > -1) ? "" : "hideMe"}>Block Hours</th>
    <th className={(list.indexOf("AddDay") > -1) ? "" : "hideMe"}>ADD. Day</th>
    <th className={(list.indexOf("PerDiem") > -1) ? "" : "hideMe"}>Per diem</th>
    <th className={(list.indexOf("PaymentONOFF") > -1) ? "" : "hideMe"}>Payment ON/OFF</th>
    <th className={(list.indexOf("PositioningHours") > -1) ? "" : "hideMe"}>Position hours</th>
    <th className={(list.indexOf("Flying") > -1) ? "" : "hideMe"}>Flying</th>
    <th className={(list.indexOf("SoldOffDay") > -1) ? "" : "hideMe"}>Sold off day</th>

    <th>Reject</th>
  </tr>);
};

/**
 * @param {undefined | {
 *   FullName: string,
 *   LocalName?: string,
 *   JobTitle: string,
 *   No_: string,
 * }} info
 * @see {EmployeeDetails}
 */
export const DisplayEmployeeInfoSmall = (info) => {
  if (info == undefined)
    return (<div></div>);
  else
    return (<div className="clientprofile-wrapper">
        <div className="item">
          <div className="title-info">
            Name
          </div>
          <b>{info.FullName != "" ? info.FullName : info.LocalName}</b>
        </div>
        <div className="item">
          <div className="title-info">
            Job Title
          </div>
          <b>{info.JobTitle}</b>
        </div>
        <div className="item">
          <div className="title-info">
            Code
          </div>
          <b>{info.No_}</b>
        </div>
    </div>);
};

/** @param {InvoiceStatus} status */
export const GetInvoiceStatusClass = (status) => {

  let invoiceStatusClass = "label ";
  switch (status) {
    case 0:
      invoiceStatusClass += "label-default";
      break;
    case 1:
      invoiceStatusClass += "label-warning";
      break;
    case 2:
      invoiceStatusClass += "label-warning";
      break;
    case 3:
      invoiceStatusClass += "label-danger";
      break;
    case 4:
      invoiceStatusClass += "label-success";
      break;
  }

  return invoiceStatusClass;
};