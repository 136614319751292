

import * as React from "react";
import ReactModal from "react-modal";
import type { createPdf } from "pdfmake/build/pdfmake";


import type { ManualInvoiceDetails as ManualInvoiceDetailsType } from "../types/Api/Invoice";
import type { Dispatch } from "redux";
import { prepareManualInvoiceForPdfExport } from "../features/manualInvoicePdfGeneration";
import { getMonthDate, getMonthFullName, getNthWorkingDayInLatvia } from "../utils/dates";
import { toCrewCodeUc, typed } from "../utils/typing";
import ManualInvoiceDetailsForm from "./ManualInvoiceDetailsForm";
import type { SetupField } from "./ManualInvoiceDetailsTable";
import ManualInvoiceDetailsTable from "./ManualInvoiceDetailsTable";
import type { EmployeeInfoBase, EmployeeInfoWise } from "../types/EmployeeInfo";
import ManualInvoiceAaiMtxSubmissionForm from "./ManualInvoiceAaiMtxSubmissionForm";
import { isAirAtlanta, isMaintenanceDepartment } from "../features/roster/air_atlanta/FeeCalculatorUtils";
import { reload } from "../features/httpUtils";
import InvoiceRosterView from "./InvoiceRosterView";
import type { ManualInvoiceSubmissionFormProps } from "../features/manual-work-invoices/sheetUtils";
import ManualInvoiceFlyJetSubmissionForm from "./ManualInvoiceFlyJetSubmissionForm";
import api from "../features/Api";
import type { InvoiceStatus } from "../features/payrollUtils";

function getYearMonthOfDate(dateCursor: Date) {
  return dateCursor.getUTCFullYear() +
      (dateCursor.getUTCMonth() + 1).toString().padStart(2, "0");
}

function isAaiMtx(profile: EmployeeInfoBase) {
  return isMaintenanceDepartment(profile.Department)
      && isAirAtlanta(profile.CompanyName);
}

export const views = {
  Table: 0,
  Form: 1
};

export function getSheetFormComponent(profile: EmployeeInfoWise): null | ((subProps: ManualInvoiceSubmissionFormProps) => React.JSX.Element) {
    if (isAaiMtx(profile)) {
        return subProps => <ManualInvoiceAaiMtxSubmissionForm {...subProps} />;
    } else if (profile.CompanyName === "CRM - HIJEUR") {
        return subProps => <ManualInvoiceFlyJetSubmissionForm {...subProps} />;
    } else {
        return null;
    }
}

export type Props = {
  profile: EmployeeInfoWise,
  code: string,
  monthsAgo: string | number,
  invoiceId: "" | string | number,
  DeadlineDay: number,
  ExtraDays: number,
  dispatch: Dispatch,
  LastMonthExtraDays: number,
  displayActions: boolean,
  year: number,
  month: number,
  store: unknown,
  provider: unknown,
  isOpen: boolean,
  dateSubmitted: string,
  invoiceMessage?: string,
  invoiceStatusInfo: string,
  invoiceStatus: keyof typeof InvoiceStatus,
};

function getInitialState(props: Props) {
  return {
    madeFirstCall: false,
    view: views.Table,
    CommentText: "",
    selectedDetail: typed<ManualInvoiceDetailsType | null>(null),
    loading: true,
    submitting: false,
    error: null,
    showLateSubmissionExplanationModal: false,
    invoiceStatusInfo: props.invoiceStatusInfo,
    invoiceMessage: props.invoiceMessage ?? "",
    justLoggedExtraDays: false,
    rosterData: typed<ManualInvoiceDetailsType[] | undefined>(undefined),
  };
}

type State = ReturnType<typeof getInitialState>;

export default class ManualInvoiceDetails extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = getInitialState(props);
    window.addEventListener("beforeunload", event => {
        if (this.state.justLoggedExtraDays) {
            event.preventDefault();
            event.returnValue = "dontcloseplizzzz";
            return "dontcloseplizzzz";
        }
    });
  };

  componentDidMount() {
    const payload = {
      employeeId: this.props.profile.Id,
      year: this.props.year,
      month: this.props.month
    };
    this.setState({ loading: true });
    api.Invoice.GetManualInvoiceDetails(payload)
        .then(rosterData => this.setState({
          rosterData, madeFirstCall: true,
        }))
        .finally(() => this.setState({ loading: false }));

    if (!this.state.madeFirstCall)
      this.setState({ madeFirstCall: true });
  };

  shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>) {
    if (nextState.loading) {
      return false;
    }
    return true;
  };

  showManualInvoiceDetailsForm() {
    if (!this.props.profile.Fields ||
        !this.props.profile.Activities
    ) {
      alert("No activities/fields setup, contact administrator");
      return;
    }

    if (!this.state.loading) {
      const payload = {
        employeeId: this.props.profile.Id,
        year: this.props.year,
        month: this.props.month
      };

      api.Invoice.GetManualInvoiceDetails(payload)
          .then(rosterData => this.setState({ rosterData }));
    }
    const self = this;
    self.setState({
      view: views.Form,
      selectedDetail: null,
    });
  };

  showManualInvoiceDetailsUpdateTable(selected: ManualInvoiceDetailsType) {
      this.setState({
        selectedDetail: selected,
        view: views.Form,
      });
  };

  checkForOldTimes() {
    let oldTimes = false;
    for (const invoiceDetail of this.state.rosterData ?? []) {
      if (
        Date.parse(invoiceDetail.ActivityDate) <
        new Date(this.props.year, this.props.month - 1, 1).getTime()
      ) {
        oldTimes = true;
      }
    }
    return oldTimes;
  };

  submitInvoice() {
    if (this.checkForOldTimes() && this.state.CommentText === "") {
      this.setState({
        showLateSubmissionExplanationModal: true
      });
      return false;
    }
    const payload = {
      invoiceId: Number(this.props.invoiceId),
      invoiceStatus: 1,
      text: this.state.CommentText,
    } as const;
    this.setState({ submitting: true });
    api.Invoice.ChangeInvoiceStatus(payload)
        .then(async () => {
          await new Promise<void>(resolve => this.setState({ justLoggedExtraDays: false }, () => resolve()));
          await reload();
        })
        .finally(() => this.setState({ submitting: false }));
    return false;
  };

  onCommentBoxChanged(e: React.ChangeEvent<HTMLTextAreaElement>) {
    this.setState({ CommentText: e.target.value });
  };

  handleCloseModal() {
    this.setState({ showLateSubmissionExplanationModal: false });
  }

  _createActions() {
    const customStyles = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
      },
    };
    const { rosterData } = this.state;
    if (this._canEditInvoiceAccordingToStatus()) {
      return (
        <div className="invoiceActionContainer">
          <ReactModal
            isOpen={this.state.showLateSubmissionExplanationModal}
            contentLabel="Late Submission Explanation"
            shouldCloseOnOverlayClick={true}
            style={customStyles}
            onRequestClose={this.handleCloseModal.bind(this)}
            ariaHideApp={false}
          >
            <div className="custom-ui">
              <div className="paragraphContainer">
                <div className="innerInvoiceActionContainer">
                  <img className="alertImage" src="/img/alert.png"/>
                  <div>
                    <div className="frontPageStatusInfo">
                      You are submitting work hours for a previous month.
                    </div>
                    <div className="frontPageStatusInfo"> Please explain why</div>
                  </div>
                </div>
              </div>
              <textarea
                className="form-control lateSubmissionBox"
                placeholder={"Late submission explanation..."}
                onChange={(e) => this.onCommentBoxChanged(e)}
              />
              <button
                className="btn btn-warning"
                type="button"
                onClick={() => {
                  this.handleCloseModal();
                }}
              >
                Cancel
              </button>
              {this.state.CommentText !== "" ? (
                <button
                  className="btn btn-primary"
                  type="button"
                  disabled={this.state.submitting}
                  onClick={() => {
                    this.submitInvoice();
                  }}
                >
                  Submit
                </button>
              ) : (
                <button className="btn btn-primary" disabled>
                  Submit
                </button>
              )}
            </div>
          </ReactModal>
          <div className="downloadAndSubmitContainer">
            {rosterData && <button
                className="btn btn-warning"
                type="button"
                onClick={(e) => this.extractInvoiceToPdf(rosterData)}
            >Download PDF</button>}
            <button
              type="button"
              disabled={this.state.submitting}
              onClick={() => this.submitInvoice()}
              className={"btn btn-primary frontPageButton" + (this.state.justLoggedExtraDays ? " just-logged-extra-days" : "")}
            >{this.usesSheetForm ? "Submit Comment" : this.props.invoiceStatus === "NotSubmitted" ? "Submit Work Invoice" : "Submit Update"}</button>
          </div>
        </div>
      );
    } else {
      return rosterData && (
        <div className="invoice-submission">
          <span>
            <button
                className="btn btn-warning"
                type="button"
                onClick={(e) => this.extractInvoiceToPdf(rosterData)}
            >Download PDF</button>
          </span>
        </div>
      );
    }
  };

  //Gisli
  //Checks if the invoice status is in a status that allows the employee to edit.
  //Returns true if employee can edit otherwise false.
  _canEditInvoiceAccordingToStatus() {
    const isSubmissionOpen = new Date() <= new Date(this.props.year, this.props.month, this.props.ExtraDays,23,59,59);
    const isInvoiceNotApproved = this.props.invoiceStatus !== "Approved";
    return isSubmissionOpen && isInvoiceNotApproved;
  };

  _updateCommentText(e: React.ChangeEvent<HTMLTextAreaElement>) {
    this.setState({ CommentText: e.target.value, error: null });
  };

  _changeInvoiceMonthSelected(e: React.ChangeEvent<HTMLSelectElement>) {
    const sParameter = e.target.value;
    const sSelMonth = sParameter.substr(4, 2);
    const sSelYear = sParameter.substr(0, 4);
    const monthsAgo = this._getMonthsAgo(sSelMonth, sSelYear);

    window.location.href = "/Home/ManualInvoice?monthsAgo=" + monthsAgo;
  };

  _getMonthsAgo(month: string | number, year: string | number) {

    const currentMonth = new Date().getMonth() + 1;
    const sResult =
      year == new Date().getFullYear()
        ? currentMonth - +month
        : currentMonth + (12 * (new Date().getFullYear() - +year) - +month);
    return sResult;
  };

  private reloadData() {
    this.setState({
      view: views.Table,
      selectedDetail: null,
      loading: true,
    });
    const payload = {
      employeeId: this.props.profile.Id,
      year: this.props.year,
      month: this.props.month
    };
    api.Invoice.GetManualInvoiceDetails(payload)
        .then(rosterData => this.setState({ rosterData }))
        .finally(() => this.setState({ loading: false }));
  }

  private get departmentDeadlineDay() {
    if (isAaiMtx(this.props.profile)) {
      const baseDate = new Date(getMonthDate(this.props, 1));
      baseDate.setUTCMonth(baseDate.getUTCMonth() + 1);
      return getNthWorkingDayInLatvia(baseDate, 1).getUTCDate();
    } else {
      return this.props.DeadlineDay;
    }
  }

  _createManualInvoiceDetailsForm() {
    let list: string[] = [];

    if (this.props.profile.Fields != undefined)
      list = this.props.profile.Fields.fields.split("|");

    const invoiceId = this.props.invoiceId;
    return <ManualInvoiceDetailsForm
        key={this.state.selectedDetail?.Id ?? ""}
        invoiceId={invoiceId}
        selectedDetail={this.state.selectedDetail}
        displayedFieldsList={list}
        activityOptionsList={this.props.profile.Activities?.split("|") ?? []}
        year={this.props.year}
        month={this.props.month}
        DeadlineDay={this.departmentDeadlineDay}
        goBack={() => this.setState({
          view: views.Table,
          selectedDetail: null,
        })}
        onSubmitted={() => {
          this.setState({ justLoggedExtraDays: true });
          this.reloadData();
        }}
        dispatch={this.props.dispatch}
        COMPANYNAME={this.props.profile.CompanyName}
    />;
  }

  _getMonthName(month: number) {
    return getMonthFullName(month + 1);
  };

  getYearMonthOptions() {
    const dateCursor = new Date();
    const disableFirst = this.props.LastMonthExtraDays > dateCursor.getDate();
    let first = true;
    const options = [];
    while (dateCursor.toISOString() > "2017-01-01") {
      const sKey = getYearMonthOfDate(dateCursor);
      const sDisplayText = dateCursor.getUTCFullYear() + " - " +
          this._getMonthName(dateCursor.getUTCMonth());

      options.push(<option key={sKey} value={sKey} disabled={first && disableFirst}>{sDisplayText}</option>);
      dateCursor.setUTCDate(0);
      first = false;
    }
    return options;
  }

  _getYearMonthAtOffset(monthsago: number | string) {
    const dateCursor = new Date();
    dateCursor.setUTCMonth(dateCursor.getUTCMonth() - +monthsago);
    return getYearMonthOfDate(dateCursor);
  };

  private getSelectedYearMonth() {
    if (this.props.monthsAgo == "") {
      let selMonth: string | number = this.props.month;
      const selYear = this.props.year;

      selMonth =
          selMonth.toString().length == 1
              ? "0" + selMonth.toString()
              : selMonth.toString();
      return selYear.toString() + selMonth;
    } else {
      return this._getYearMonthAtOffset(this.props.monthsAgo);
    }
  }

  extractInvoiceToPdf(rosterData: ManualInvoiceDetailsType[]) {
    if (!window.pdfMake) {
      const message = "PDF still loading. Please, wait few seconds and try again";
      if (window.toastr) {
        window.toastr.warning(message);
      } else {
        alert(message);
      }
      return;
    }
    const createPdfTyped: typeof createPdf = window.pdfMake.createPdf;
    const docDefinition = prepareManualInvoiceForPdfExport(this.props, rosterData, null);
    const date = this.getSelectedYearMonth();
    createPdfTyped(docDefinition).download(date + "_work_invoice.pdf");
  };

  private get usesSheetForm() {
    return getSheetFormComponent(this.props.profile) !== null;
  }

  get contractFileLink() {
      return "/api/Contract/GetLatestContractFile?" + new URLSearchParams({
          year: this.props.year.toString(),
          month: this.props.month.toString(),
          employeeCode: this.props.profile.No_,
          company: this.props.profile.CompanyName,
      }) + "#page=11";
  }

  render() {
    const selYearMonth = this.getSelectedYearMonth();
    const self = this;
    const hasRosterData = self.state.rosterData && self.state.rosterData.length > 0;

    const actions = this._createActions();

    if (this.state.view == views.Form) {
        return <div>{this._createManualInvoiceDetailsForm()}</div>;
    } else if (!this.state.madeFirstCall || this.state.loading) {
      return <div>
        <h1 className="loading status-loading-animated-ellipsis">
          Loading invoice
        </h1>
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>;
    }

    const canSubmit = this._canEditInvoiceAccordingToStatus();
    const inputActionPanel = !canSubmit
      ? <strong className="submission-is-closed-message">Submission is closed</strong>
      : !this.usesSheetForm
      ? <button
        type="button"
        className="btn btn-primary floatRight"
        onClick={() => this.showManualInvoiceDetailsForm()}
      >Log time</button>
      : null;

    let monthForLastDay = this.props.month;
    if (monthForLastDay == 12) monthForLastDay = 0;
    const header = <div className="manual-invoice-header-section">
      <div className="manual-invoice-header">
        <div>
          <div className="timeContainer">
            <select
              onChange={(e) => this._changeInvoiceMonthSelected(e)}
              value={selYearMonth}
            >
              {this.getYearMonthOptions()}
            </select>
          </div>
        </div>
        <div className="form-group">
          <div className="logTime">
            <div className="frontPageStatusInfo">
              Status: <span className="invoice-status-text" data-status={this.props.invoiceStatus}>{this.state.invoiceStatusInfo}</span>
            </div>
            <span className="deadlineSpan">
              <img
                className="deadline"
                style={{ display: "inline-block" }}
                src="/img/deadline.png"
              />
              Deadline: {this.departmentDeadlineDay}{" "}
              {this._getMonthName(monthForLastDay).toUpperCase()}
            </span>
            {inputActionPanel}
          </div>
        </div>
      </div>
      {this.state.invoiceMessage && <div className="invoice-message-panel">
          <span className="invoice-message-label">Invoice Message Box</span>
          <span className="invoice-message-value">{this.props.invoiceMessage}</span>
      </div>}
    </div>;

    const sheetDataSubmittedStatusMessage = "Your Work Invoice has been submitted to the payroll officer. Waiting for approval";
    const canShowSheetForm = canSubmit &&
        this.props.invoiceStatus === "NotSubmitted" &&
        this.state.invoiceStatusInfo !== sheetDataSubmittedStatusMessage;
    const sheetFormParams: ManualInvoiceSubmissionFormProps = {
        profile: this.props.profile,
        invoiceId: +this.props.invoiceId,
        year: this.props.year,
        month: this.props.month,
        onSubmitted: (sheetRequestData: { CommentBox: string }) => {
            toastr.success("Your work report was successfully submitted");
            this.reloadData();
            this.setState({
                invoiceStatusInfo: sheetDataSubmittedStatusMessage,
                invoiceMessage: sheetRequestData.CommentBox,
            });
        },
    } as const;
    const sheetForm = getSheetFormComponent(this.props.profile);
    const footer = <div className="work-report-submission-section">
        {canShowSheetForm && sheetForm && sheetForm(sheetFormParams)}
        <div className="roster-view-subsection" style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ flex: 1 }}></div>
            <InvoiceRosterView
                locator={{
                    company: this.props.profile.CompanyName,
                    employeeCode: toCrewCodeUc(this.props.profile.No_),
                    year: this.props.year,
                    month: this.props.month,
                }}
                contractFileLink={this.contractFileLink}
            />
        </div>
    </div>;

    if (!hasRosterData) {
      return <div className="table-responsive">
          {header}
          {!this.usesSheetForm && <h1 className="loading">Please, click "Log Time" to initiate Work Invoice submission</h1>}
          <div className="work-report-submission-content">
            <div className="work-report-submission-section-wrapper">
              {footer}
            </div>
          </div>
      </div>;
    } else {
      if (this.state.rosterData) {
        const displayedFieldsList = (this.props.profile.Fields?.fields?.split("|") ?? []) as SetupField[];
        return <div>
            {header}
            <div className="manual-invoice-logged-time-result-section">
              <div className="table-responsive">
                <ManualInvoiceDetailsTable
                    rosterData={this.state.rosterData}
                    editRow={(detail: ManualInvoiceDetailsType) => this.showManualInvoiceDetailsUpdateTable(detail)}
                    canEdit={!this.usesSheetForm && this._canEditInvoiceAccordingToStatus()}
                    displayedFieldsList={this.usesSheetForm ? undefined : displayedFieldsList}
                    year={this.props.year}
                    month={this.props.month}
                />
              </div>
              <div className="commentsTimesheet">
                <h4>Comments</h4>
                <div>
                  <textarea
                    className={"form-control "}
                    style={{
                      display: this._canEditInvoiceAccordingToStatus()
                        ? "inline-block"
                        : "none",
                    }}
                    onChange={(e) => this._updateCommentText(e)}
                    value={this.state.CommentText}
                  />
                </div>
              </div>
              <div className="submitInfo">
                {this.props.dateSubmitted !== "" ? (
                  <div className="submitDateInfo">
                    This work invoice was last submitted{" "}
                    {this.props.dateSubmitted}
                  </div>
                ) : null}
                {actions}
              </div>
            </div>
            {footer}
        </div>;
      }
    }
  };
}
