import type { InvoiceAction } from "../types/Api/Invoice";
import * as React from "react";
import { InvoiceActionLine } from "./InvoiceActionLine";
import { neverNull } from "../utils/typing";

type WorkInvoiceApproveDashboardProps = {
    invoiceActions: InvoiceAction[],
    _ShowTimeSheets: (invoiceAction: InvoiceAction) => void,
    handleSort: (e: React.MouseEvent<HTMLTableCellElement>) => void,
};

export const WorkInvoiceApproveTable = (props: WorkInvoiceApproveDashboardProps) => {
    const actions = props.invoiceActions.map((i, index) => {
        return InvoiceActionLine({
            invoiceAction: i,
            _ShowTimeSheets: props._ShowTimeSheets,
        });
    });
    const currentUser = window.EMPLOYEE_INFO ?? neverNull();
    const isMultiCompany = currentUser.IsAdmin || currentUser.ApprovalCompaniesList.length > 1;

    return (<div className="table-responsive">
        <table
            className={"invoices table table-striped table-condensed work-invoice-approve-dashboard" + (isMultiCompany ? " multi-company" : "")}>
            <thead>
                <tr>
                    <th id="thEmployee" className="sort" onClick={(e) => props.handleSort(e)}>Contractor</th>
                    <th id="thEmployeeCode" className="sort" onClick={(e) => props.handleSort(e)}>Code</th>
                    <th id="thCompanyName" className="sort" data-field-name="CompanyName"
                        onClick={(e) => props.handleSort(e)}>Company
                    </th>
                    <th id="thDepartmentCode" className="sort" onClick={(e) => props.handleSort(e)}>Department</th>
                    <th id="thJobTitle" className="sort" onClick={(e) => props.handleSort(e)}>Jobtitle</th>
                    <th>Status</th>
                    <th className="noSort"/>
                </tr>
            </thead>
            <tbody>
            {actions}
            </tbody>
        </table>
    </div>);
};