import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getJson } from "./httpUtils";


// Create class ProfileSlice with with async thunks: loadProfile

// Async thunks
export const loadProfile = createAsyncThunk(
  "profile/loadProfile",
  async (payload, thunkAPI) => {
    const { id } = payload;
    /** @var {EmployeeInfoWise} profile */
    const profile = await getJson(`/api/employee/LoadProfile?employeeId=${id}`);
    return profile;
  }
);

export const profileSlice = createSlice({
  name: "profile",
  /** @type {ProfileReducerState} */
  initialState: {
    profile: {},
    loading: false,
    error: null,
    localProfile: {},
    _originalPayload: {}
  },
  reducers: {
  },
  extraReducers: builder => {
    builder.addCase(loadProfile.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    }),
    builder.addCase(loadProfile.fulfilled, (state, action) => {
      state.loading = false;
      state.profile = action.payload;
    }),
    builder.addCase(loadProfile.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  }
});
