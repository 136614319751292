import type  {
    FullPayslip,
    NavCompanyData,
    BoxedPayslipAndAllCompany, PayEntry, PayslipAndTransfersAndAllCompany,NavInvoiceId
} from "../types/NavisionPayslip";
import type { INavInvoiceConfirmation, INavInvoiceConfirmationBase } from "../types/Api/Invoice";
import type { Workbook } from "exceljs";
import CustomFields from "./CompanySpecificFields";
import type { COMPANYNAME } from "../features/Api";
import { neverNull,toUpperCase } from "./typing";

export type PayEntriesMapping = ReadonlyMap<COMPANYNAME, Record<NavInvoiceId, PayEntry[]>>;

export function getPayEntries(
    record: BoxedPayslipAndAllCompany,
    companyToInvoiceToPayEntries: ReadonlyMap<COMPANYNAME, Record<NavInvoiceId, PayEntry[]>>
) {
    const COMPANYNAME = toUpperCase(record.allCompany.COMPANYNAME);
    return companyToInvoiceToPayEntries
        .get(COMPANYNAME)?.[record.payslip.Numer];
}

const amountDifferenceSinceLastConfirmation = (fullPayslip: FullPayslip, lastConfirmation: INavInvoiceConfirmationBase | INavInvoiceConfirmation | null) => {
    if (!lastConfirmation) {
        return 0;
    }

    const difference = + fullPayslip.bankInfo["Upphaed"] - (lastConfirmation.confirmation_total ?? neverNull());
    return Math.abs(difference) >= 0.01 ? difference : 0;
};

const deadlinePassed = (deadline: Date | null) => {
    if (!deadline) {
        return false;
    } else {
        return deadline.getTime() < Date.now();
    }
};

const getNewTotalText = (companyData: NavCompanyData, fullPayslip: FullPayslip) => {
    return ` New total: ${companyData.glSetup["LCY Code"]} ${(+fullPayslip.bankInfo["Upphaed"]).toFixed(2)}`;
};

const getFormattedDate = (date: Date) => {
    const day = ("0" + (date.getDate())).slice(-2);
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
};

const getDownloadFeeStatementOverviewLink = async (
    ExcelJS: { Workbook: typeof Workbook },
    records: PayslipAndTransfersAndAllCompany[]
) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Fee Statement Overview");
    worksheet.columns = [
        { header: "No.", key: "no", width: 7 },
        { header: "Name", key: "name", width: 30 },
        { header: "Type", key: "operationType", width: 5 },
        { header: "Entry Type", key: "entryType", width: 14 },
        { header: "Description", key: "description", width: 21 },
        { header: "Period from (Per diem)", key: "periodFrom", width: 24 },
        { header: "Period end (Per diem)", key: "periodEnd", width: 24 },
        { header: "Units", key: "units", width: 8 },
        { header: "Rate Amount", key: "rateAmount", width: 15 },
        { header: "Amount", key: "amount", width: 10 },
        { header: "Country", key: "country", width: 10 },
        { header: "Department", key: "department", width: 10 },
        { header: "Duty Base", key: "entryBase", width: 20 },
        { header: "Contract Base", key: "crewBase", width: 10 },
        { header: "Project", key: "project", width: 10 },
    ];

    for (const record of records) {
        const invoiceCustomFields = new CustomFields(record.allCompany, record.payslip);
        for (const transfer of record.transfers) {
            const entryCustomFields = new CustomFields(record.allCompany, transfer);
            const fromDate = new Date(transfer["Utb_timabil fra"]);
            const endDate = new Date(transfer["Utb_timabil til"]);

            worksheet.addRow({
                no: record.payslip.Kennitala,
                name: `${record.payslip["First Name"]} ${record.payslip["Last Name"]}`,
                operationType: {
                    1: "Pay",
                    2: "Claim",
                }[transfer.Tegund] ?? "N/a",
                entryType: transfer.Faerslutegund,
                description: transfer.Texti,
                periodFrom: getFormattedDate(fromDate),
                periodEnd: getFormattedDate(endDate),
                units: +transfer.Einingar,
                rateAmount: +transfer["Upphaed taxta"],
                amount: +transfer.Einingar * +transfer["Upphaed taxta"],
                country: transfer["Land (Dagpeningar)"],
                department: entryCustomFields.departmentCode,
                entryBase: entryCustomFields.base,
                crewBase: invoiceCustomFields.base,
                project: invoiceCustomFields.project,
            });
        }
    }

    worksheet.autoFilter = "A1:J1";

    const filled = await workbook.xlsx.writeBuffer();
    const blob = new Blob([filled], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    return URL.createObjectURL(blob);
};

export { amountDifferenceSinceLastConfirmation, deadlinePassed, getFormattedDate, getNewTotalText, getDownloadFeeStatementOverviewLink };