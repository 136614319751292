import AppEnvironment from "./AppEnvironment";
import type { JsonValue } from "../types/utility";

export class HttpResponseError extends Error {
    constructor(
        public readonly baseMessage: string,
        public readonly errorSuffix: string,
        public readonly response: Response,
        public readonly expected = false
    ) {
        super((response.statusText || response.status) + ": " + baseMessage + errorSuffix);
    }

    static async fromResponse(response: Response, errorSuffix: string) {
        const message = await extractErrorMessage(response);
        return new HttpResponseError(message, errorSuffix, response);
    }
}

async function extractErrorMessage(response: Response): Promise<string> {
    const errorStr = (await response.text()
        .then(async text => {
            try {
                const asData: unknown = JSON.parse(text);
                if (asData && typeof asData === "object") {
                    if ("ExceptionMessage" in asData) {
                        return String(asData.ExceptionMessage);
                    } else if ("Message" in asData) {
                        return String(asData.Message);
                    }
                }
                return text;
            } catch (error) {
                return text;
            }
        })
        .catch(error => "(failed to retrieve response - " + error + ")")) || "(no response)";
    return errorStr;
}

let redirecting = false;

async function redirect(url: string) {
    redirecting = true;
    window.location.href = url;
    await new Promise(resolve => {}); // a promise that never resolves - since we started redirect to another page
}

export async function reload() {
    await redirect(window.location.href);
}

/** @throws {Error} */
export async function assertSuccessResponse(response: Response, errorSuffix = ""): Promise<void> {
    if (response.status >= 200 && response.status < 300) {
        return;
    } else if (response.status === 401) {
        if (redirecting) {
            await new Promise(resolve => {}); // a promise that never resolves - since we started redirect to another page
        } else if (confirm("You were logged out. Go to login page?")) {
            await redirect("/Account/Login?" + new URLSearchParams({
                returnUrl: window.location.href,
            }));
        } else {
            throw new HttpResponseError("Please, login", errorSuffix, response, true);
        }
    }
    throw await HttpResponseError.fromResponse(response, errorSuffix);
}

export async function fetchOrFail(route: string, requestInit: RequestInit) {
    const url = route.startsWith("/") ? AppEnvironment.API_BASE_URL + route : route;
    const startMs = Date.now();
    const errorSuffix = " - failed to " + requestInit.method + " " + route;
    let response;
    try {
        response = await fetch(url, requestInit);
    } catch (error) {
        if (error instanceof Error) {
            const elapsedMs = Date.now() - startMs;
            const baseMessage = isObscuredTransportError(error)
                ? "No connection to server in " + elapsedMs / 1000 + " s.: " + error.message
                : error.message;
            error.message = baseMessage + errorSuffix;
        }
        throw error;
    }
    await assertSuccessResponse(response, errorSuffix);
    return response;
}

export async function sendForSuccess(route: string, method: "POST" | "DELETE" | "PATCH", params: Record<string, unknown>) {
    const requestInit: RequestInit = {
        method: method,
        body: JSON.stringify(params),
        headers: {
            "content-type": "application/json",
        },
    };
    return fetchOrFail(route, requestInit);
}

export async function postForSuccess(route: string, params: Record<string, unknown>): Promise<Response> {
    return sendForSuccess(route, "POST", params);
}

/** probably meaningless now that we have postForSuccess() that returns whole Response... */
export async function postForSuccessStatus(route: string, params: Record<string, unknown>): Promise<number> {
    const response = await postForSuccess(route, params);
    return response.status;
}

export async function postForJson<TData = unknown>(route: string, params: Record<string, unknown>): Promise<TData> {
    const response = await postForSuccess(route, params);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return response.json();
}

export async function postForSuccessStatusToast(route: string, params: Record<string, unknown>): Promise<number> {
    const status = await postForSuccessStatus(route, params);
    const message = "Update submitted successfully";
    if (window.toastr) {
        window.toastr.success(message);
    } else {
        alert(message);
    }
    return status;
}

/**
 * common causes:
 *   - CORS (including cases when server does not send access-control header due to exception)
 *   - no internet connection
 *   - server refused connection (like if it's down)
 */
export function isObscuredTransportError(error: object) {
    return (
        error instanceof TypeError ||
        (error as { name: string }).name === "TypeError"
    ) && (
        (error as { message: string }).message.startsWith("Failed to fetch") || // windows/linux
        (error as { message: string }).message.startsWith("Load failed") // apple
    );
}

export function buildUrl(route: string, params: Record<string, string | number> = {}) {
    const queryEntries = Object.entries(params)
        .filter(([k,v]) => v !== undefined && v !== null)
        .map(([k,v]) => [k, String(v)]);

    const querySuffix = queryEntries.length > 0
        ? "?" + new URLSearchParams(queryEntries)
        : "";
    return route + querySuffix;
}

export async function getUrlResponse(url: string): Promise<Response> {
    return fetchOrFail(url, {
        method: "GET",
    });
}

export async function getResponse(route: string, params: Record<string, string | number> = {}): Promise<Response> {
    const url = buildUrl(route, params);
    return getUrlResponse(url);
}

export async function getJson<TData extends JsonValue | void = JsonValue | void>(route: string, params: Record<string, string | number> = {}): Promise<TData> {
    const response = await getResponse(route, params);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return response.json();
}

const URL_TO_WHEN_JSON_RESPONSE_BODY: Record<string, Promise<string>> = {};

/** use with care, it's a legal memory leak factory */
export async function getOrReuseJson<TData extends JsonValue = JsonValue>(route: string, params: Record<string, string | number> = {}): Promise<TData> {
    const url = buildUrl(route, params);
    let whenResponseBody = URL_TO_WHEN_JSON_RESPONSE_BODY[url] ?? null;
    if (whenResponseBody) {
        // only reuse successful responses
        try {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return await whenResponseBody.then(jsonStr => JSON.parse(jsonStr));
        } catch {}
    }
    whenResponseBody = getUrlResponse(url).then(rs => rs.text());
    URL_TO_WHEN_JSON_RESPONSE_BODY[url] = whenResponseBody;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return await whenResponseBody.then(jsonStr => JSON.parse(jsonStr));
}