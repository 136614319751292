import type { ManualInvoiceDetails } from "../types/Api/Invoice";
import { entries,neverNull } from "./typing";

const getFieldsToDisplay = (rosterData: ManualInvoiceDetails[]) => {
    const allFields = new Set<keyof ManualInvoiceDetails>();
    const fieldsWithData = new Set<keyof ManualInvoiceDetails>();
    for (const row of rosterData) {
        for (const [key, value] of entries(row)) {
            allFields.add(key);
            if (!isEmptyValue(row, key) &&
                key !== "Id" &&
                key !== "InvoiceId" &&
                key !== "ActivityDate" &&
                key !== "EmployeeId" &&
                key !== "EmployeeCode" &&
                key !== "Year" &&
                key !== "Month" &&
                key !== "ActivityDateStr"
            ) {
                fieldsWithData.add(key);
            }
        }
    }
    return [...allFields].filter(f => fieldsWithData.has(f));
};

const isEmptyValue = (r: ManualInvoiceDetails, field: keyof ManualInvoiceDetails) => {
    if (field === "FlyingHoursStatus" && (r.FlyingHoursStatus ?? neverNull()).trim() === "NO_HOURS") {
        return true;
    }
    return !r[field];
};

export { getFieldsToDisplay, isEmptyValue };