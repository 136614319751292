import type { OfficerFlightDeckFees } from "../../../types/Api/Contract";
import type { ImpersonalSalaryJournalRecord } from "../../ExternalRostersApi";
import  { FeeEntryType } from "../../ExternalRostersApi";
import type {
    PersonFeeCalculationParams
} from "./FeeCalculatorUtils";
import  { countDutyBlockMinutes
} from "./FeeCalculatorUtils";
import {
    getNias
} from "./FeeCalculatorUtils";
import {
    getGroundActivityCategory
} from "./FeeCalculatorUtils";
import { WW_ROTATION_PERIOD
} from "./FeeCalculatorUtils";
import {
    countDutyDays,
    fixManually,
    getBestError,
    groupSequences,
    isSoldDayOff,
    parseContract, parseRotationPeriod
} from "./FeeCalculatorUtils";
import { neverNull, typed } from "../../../utils/typing";
import type { RotationDay } from "./RotationDaysGrouper";
import { getRotationDays } from "./RotationDaysGrouper";
import { type AbsoluteMonth,decrementMonth, getDatePart, getMonthEndDate, getMonthEndDateObj, getMonthStartDate } from "../../../utils/dates";
import type { RosterItem } from "./Roster";
import type { IataToIana } from "../../../types/utility";
import type { NavInvoiceScreenParams } from "../../Api";
import api from "../../Api";

const OVERTIME_BLOCK_HOURS_THRESHOLD = 65;

function isOffRotationActivity(activity: RosterItem) {
    return activity.DayNumberInRotaion === -1
        || activity.GNDACTCODETITLE === "CBO"
        || activity.GroundActivityInfo?.toUpperCase().includes("EMERGENCY LEAVE")
        || activity.GroundActivityInfo?.toUpperCase().includes("WAITING FOR LICENSE")
        || activity.GroundActivityInfo?.toUpperCase().includes("ROTATION")
        || activity.GroundActivityInfo?.toUpperCase().includes("VACATION UNPAID");
}

function endOffRotation(rotationDays: RotationDay[]) {
    const lastDay = rotationDays.slice(-1)[0];
    if (!lastDay) {
        return true;
    }
    if (lastDay.DayNumberInRotaion < 1) {
        return true;
    }
    const activities = rotationDays.flatMap(r => r.Activities);
    const lastActivity = activities.slice(-1)[0];
    const activityBeforeLast = activities.slice(-2)[0];
    if (!lastActivity) {
        return true;
    }
    if (lastDay.Activities.every(a => isOffRotationActivity(a)) ||
        lastActivity.ActivityType === "BLANK" &&
        activityBeforeLast &&
        isOffRotationActivity(activityBeforeLast)
    ) {
        return true;
    }
    return false;
}

/**
 * @param {number} rotationDays :
 *     [-1 -1 22 23 24 -1 -1 -1  1  2  3] -> [] [22 23 24] [1 2 3]
 *     [      22 23 24 -1 -1 -1 -1 -1 -1] ->    [22 23 24] []
 * The paid overtime hours are counted over the period of a rotation,
 * rotation often starts in the previous month and finishes in current month.
 * We consider that the first activity that does not sequentially follow the previous one marks the end of the rotation.
 * This function returns the last sequential group of days. If last day of the month is off-rotation, then
 * an empty array is returned, since last sequence was already covered in the previous payroll month.
 */
export function splitRotations(rotationDays: RotationDay[]): RotationDay[][] {
    const rotations: RotationDay[][] = [[]];
    let prevDay = null;
    for (const rotationDay of rotationDays) {
        const ending = [...prevDay ? [prevDay] : [], rotationDay];
        if (!endOffRotation(ending)) {
            rotations[rotations.length - 1].push(rotationDay);
        } else if (rotations[rotations.length - 1].length > 0) {
            rotations.push([]);
        } else if (!prevDay) {
            rotations.push([]);
        }
        prevDay = rotationDay;
    }
    return rotations;
}

export function endsPrematurely(rotation: RotationDay[]) {
    const lastActivity = rotation.slice(-1)[0]?.Activities.slice(-1)[0];
    if (endOffRotation(rotation) ||
        lastActivity && isOffRotationActivity(lastActivity)
    ) {
        return true;
    }
    const lastDay = rotation.slice(-1)[0];
    const lastMonthDate = getMonthEndDateObj(lastDay.Date).toISOString();
    if (getDatePart(lastDay.Date) < getDatePart(lastMonthDate)) {
        return true;
    }
    return false;
}

/**
 * @param rotationDays - [1..72]
 * @param rotationPeriod = 30
 * @return [1..30], [31..60], [61..72]
 */
function chunk(rotationDays: RotationDay[], rotationPeriod: number): RotationDay[][] {
    const chunks: RotationDay[][] = [];
    for (let i = 0; i < rotationDays.length; i += rotationPeriod) {
        chunks.push(rotationDays.slice(i, i + rotationPeriod));
    }
    return chunks;
}

function getRotations(
    rotationDays: RotationDay[],
    precedingRemainder: RotationDay[],
    rotationPeriod: number
) {
    precedingRemainder = chunk(precedingRemainder, rotationPeriod).pop() ?? [];
    let rotations: RotationDay[][];
    if (precedingRemainder.length !== rotationPeriod) {
        // if it was longer than 30 days, then it was already handled in the previous month - see below
        rotations = splitRotations([...precedingRemainder, ...rotationDays]);
    } else {
        rotations = splitRotations(rotationDays);
    }
    return rotations.flatMap(rot => rot.length <= rotationPeriod ? [rot] : [
        rot.slice(0, rotationPeriod), rot.slice(rotationPeriod),
    ]);
}

export function getAllRotationBlockHours(
    rotationDays: RotationDay[],
    precedingRemainder: RotationDay[],
    rotationPeriod: number
) {
    const rotations = getRotations(rotationDays, precedingRemainder, rotationPeriod);
    return rotations.filter(rotation => rotation.length > 0).map(rotation => {
        return {
            blockHours: countDutyBlockMinutes(rotation) / 60,
            startDay: rotation[0],
            endDay: rotation.slice(-1)[0],
        };
    });
}

export function getTerminatedRotationBlockHours(
    rotationDays: RotationDay[],
    precedingRemainder: RotationDay[],
    rotationPeriod: number
) {
    const rotations = getRotations(rotationDays, precedingRemainder, rotationPeriod);
    if ((rotations.slice(-1)[0] ?? []).length < rotationPeriod &&
        !endsPrematurely(rotationDays) ||
        rotations.slice(-1)[0]?.length === 0
    ) {
        // exclude unterminated ending - will be covered next month
        rotations.pop();
    }
    return rotations.filter(rotation => rotation.length > 0).map(rotation => {
        return {
            blockHours: countDutyBlockMinutes(rotation) / 60,
            startDay: rotation[0],
            endDay: rotation.slice(-1)[0],
        };
    });
}

export type RotationPeriods = ReturnType<typeof getTerminatedRotationBlockHours>;

export async function getPrecedingUnterminatedRotationDays(
    currentMonth: NavInvoiceScreenParams
): Promise<RotationDay[]> {
    const MAX_BACKTRACK = 6;
    const precedingDays: RotationDay[] = [];
    let cursor: AbsoluteMonth = currentMonth;
    for (let i = 0; i < MAX_BACKTRACK; ++i) {
        cursor = decrementMonth(cursor);
        const precedingMonth = await api.Roster
            .getAirAtlantaRoster({ ...currentMonth, ...cursor });
        const allMonthDays = getRotationDays(precedingMonth);
        if (!allMonthDays || endsPrematurely(allMonthDays)) {
            return precedingDays;
        }
        const rotations = splitRotations(allMonthDays);
        if (rotations.length > 1) {
            precedingDays.unshift(...splitRotations(allMonthDays).pop() ?? neverNull());
            return precedingDays;
        } else {
            precedingDays.unshift(...allMonthDays);
        }
    }
    throw new Error(
        "Failed to find the start of rotation for " + currentMonth.employeeCode +
        " after looking " + MAX_BACKTRACK + " months back"
    );
}

function isTrainingDay(day: RotationDay) {
    return day.Activities.some(a => getGroundActivityCategory(a) === "TRAINING");
}

export function calculateFeesForFlightDeckPerson(
    person: PersonFeeCalculationParams & {
        precedingUnterminatedRotationDays: RotationDay[],
    },
    iataToIana: IataToIana,
    officerFees: OfficerFlightDeckFees | null
): ImpersonalSalaryJournalRecord[] {
    const journalRecords: ImpersonalSalaryJournalRecord[] = [];
    const nias = getNias(person);

    let dailyFeeRaw: string | null = officerFees?.dailyFee ?? null;
    let dailyFee: `${number}` | undefined | null;
    if (dailyFeeRaw && String(+dailyFeeRaw) === String(dailyFeeRaw)) {
        dailyFee = dailyFeeRaw as `${number}`;
    }
    let rotationPeriod = officerFees?.rotationType ? parseRotationPeriod(officerFees.rotationType) : null;
    if (!person.latestContractData) {
        if (!dailyFee) {
            journalRecords.push(...fixManually(person, "Contract Missing"));
            dailyFee = "0";
        }
    } else if (person.latestContractData.document_kind === "TRAINING_AGREEMENT") {
        journalRecords.push(...fixManually(person, "Training Agreement"));
        if (!dailyFee) {
            dailyFee = "0";
        }
    } else {
        const parsed = parseContract(person.latestContractData);
        dailyFee = dailyFee ?? parsed.dailyFee;
        dailyFeeRaw = parsed.dailyFeeRaw ?? dailyFeeRaw;
        rotationPeriod = parsed.rotationPeriod ?? rotationPeriod;
        if (!dailyFee) {
            journalRecords.push(...fixManually(person, "Contract Fee Format Unsupported " + dailyFeeRaw));
            dailyFee = "0";
        }
    }
    const dailyFeeFinal = dailyFee;

    const dutylessRotationDays = getRotationDays(person.personRoster);
    if (!dutylessRotationDays) {
        const bestError = getBestError(person.personRoster);
        return fixManually(person, "Roster - " + bestError);
    }
    const rotationDays = countDutyDays(nias, dutylessRotationDays, iataToIana);

    const normalDutyDays = [];
    const soldOffDays = [];
    for (const rotationDay of rotationDays) {
        if (rotationDay.DutyKind === "OFF_DUTY") {
            continue;
        } else if (isSoldDayOff(rotationDay)) {
            soldOffDays.push(rotationDay);
        } else {
            normalDutyDays.push(rotationDay);
        }
    }

    journalRecords.push(...groupSequences(soldOffDays)
        .map(sequence => typed<ImpersonalSalaryJournalRecord>({
            service_start_time: sequence[0].Date,
            service_end_time: sequence.slice(-1)[0].Date,
            units: `${sequence.length}`,
            payment_per_unit: (+dailyFeeFinal * 1.35).toFixed(2),
            fee_entry_type: FeeEntryType(108),
            description: "Extra days on duty FD",
        })));

    journalRecords.push(
        ...groupSequences(normalDutyDays).map(sequence => typed<ImpersonalSalaryJournalRecord>({
            fee_entry_type: FeeEntryType(200),
            description: "Daily Fee" + (sequence.some(isTrainingDay) ? " (Training)" : ""),
            payment_per_unit: dailyFeeFinal,
            service_start_time: sequence[0].Date,
            service_end_time: sequence.slice(-1)[0].Date,
            units: `${sequence.length}`,
        })),
    );

    const isCruiseRelief = person.navEntry.JobTitle === "CRUISE RELIEF FIRST OFFICER";
    const perDiem = isCruiseRelief ? "50" : "85";
    const perDiemDays = rotationDays.filter(ri => {
        if (ri.Activities.some(a => a.GNDACTCODETITLE === "PXP")) {
            return false;
        }
        return ri.DutyKind === "TRAVEL"
            || ri.DutyKind === "DAY_SHIFT_TRAVEL_END"
            || ri.DutyKind === "DAY_SHIFT_TRAVEL_START"
            || ri.DutyKind === "NIA_GROUND_DUTY";
    });

    journalRecords.push(...groupSequences(perDiemDays)
        .map(sequence => typed<ImpersonalSalaryJournalRecord>({
            fee_entry_type: FeeEntryType(500),
            description: "Per diem",
            payment_per_unit: perDiem,
            service_start_time: sequence[0].Date,
            service_end_time: sequence.slice(-1)[0].Date,
            units: `${sequence.length}`,
        })));
    if (!isCruiseRelief) {
        const overtimes = getTerminatedRotationBlockHours(rotationDays, person.precedingUnterminatedRotationDays, WW_ROTATION_PERIOD);
        for (const overtime of overtimes) {
            const overtimeHours = overtime.blockHours - OVERTIME_BLOCK_HOURS_THRESHOLD;
            if (overtimeHours > 0) {
                const factor = person.year < 2024 ? 0.6 : 0.39;
                journalRecords.push({
                    service_start_time: getDatePart(overtime.startDay.Date),
                    service_end_time: getDatePart(overtime.endDay.Date),
                    units: overtimeHours.toFixed(2),
                    payment_per_unit: (factor * +dailyFee).toFixed(2),
                    fee_entry_type: FeeEntryType(300),
                    description: "Over time FD",
                });
            }
        }
    }
    if (officerFees) {
        if (officerFees.instructorFee && +officerFees.instructorFee > 0) {
            journalRecords.push({
                service_start_time: getMonthStartDate(person),
                service_end_time: getMonthEndDate(person),
                units: "1",
                payment_per_unit: officerFees.instructorFee,
                fee_entry_type: FeeEntryType(670),
                description: "Instructor fee 010811",
            });
        }
        if (officerFees.safetyOfficerFee && +officerFees.safetyOfficerFee > 0) {
            journalRecords.push({
                service_start_time: getMonthStartDate(person),
                service_end_time: getMonthEndDate(person),
                units: "1",
                payment_per_unit: officerFees.safetyOfficerFee,
                fee_entry_type: FeeEntryType(209),
                description: "Safety Officer Fee",
            });
        }
        if (officerFees.telecommunicationsAllowance && +officerFees.telecommunicationsAllowance > 0) {
            journalRecords.push({
                service_start_time: getMonthStartDate(person),
                service_end_time: getMonthEndDate(person),
                units: "1",
                payment_per_unit: officerFees.telecommunicationsAllowance,
                fee_entry_type: FeeEntryType(4005),
                description: "Phone and Internet allowance",
            });
        }
    }
    return journalRecords;
}
