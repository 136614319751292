import type {
    ImpersonalSalaryJournalRecord, NavContractorBase,
    PersonMonthRosterContent, RdbContractor
} from "../../ExternalRostersApi";
import  { FeeEntryType
} from "../../ExternalRostersApi";

import type { AbsoluteMonth } from "../../../utils/dates";
import { getMonthDate, getMonthEndDate, getMonthStartDate } from "../../../utils/dates";
import type  { SignedDocumentRowBase } from "../../../types/Api/Contract";
import aaiActivityTypes from "./aaiActivityTypes";
import type { DutiedRotationDay, DutyKind, RotationDay } from "./RotationDaysGrouper";
import   { estimateStartDateTime } from "./RotationDaysGrouper";
import { asUtc, makeBlankActivity } from "./RotationDaysGrouper";
import type   { BlankActivity, RosterItem } from "./Roster";
import { utcToZonedTime } from "date-fns-tz";
import type { CompanyName } from "../../Api";
import api from "../../Api";
import { HttpResponseError } from "../../httpUtils";
import { neverNull,toCrewCodeUc } from "../../../utils/typing";
import type  { IataToIana } from "../../../types/utility";
import type { CrewCode } from "@mhc/utils/types/nav";
import type { RosterActivity } from "@mhc/utils/types/integrations/raido/api";
import aaiRaidoActivityTypes from "./aaiRaidoActivityTypes";

/** there are also some 21 and 24 contracts, we should take this value from Inna's table eventually */
export const WW_ROTATION_PERIOD = 30;

/**
 * there are cases when crew is stationed in an airport that matches his NIA, but he
 * stays in the hotel as it's not an unpaid off day, just a stop between flights
 */
function isDutyStop(activity: LastRealActivity, days: RotationDay[]) {
    const activities = days.flatMap(d => d.Activities);
    const baseIndex = activities.findIndex(full => full.FlightId === activity.FlightId);
    if (baseIndex < 0) {
        return false;
    }
    for (const activity of activities.slice(baseIndex + 1)) {
        if (activity.ActivityType === "Flight") {
            return true;
        } else if (activity.ActivityType === "Deadhead") {
            return false;
        }
    }
    for (const activity of activities.slice(0, baseIndex).reverse()) {
        if (activity.ActivityType === "Flight") {
            return true;
        } else if (activity.ActivityType === "Deadhead") {
            return false;
        }
    }
    return false;
}

export function consideredOffPayNiaStay(nias: Set<string>, activity: LastRealActivity, days: RotationDay[]) {
    if (activity.GNDACTCODETITLE === "LLV" || // LONG TERM LEAVE ||
        activity.GroundActivityInfo?.toUpperCase().includes("ROTATION") ||
        activity.GroundActivityInfo?.toUpperCase().includes("UNPAID") ||
        activity.GroundActivityInfo?.toUpperCase().includes("OFF PERDIEM") ||
        activity.GroundActivityInfo?.toUpperCase().includes("OFF PER DIEM") ||
        activity.GNDACTCODETITLE === "SVX" ||
        activity.GNDACTCODETITLE === "CBO" ||
        activity.GNDACTCODETITLE === "EML" ||
        activity.GNDACTCODETITLE === "WFL" ||
        activity.GNDACTCODETITLE?.match(/^R\d+$/)
    ) {
        return true;
    }
    if (!activity.AirportFrom || !activity.AirportTo) {
        return false;
    }
    const isNia = nias.has(activity.AirportFrom) && nias.has(activity.AirportTo);
    return isNia && !isDutyStop(activity, days)
        || activity.DayNumberInRotaion < 0;
}

type GroundActivityCategory = "ON_DUTY" | "OFF_DUTY_UNPAID" | "OFF_DUTY_PAID" | "TRAINING" | "POSITIONING" | "FACT" | "ILLNESS";

function guessCategoryFromGroundActivityName(text: string): GroundActivityCategory | null {
    text = text.toUpperCase();
    if (text.includes("DUTY") && !text.includes("EXCL. DUTY") || text.includes("SHIFT")) {
        return "ON_DUTY";
    } else if (text.match(/\bILL/) || text.match(/\bSICK/)) {
        return "ILLNESS";
    } else if (text.includes("STANDBY")
            || text.includes("STBY")
            || text.includes("LEAVE")
            || text.includes("ROTATION")
            || text.includes("RESERVE")
            || text.includes("VACATION")
            || text.match(/\bVAC\b/)
            || text.match(/\bOFF\b/)
    ) {
        return "OFF_DUTY_UNPAID";
    } else if (text.includes("TRN")
            || text.includes("SIMULATOR")
            || text.match(/\bSIM\b/)
            || text.includes("TRAINING")
            || text.includes("INSTRUCT")
            || text.includes("COURSE")
    ) {
        return "TRAINING";
    } else if (text.includes("HTL")
            || text.includes("HOTEL")
            || text.includes("APARTMENT")
    ) {
        return "OFF_DUTY_PAID";
    } else if (text.includes("POS")
            || text.includes("DEADHEAD")
            || text.includes("TRANSPORT")
    ) {
        return "POSITIONING";
    } else {
        return null;
    }
}

function getRaidoGroundActivityCategory(raidoActivity: RosterActivity): null | "ON_DUTY" | "OFF_DUTY_UNPAID" | "OFF_DUTY_PAID" | "TRAINING" | "POSITIONING" | "FACT" | "ILLNESS" {
    if (raidoActivity.ActivityCode === "PXP") {
        // in reference mapping it's categorized as off day, but it's applicable
        // for daily fee, the only thing off in this activity is Per Diem
        return "ON_DUTY";
    } else if (raidoActivity.ActivitySubType === "Transport") {
        return "POSITIONING";
        // "DayOff"
    } else if (raidoActivity.ActivitySubType === "Hotel") {
        return "OFF_DUTY_PAID";
    } else if (raidoActivity.ActivitySubType === "Illness") {
        return "ILLNESS";
    } else if (raidoActivity.ActivitySubType === "Vacation"
            || raidoActivity.ActivitySubType === "DayOff"
            || raidoActivity.ActivityCode.match(/^R\d+$/)
    ) {
        return "OFF_DUTY_UNPAID";
    } else if (raidoActivity.ActivitySubType === "Shift"
            || raidoActivity.ActivitySubType === "StandBy"
            || raidoActivity.ActivityCode === "HRD"
    ) {
        return "ON_DUTY";
    } else if (raidoActivity.ActivitySubType === "Training"
            || raidoActivity.ActivitySubType === "Simulator"
    ) {
        return "TRAINING";
    }
    const referenceActivity = aaiRaidoActivityTypes.get(raidoActivity.RefUniqueId);
    if (!referenceActivity) {
        return null;
    } else {
        return guessCategoryFromGroundActivityName(referenceActivity.Name);
    }
}

export function getGroundActivityCategory(activity: LastRealActivity | BlankActivity): null | GroundActivityCategory {
    if (activity.FullRaidoActivity) {
        return getRaidoGroundActivityCategory(activity.FullRaidoActivity);
    }
    const code = activity.GroundActivityCode;
    if (!code || !+code) {
        return null;
    }
    const typeRecord = aaiActivityTypes.find(tr => tr.Code === +code);
    if (typeRecord) {
        return "Category" in typeRecord ? typeRecord.Category : null;
    }
    if (!activity.GroundActivityInfo) {
        return null;
    }
    return guessCategoryFromGroundActivityName(activity.GroundActivityInfo);
}

export function isSccmJob(jobTitle: string) {
    return jobTitle === "SENIOR CABIN CREW GRADE 2"
        || jobTitle === "SENIOR CABIN CREW MEMBER";
}

export function isCabinCrewDepartment(department: string) {
    return [625, 626, 627].includes(+department);
}

export function isFlightDeckDepartment(department: string) {
    return [620, 622, 623, 630].includes(+department);
}

export function isMaintenanceDepartment(department: string) {
    return [530, 540, 550, 560, 585].includes(+department);
}

export function isAirAtlanta(company: string) {
    company = company.toUpperCase();
    return company === "AIRBORNE - MALTA"
        || company === "HEL - AAIUSD"
        || company === "D2W - AAIUSD";
}

/**
 * @param days = [1.2, 1.3, 1.4, 1.7, 1.9, 1.10, 1.15, 1.16, 1.17, 2.2, 2.3, 2.4]
 * @return number[][] = [[1.2, 1.3, 1.4], [1.7], [1.9, 1.10], [1.15, 1.16, 1.17], [2.2, 2.3, 2.4]]
 */
export function groupSequences(days: RotationDay[]): RotationDay[][] {
    if (days.length === 0) {
        return [];
    }
    days = [...days];
    const sequences: RotationDay[][] = [[days.shift() ?? neverNull()]];
    for (const day of days) {
        const lastSequence = sequences[sequences.length - 1];
        const lastDay = lastSequence[lastSequence.length - 1];
        if (lastDay.MonthDayIndex + 1 === day.MonthDayIndex) {
            lastSequence.push(day);
        } else {
            sequences.push([day]);
        }
    }
    return sequences;
}

export function assertPositioning(activity: RosterItem): RosterItem & { ActivityType: "Deadhead" | "Flight" } | null {
    if (activity.ActivityType === "Deadhead") {
        return { ...activity, ActivityType: "Deadhead" };
    } else if (activity.ActivityType === "Flight") {
        const dutyDesignatorsAccessible = !!activity.FullRotationActivity;
        if (!dutyDesignatorsAccessible) {
            // if we don't have full roster with duty designators
            // data, it is safer to assume all flights to be positioning
            return { ...activity, ActivityType: "Flight" };
        } else {
            const isPositioning = activity.FullRotationActivity?.DutyDesignators?.match(/\[[DP]]/);
            return isPositioning ? { ...activity, ActivityType: "Flight" } : null;
        }
    } else {
        return null;
    }
}

export function getDutyFlights(activities: RosterItem[]) {
    return activities
        .filter(a => !assertPositioning(a))
        .flatMap(a => a.ActivityType === "Flight" ? [a] : []);
}

export function getFlightBlockMinutes(activity: RosterItem) {
    return +(activity.BlockHours ?? 0) * 60 + +(activity.BlockMinutes ?? 0);
}

export function getDeadheadBlockMinutes(activity: RosterItem & { ActivityType: "Deadhead" }) {
    return +(activity.DeadheadHours ?? 0) * 60 + +(activity.DeadHeadMinutes ?? 0);
}

export function getPositioningBlockMinutes(activity: RosterItem) {
    if (activity.ActivityType === "Deadhead") {
        return getDeadheadBlockMinutes({ ...activity, ActivityType: "Deadhead" });
    } else {
        return getFlightBlockMinutes(activity);
    }
}

export function countDutyBlockMinutes(rotationDays: RotationDay[]) {
    const allActivities = rotationDays.flatMap(rd => rd.Activities);
    return getDutyFlights(allActivities)
        .map(getFlightBlockMinutes)
        .reduce((a, b) => a + b, 0);
}

export function getOvertimeUnits(rotation: RotationDay[], thresholdMinutes: number) {
    const totalMinutes = countDutyBlockMinutes(rotation);
    return Math.max(0, totalMinutes - thresholdMinutes) / 60;
}

function endsPastMidnight(rotationDay: RotationDay, iataToIana: IataToIana): boolean {
    const lastActivity = rotationDay.Activities.slice(-1)[0];
    const endDtUtc = asUtc(lastActivity.ActivityDate);
    const iata = lastActivity.AirportTo ?? null;
    const tz = iata && iataToIana[iata];
    let endDay;
    if (tz) {
        // for Cabin Crew and Flight Deck members duty end date is considered by the local arrival time
        const local = utcToZonedTime(endDtUtc, tz);
        endDay = local.getDate();
    } else {
        // probably should throw an error or smth
        endDay = endDtUtc.getUTCDate();
    }
    return endDay > rotationDay.MonthDayIndex + 1;
}

function startsBeforeMidnight(rotationDay: RotationDay, iataToIana: IataToIana): boolean {
    if (rotationDay.Activities.length === 0) {
        return false;
    }
    const firstActivity = rotationDay.Activities[0];
    const startDtUtc = estimateStartDateTime(firstActivity, rotationDay);
    if (!startDtUtc) {
        return false;
    }
    const iata = firstActivity.AirportFrom ?? null;
    const tz = iata && iataToIana[iata];
    let startDay;
    if (tz) {
        // for Cabin Crew and Flight Deck members duty end date is considered by the local arrival time
        const local = utcToZonedTime(startDtUtc, tz);
        startDay = local.getDate();
    } else {
        // probably should throw an error or smth
        startDay = startDtUtc.getUTCDate();
    }
    return startDay < rotationDay.MonthDayIndex + 1;
}

/**
 * positioning that starts before midnight according to UTC, but past midnight according to LT
 */
function isPastLtMidnightPositioning(monthDayIndex: number, activity: RosterItem, iataToIana: IataToIana): boolean {
    if (activity.ActivityType !== "Deadhead") {
        return false;
    }
    const endDtUtc = asUtc(activity.ActivityDate);
    const iata = activity.AirportFrom;
    const tz = iata && iataToIana[iata];
    if (tz) {
        // for Cabin Crew members duty start date is considered by the local arrival time
        const startDtUtc = new Date(endDtUtc.getTime());
        startDtUtc.setUTCHours(startDtUtc.getUTCHours() - +activity.DeadheadHours);
        startDtUtc.setUTCMinutes(startDtUtc.getUTCMinutes() - +activity.DeadHeadMinutes);
        const local = utcToZonedTime(startDtUtc, tz);
        return local.getDate() > monthDayIndex + 1;
    } else {
        // probably should throw an error or smth
        return false;
    }
}

export type LastRealActivity = {
    AirportFrom: string,
    AirportTo: string,
    DayNumberInRotaion: number,
    GroundActivityCode: null | string,
    GroundActivityInfo: null | string,
    GNDACTCODETITLE: null| string,
    FlightId: number | 0,
    FullRaidoActivity?: RosterActivity,
};

export function countDutyDays(
    nias: Set<string>,
    inputRotationDays: RotationDay[],
    iataToIana: IataToIana
): DutiedRotationDay[] {
    const rotationDays: DutiedRotationDay[] = inputRotationDays
        .map(ird => ({ ...ird, DutyKind: null }));
    let lastRealActivity: LastRealActivity = rotationDays
        .flatMap(rd => rd.Activities)
        .flatMap(r => r.ActivityType === "BLANK" ? [] : [r])
        .map(a => ({
            AirportFrom: a.AirportFrom,
            AirportTo: a.AirportFrom,
            DayNumberInRotaion: -1,
            GroundActivityCode: null,
            GroundActivityInfo: null,
            GNDACTCODETITLE: null,
            FlightId: a.FlightId,
            FullRaidoActivity: a.FullRaidoActivity,
        }))[0];
    if (!lastRealActivity) {
        for (const rotationDay of rotationDays) {
            rotationDay.DutyKind = "OFF_DUTY";
        }
        return rotationDays;
    }
    const startFakeActivity = lastRealActivity;

    let lastDutyKind: DutyKind = "OFF_DUTY";
    // TODO: account for past month
    let paidSickDays = 0;
    for (let i = 0; i < rotationDays.length; ++i) {
        const rotationDay = rotationDays[i];
        if (i > 0) {
            const lastRotationDay = rotationDays[i - 1];
            const countedPastMidnight =
                endsPastMidnight(lastRotationDay, iataToIana) &&
                lastRotationDay.Activities.slice(-1)[0].DayNumberInRotaion !== -1 &&
                getGroundActivityCategory(lastRealActivity) !== "OFF_DUTY_UNPAID" &&
                getGroundActivityCategory(lastRealActivity) !== "ILLNESS" &&
                getGroundActivityCategory(lastRealActivity) !== "FACT";
            if (countedPastMidnight) {
                if (lastDutyKind === "TRAVEL") {
                    rotationDay.DutyKind = "DAY_SHIFT_TRAVEL_END";
                } else if (lastDutyKind === "NIA_GROUND_DUTY") {
                    rotationDay.DutyKind = "NIA_GROUND_DUTY";
                }
            }
        }
        for (const activity of rotationDay.Activities) {
            const followsNiaStay = lastRealActivity === startFakeActivity
                ? nias.has(startFakeActivity.AirportFrom)
                : consideredOffPayNiaStay(nias, lastRealActivity, rotationDays);
            const isActivityMeaningful = activity.ActivityType !== "BLANK" && (
                activity.ActivityType !== "Ground" ||
                activity.GNDACTCODETITLE !== "LEE" &&
                activity.GNDACTCODETITLE !== "GT" &&
                activity.GNDACTCODETITLE !== "HTL" &&
                activity.GNDACTCODETITLE !== "TRH" &&
                activity.GNDACTCODETITLE !== "TRA"
            );
            lastRealActivity = isActivityMeaningful ? activity : {
                ...lastDutyKind === "OFF_DUTY" ? lastRealActivity : {
                    GroundActivityCode: null,
                    GroundActivityInfo: null,
                    GNDACTCODETITLE: null,
                },
                DayNumberInRotaion: activity.DayNumberInRotaion,
                AirportFrom: lastRealActivity.AirportTo,
                AirportTo: lastRealActivity.AirportTo,
                FlightId: lastRealActivity.FlightId,
                FullRaidoActivity: lastRealActivity.FullRaidoActivity,
            };
            const groundActivityCategory = getGroundActivityCategory(lastRealActivity);
            const ignorePositioning = followsNiaStay &&
                isPastLtMidnightPositioning(rotationDay.MonthDayIndex, activity, iataToIana);
            if (ignorePositioning) {
                lastDutyKind = "TRAVEL";
                if (!rotationDay.DutyKind) {
                    rotationDay.DutyKind = "OFF_DUTY";
                }
            } else if (!consideredOffPayNiaStay(nias, lastRealActivity, rotationDays)
                    && !(groundActivityCategory === "ILLNESS" && lastDutyKind === "OFF_DUTY" && i > 0)
                    || groundActivityCategory === "POSITIONING"
            ) {
                lastDutyKind = "TRAVEL";
                rotationDay.DutyKind = "TRAVEL";
            } else if (groundActivityCategory === "ON_DUTY"
                    || groundActivityCategory === "TRAINING"
            ) {
                lastDutyKind = "NIA_GROUND_DUTY";
                if (rotationDay.DutyKind !== "TRAVEL" &&
                    rotationDay.DutyKind !== "DAY_SHIFT_TRAVEL_END" &&
                    rotationDay.DutyKind !== "DAY_SHIFT_TRAVEL_START"
                ) {
                    rotationDay.DutyKind = "NIA_GROUND_DUTY";
                }
            } else {
                lastDutyKind = "OFF_DUTY";
                if (!rotationDay.DutyKind) {
                    rotationDay.DutyKind = "OFF_DUTY";
                }
            }
        }
        if (rotationDay.Activities.some(a => getGroundActivityCategory(a) === "ILLNESS")) {
            if (rotationDay.DutyKind === "TRAVEL" ||
                rotationDay.DutyKind === "DAY_SHIFT_TRAVEL_END" ||
                rotationDay.DutyKind === "DAY_SHIFT_TRAVEL_START"
            ) {
                ++paidSickDays;
                if (paidSickDays > 7) {
                    rotationDay.DutyKind = "OFF_DUTY";
                }
            } else if (rotationDay.DutyKind === "NIA_GROUND_DUTY") {
                ++paidSickDays;
            }
        }
        if (i < rotationDays.length - 1 && (rotationDay.DutyKind ?? "OFF_DUTY") === "OFF_DUTY") {
            const nextRotationDay = rotationDays[i + 1];
            const nextActivity = nextRotationDay.Activities[0] ?? null;
            if (nextActivity && nextActivity.ActivityType !== "BLANK" &&
                startsBeforeMidnight(nextRotationDay, iataToIana)
            ) {
                const nextGroundActivityCategory = getGroundActivityCategory(nextActivity);
                if (nextGroundActivityCategory === "ON_DUTY" ||
                    nextGroundActivityCategory === "TRAINING"
                ) {
                    rotationDay.DutyKind = "NIA_GROUND_DUTY";
                } else if (!consideredOffPayNiaStay(nias, nextActivity, rotationDays)) {
                    rotationDay.DutyKind = "DAY_SHIFT_TRAVEL_START";
                }
            }
        }
    }
    return rotationDays;
}

type FetchedCalculationData = {
    rotationDays: RotationDay[],
    latestContractData: SignedDocumentRowBase | null,
};

export type PersonMonthRosterLocator = AbsoluteMonth & {
    navEntry: NavContractorBase,
    rdbEntry?: RdbContractor,
};

export type PersonFeeCalculationParams = FetchedCalculationData & PersonMonthRosterLocator;

export function fixManually(rosterLocator: AbsoluteMonth, message: string): ImpersonalSalaryJournalRecord[] {
    const fixManuallyBase = {
        fee_entry_type: FeeEntryType(9000),
        units: "1",
        payment_per_unit: "0",
        service_start_time: getMonthStartDate(rosterLocator),
        service_end_time: getMonthEndDate(rosterLocator),
    } as const;

    return [{
        ...fixManuallyBase,
        description: "Fix Manually - " + message,
    }];
}

export function parseRotationPeriod(title: string) {
    if (title.includes("21/14")) {
        return 21;
    } else if (title.includes("24/12")) {
        return 24;
    } else if (title.includes("WW")) {
        return WW_ROTATION_PERIOD;
    } else {
        return null;
    }
}

export function parseContract(record: SignedDocumentRowBase) {
    let title = null;
    let rotationPeriod = null;
    let nia = null;
    let dailyFee: `${number}` | null = null;
    let dailyFeeRaw: string | null = null;
    for (const entry of record.remaining_details) {
        if (!("key" in entry)) {
            if (!("number" in entry) && !title && !entry.raw.includes("SERVICE COMPANY")) {
                const match = entry.raw.match(/^.*SCHEDULE \S+\s+(\S.*)$/);
                if (match) {
                    title = match[1].replace(/DETAILS\s+OF\s+THE\s+INDIVIDUAL\s*/, "");
                    rotationPeriod = parseRotationPeriod(title) ?? rotationPeriod;
                }
            }
            continue;
        }
        if (entry.key.toUpperCase().replace(/\s+/g, "").includes("DAILYFEE")) {
            dailyFeeRaw = entry.value;
            const match = dailyFeeRaw.replace(/\s+/g, "")
                .match(/^\$?(?:USD?\s*|)\$?(\d+(\.\d{2})?)$/);
            if (match) {
                dailyFee = match[1] as `${number}`;
            }
        } else if (entry.key.toUpperCase().replace(/\s+/g, "").includes("NEARESTINTERNATIONALAIRPORT")
                || entry.key.toUpperCase().replace(/\s+/g, "").includes("NIA")
        ) {
            const value = entry.value.trim().toUpperCase();
            if (value.length === 3) {
                nia = value;
            }
        }
    }
    return { title, rotationPeriod, nia, dailyFee, dailyFeeRaw };
}

export async function fetchPersonRoster({ year, month }: AbsoluteMonth, person: {
    navEntry: { No_: CrewCode, CompanyName: CompanyName },
}, forceLatestRoster = false) {
    const whenPersonRoster = api.Roster.getAirAtlantaRoster({
        employeeCode: toCrewCodeUc(person.navEntry.No_),
        company: person.navEntry.CompanyName,
        year: year,
        month: month,
        forceLatest: forceLatestRoster ? "true" : undefined,
    });
    const personRoster = await whenPersonRoster;
    if (!personRoster.roster_incomplete &&
        personRoster.roster_complete?.ResultMessage === "Sequence contains no elements" ||
        personRoster.roster_complete?.ResultMessage === "Employee for this Lettercode was not found"
    ) {
        const dateStr = getMonthDate({ year, month }, 1);
        personRoster.roster_incomplete = [makeBlankActivity(-1, dateStr)];
    }
    return personRoster;
}

export async function fetchCalculationDataForPerson({ year, month }: AbsoluteMonth, person: {
    navEntry: { No_: CrewCode, CompanyName: CompanyName },
    rdbEntry?: RdbContractor,
}, forceLatestRoster = false) {
    const whenPersonRoster = fetchPersonRoster({ year, month }, person, forceLatestRoster);
    const whenLatestContractData = !person.rdbEntry ? null : api.Contract.GetLatestContractData({
        rdbPersonId: person.rdbEntry.ApplicantID,
    }).catch(error => {
        if (error instanceof HttpResponseError && error.response.status === 404) {
            return null;
        } else {
            throw error;
        }
    });
    const personRoster = await whenPersonRoster;
    return {
        personRoster: personRoster,
        latestContractData: await whenLatestContractData,
    };
}

export function getBestError(personRoster: PersonMonthRosterContent) {
    return personRoster.roster_complete?.ResultMessage
        ?? personRoster.roster_complete_error;
}

export function isSoldDayOff(day: RotationDay) {
    return day.Activities.some(activity => {
        if (activity.ActivityType !== "Ground") {
            return false;
        }
        return activity.GroundActivityInfo === "EXTRA FLIGHT ON OFF DAY"
            || activity.GroundActivityInfo === "WORKED OFF DAY"
            || activity.GNDACTCODETITLE === "WOFF"
            || activity.GNDACTCODETITLE === "HRD"
            || activity.GNDACTCODETITLE === "ECD" // not sure
            || activity.GNDACTCODETITLE === "BOD" // not sure
            ;
    });
}

export function getNias(person: {
    navEntry: NavContractorBase | null,
    rdbEntry?: { "Nearest_Airport": string | null },
    latestContractData: SignedDocumentRowBase | null,
}) {
    const nias = new Set<string>();
    if (person.latestContractData) {
        const parsed = parseContract(person.latestContractData);
        if (parsed.nia) {
            nias.add(parsed.nia);
        }
    }
    if (person.navEntry?.NearestIntAirport) {
        nias.add(person.navEntry.NearestIntAirport);
    }
    const rdbNias = person.rdbEntry?.Nearest_Airport?.split(/[^a-zA-Z]+/)
        .map(a => a.toUpperCase())
        .filter(a => a.length >= 3 && a.length <= 4) ?? [];
    if (rdbNias.length > 1) {
        for (const rdbNia of rdbNias) {
            nias.add(rdbNia);
        }
    }
    return nias;
}
