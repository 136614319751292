import type OpenExchangeRates from "../types/OpenExchangeRates";

export function getCurrencyCode(currencySymbol: string) {
    return {
        "£": "GBP",
        "€": "EUR",
        "$": "USD",
    // LCY Code field in Navision may have either currency
    // code or currency symbol for historical reasons
    }[currencySymbol] ?? currencySymbol;
}

export function getCurrencySymbol(currencyCode: string) {
    return {
        "GBP": "£",
        "EUR": "€",
        "USD": "$",
    }[currencyCode] ?? currencyCode;
}

export function getEquivalent(
    sourceCurrency: string,
    sourceAmount: number | string,
    targetCurrency: string,
    rates: OpenExchangeRates
) {
    const targetRate = targetCurrency === rates.base
        ? 1 : rates.rates[targetCurrency];
    const baseRate = sourceCurrency === rates.base
        ? 1 : rates.rates[sourceCurrency];

    if (!baseRate || !targetRate) {
        return undefined;
    }
    const baseEquivalent = +sourceAmount / baseRate;
    return baseEquivalent * targetRate;
}