import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getJson, postForSuccessStatus } from "./httpUtils";

// Create a const companySlice with async thunks: loadCompanies
// loadCompaniesWise
// updateCompany
// addCompanyFieldSetup
// addCompanyActivitySetup
// loadCompanyFieldSetup
// loadCompanyActivitySetup
// loadCompanyActivitySetupAndJobtitle
// loadActivitiesWise
// loadJobTitlesWise

// async thunks:

export const loadCompaniesWise = createAsyncThunk(
  "companies/loadCompaniesWise", async (arg, thunkAPI) => {
    /** @type {CompanyWise[]} */
    const companies = await getJson("/api/company/getallcompaniesWise");
    return companies;
  }
);

export const addCompanyFieldSetup = createAsyncThunk(
  "companies/addCompanyFieldSetup", async (payload, thunkAPI) => {
    const { companyName, jobTitle, fields, type } = payload;
    const url = `/api/company/addcompanyfieldsetup?company=${companyName}&jobtitle=${jobTitle}&fields=${fields}&type=${type}`;
    await postForSuccessStatus(url, {});
  }
);

export const addCompanyActivitySetup = createAsyncThunk(
  "companies/addCompanyActivitySetup", async (arg, thunkAPI) => {
    const { companyName, jobTitle, activity } = arg;
    const url = `/api/company/addcompanyactivitysetup?` + "company=" + companyName + "&jobtitle=" + jobTitle + "&activities=" + activity;
    await postForSuccessStatus(url, {});
  }
);

export const loadCompanyFieldSetup = createAsyncThunk(
  "companies/loadCompanyFieldSetup", async (payload, thunkAPI) => {
    const { companyName, type } = payload;
    /** @type {FieldSetup[]} */
    const company = await getJson("/api/company/GetCompanyFieldSetupByCompany?company=" + companyName + "&type=" + type);
    return company;
  }
);

export const loadCompanySoeFieldSetup = createAsyncThunk(
  "companies/loadCompanySoeFieldSetup", async (payload, thunkAPI) => {
    const { companyName } = payload;
    /** @type {FieldSetup[]} */
    const company = await getJson("/api/company/GetCompanyFieldSetupByCompany?company=" + companyName + "&type=" + 2);
    return company;
  }
);

export const loadCompanyActivitySetup = createAsyncThunk(
  "companies/loadCompanyActivitySetup", async (companyName, thunkAPI) => {
    const company = await getJson(`/api/company/GetCompanyActivitySetupByCompany?company=${companyName}`);
    return company;
  }
);

export const loadCompanyActivitySetupAndJobtitle = createAsyncThunk(
  "companies/loadCompanyActivitySetupAndJobtitle", async (payload, thunkAPI) => {
    const { companyName, jobTitle } = payload;
    const company = await getJson(`/api/company/GetCompanyActivitySetupByCompanyAndJobtitle?company=${companyName}&jobtitle=${jobTitle}`);
    return company;
  }
);

export const loadCompanyFieldsSetupJobtitle = createAsyncThunk(
  "companies/loadCompanyFieldsSetupJobtitle", async (payload, thunkAPI) => {
    const { companyName, jobTitle, type } = payload;
    const url = `/api/company/GetCompanyFieldSetupByCompanyAndJobtitleNew?company=${companyName}&jobtitle=${jobTitle}&type=${type}`;
    /** @type {string[]} */
    const company = await getJson(url);
    return company;
  }
);

export const deleteCompanyFieldsSetupJobtitle = createAsyncThunk(
  "companies/deleteCompanyFieldsSetupJobtitle", async (payload, thunkAPI) => {
    const { companyName, jobTitle } = payload;
    const company = await getJson(`/api/company/GetDeleteCompanyFieldSetupByCompanyAndJobtitle?company=${companyName}&jobtitle=${jobTitle}`);
    return company;
  }
);

export const loadJobTitlesWise = createAsyncThunk(
  "companies/loadJobTitlesWise", async (arg, thunkAPI) => {
    const company = await getJson("/api/jobtitle/GetJobTitlesWise");
    return company;
  }
);

export const deleteCompanyActivitySetupJobtitle = createAsyncThunk(
  "companies/deleteCompanyActivitySetupJobtitle", async (payload, thunkAPI) => {
    const { companyName, jobTitle } = payload;
    const company = await getJson(`/api/company/GetDeleteCompanyActivitySetupByCompanyAndJobtitle?company=${companyName}&jobtitle=${jobTitle}`);
    return company;
  }
);

export const companySlice = createSlice({
  name: "companies",
  /** @type {CompanyReducerState} */
  initialState: {
    companies: [],
    companiesWise: [],
    company: {},
    companyFieldSetup: {},
    companyActivitySetup: {},
    activitiesWise: [],
    loading: false,
    projectsWise: [],
    basesWise: [],
    activitySetup: [],
    jobTitlesWise: [],
    selectedActivities: [],
    soeFields: [],
  },
  reducers: {},
  // TODO other states
  extraReducers: builder => {
    builder.addCase(
      loadCompaniesWise.fulfilled, (state, action) => {
        state.companiesWise = action.payload;
      }
    ).addCase(
      addCompanyFieldSetup.fulfilled, (state, action) => {
      }
    ).addCase(
      addCompanyActivitySetup.fulfilled, (state, action) => {
      }
    ).addCase(
      loadCompanyFieldSetup.fulfilled, (state, action) => {
        state.companyFieldSetup = action.payload;
      }
    ).addCase(
      loadCompanyActivitySetup.fulfilled, (state, action) => {
        state.companyActivitySetup = action.payload;
      }
    ).addCase(
      loadCompanyActivitySetupAndJobtitle.fulfilled, (state, action) => {
        state.companyActivitySetupAndJobtitle = action.payload;
        state.loading = false;
      }
    ).addCase(
      loadCompanyActivitySetupAndJobtitle.pending, (state, action) => {
        state.loading = true;
      }
    ).addCase(
      loadCompanyActivitySetupAndJobtitle.rejected, (state, action) => {
        state.error = action.payload;
      }
    ).addCase(
      loadJobTitlesWise.fulfilled, (state, action) => {
        state.jobTitlesWise = action.payload;
      }
    ).addCase(
      loadCompanySoeFieldSetup.fulfilled, (state, action) => {
        state.soeFields = action.payload;
      }
    ).addCase(
      loadCompanyFieldsSetupJobtitle.fulfilled, (state, action) => {
        state.soeFields = action.payload;
      }
    );
  }
});
