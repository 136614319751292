import * as React from "react";
import { Calendar } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import type { SettingsProps } from "react-multi-date-picker/plugins/settings";
import Settings from "react-multi-date-picker/plugins/settings";
import type DateObject from "react-date-object";
import type { ReactElement } from "react";
import { typed } from "../utils/typing";
import * as _ from "lodash";
import type { ManualInvoiceDetails } from "../types/Api/Invoice";
import type { Dispatch } from "redux";
import { getMonthFullName } from "../utils/dates";
import { getAllNavActivities, getAllNavBases, getNavCompanyProjects } from "../features/StaticData";
import type WiseCodeValue from "../types/WiseCodeValue";
import type { CompanyName } from "../features/Api";
import api from "../features/Api";

declare module "react-multi-date-picker" {
    interface CalendarProps {
        autocomplete?: "off",
        dateFormat?: "DD.MM.YYYY",
    }
}

type Props = {
    invoiceId: "" | string | number,
    selectedDetail: ManualInvoiceDetails | null,
    displayedFieldsList: string[],
    activityOptionsList: string[],
    year: number,
    month: number,
    DeadlineDay: number,
    goBack: () => void,
    onSubmitted: () => void,
    dispatch: Dispatch,
    COMPANYNAME: CompanyName,
};

function makeEmptyManualInvoice(invoiceId: "" | string | number) {
    return {
        Id: typed<null | number | undefined>(null),
        InvoiceId: invoiceId,
        ActivityDate: typed<string | Date>(new Date()),
        ActivityDates: typed<null | undefined | DateObject | DateObject[]>(null),
        Project: "",
        Activity: "",
        AddonFees: typed<string[]>([]),
        AddonFee: "",
        TimeIn1: "",
        TimeOut1: "",
        TimeOut1NextDay: false,
        TimeIn2: "",
        TimeOut2: "",
        TimeOut2NextDay: false,
        DailyHours: "",
        Over12Hours: "",
        AdditionalDay: false,
        Flying: false,
        PositioningHours: typed<boolean>(false),
        BlockHours: "",
        FlightNumber: "",
        AircraftReg: "",
        Base: "",
        PaymentOnOff: false,
        PerDiem: false,
        SoldOffDay: false,
        OverTime: "",
        Rejected: false,
        isEdit: typed<boolean | undefined>(false),
        Status: typed<undefined | number>(undefined),
        Multiple: typed<undefined | unknown>(undefined),
        Range: typed<undefined | unknown>(undefined),
    };
}

type PickerValues = Partial<{
    multiple: boolean,
    range: boolean,
}>;

function getInitialState(props: Props) {
    const ManualInvoice = makeEmptyManualInvoice(props.invoiceId);
    let ActivityDate: null | Date;
    if (props.selectedDetail) {
        ManualInvoice.Id = props.selectedDetail.Id;
        ManualInvoice.ActivityDate = props.selectedDetail.ActivityDate;
        ManualInvoice.Base = props.selectedDetail.Base;
        ManualInvoice.TimeIn1 = props.selectedDetail.TimeIn1;
        ManualInvoice.TimeOut1 = props.selectedDetail.TimeOut1;
        ManualInvoice.TimeOut1NextDay = props.selectedDetail.TimeOut1NextDay;
        ManualInvoice.TimeIn2 = props.selectedDetail.TimeIn2;
        ManualInvoice.TimeOut2 = props.selectedDetail.TimeOut2;
        ManualInvoice.TimeOut2NextDay = props.selectedDetail.TimeOut2NextDay;
        ManualInvoice.DailyHours = props.selectedDetail.DailyHours;
        ManualInvoice.Over12Hours = props.selectedDetail.Over12Hours;
        ManualInvoice.AdditionalDay = props.selectedDetail.AdditionalDay;
        ManualInvoice.Flying = props.selectedDetail.Flying;
        ManualInvoice.BlockHours = props.selectedDetail.BlockHours;
        ManualInvoice.FlightNumber = props.selectedDetail.FlightNumber;
        ManualInvoice.Status = props.selectedDetail.Status;
        ManualInvoice.Activity = props.selectedDetail.Activity;
        ManualInvoice.AddonFee = props.selectedDetail.AddonFee;
        ManualInvoice.Project = props.selectedDetail.Project;
        ManualInvoice.PaymentOnOff = props.selectedDetail.PaymentOnOff;
        ManualInvoice.SoldOffDay = props.selectedDetail.SoldOffDay;
        ManualInvoice.PerDiem = props.selectedDetail.PerDiem;
        ManualInvoice.OverTime = props.selectedDetail.OverTime;
        ManualInvoice.AircraftReg = props.selectedDetail.AircraftReg;
        ManualInvoice.OverTime = props.selectedDetail.OverTime;
        ManualInvoice.PositioningHours = props.selectedDetail.PositioningHours;
        ManualInvoice.isEdit = true;
        ActivityDate = new Date(props.selectedDetail.ActivityDate);
    } else {
        ActivityDate = null;
    }
    return {
        submitting: false,
        error: typed<null | undefined>(undefined),
        MandatoryFiles: "",
        ActivityDates: typed<null | DateObject | DateObject[]>(null),
        pickerValues: typed<PickerValues>({ multiple: !props.selectedDetail, range: false }),
        ActivityDate: ActivityDate,
        ManualInvoice: ManualInvoice,
        allNavBases: typed<null | WiseCodeValue[]>(null),
        allNavActivities: typed<null | WiseCodeValue[]>(null),
        navCompanyProjects: typed<null | WiseCodeValue[]>(null),
    };
}

type State = ReturnType<typeof getInitialState>;
export type ManualInvoiceSubmissionData = State["ManualInvoice"];

export default class ManualInvoiceDetailsForm extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = getInitialState(props);
        getAllNavBases().then(allNavBases => this.setState({ allNavBases }));
        getAllNavActivities().then(allNavActivities => this.setState({ allNavActivities }));
        getNavCompanyProjects(props.COMPANYNAME).then(navCompanyProjects => this.setState({ navCompanyProjects }));
    }

    getMinDate() {
      let minMonth = this.props.month;
      let year = this.props.year;
      if (this.props.month <= 2) {
        minMonth += 12;
        year -= 1;
      }

      //temporary hack put in to avoid users not being able to create work invoice entries for last year.
      const dateHack = new Date(2023, 3, 31);
      if (new Date() < dateHack) {
        year -= 1;
      }


      minMonth -= 3;
      return {
        MinAllowedYear: year,
        MinAllowedMonth: minMonth,
      };
    };

    showManualInvoiceDetailsTable() {
        this.props.goBack();
    };

    deleteFromAddonFeeList(addonFee: string) {
      const ManualInvoice = _.cloneDeep(this.state.ManualInvoice);
      const list = this.state.ManualInvoice.AddonFees;
      ManualInvoice.AddonFees = list
          .filter((item) => item !== addonFee);
      ManualInvoice.AddonFee = ManualInvoice.AddonFees.join(";");
      this.setState({ ManualInvoice: ManualInvoice });
    };

    generateSelectedAddonFeeList() {
      const items: ReactElement[] = [];
      this.state.ManualInvoice.AddonFees.map((addonFee) => {
        if (addonFee !== "")
          items.push(
            <div className="col-sm-2 addOnFee" style={{ fontSize: "20px" }}>
              {addonFee}{" "}
              <span
                style={{
                  fontSize: "12px",
                  color: "red",
                  marginTop: "-7px",
                  display: "block",
                }}
                onClick={() => this.deleteFromAddonFeeList(addonFee)}
              >
                Delete
              </span>
            </div>
          );
      });
      return <div className="addOnFeeContainer">{items}</div>;
    };

    canAddExtraActivity() {
      return (
        this.state.ManualInvoice.AddonFee.length + 5 <
        60 && this.state.ManualInvoice.Activity !== ""
      );
    };

    changePickerValues(e: object) {
      this.setState({ pickerValues: e });
    }
    formatDate(input: {
      date: DateObject | undefined,
      format: "2023/12/14" | string,
      index: number,
    }) {
      return !input.date ? undefined : [
        input.date.day,
        input.date.month.number,
        input.date.year,
      ].map(n => String(n).padStart(2, "0")).join("/");
    }

    addStr(str: string, index: number, stringToAdd: string) {
      return (
        str.substring(0, index) + stringToAdd + str.substring(index, str.length)
      );
    };

    CheckMandatoryFields() {
      let error = "";

      const ManualInvoice = _.cloneDeep(this.state.ManualInvoice);

      ManualInvoice.DailyHours = "00:15";
      const tempString = "<br/>- Please select ";
      if (
        this.state.ManualInvoice.Project == "" &&
        this.props.displayedFieldsList.indexOf("Project") > -1
      )
        error = tempString + "Project";
      if (!this.state.ManualInvoice.isEdit &&
        (!this.state.ActivityDates || Array.isArray(this.state.ActivityDates) && this.state.ActivityDates.length == 0))
        error = tempString + "at least one Activity Date";
      if (
        this.state.ManualInvoice.Base == "" &&
        this.props.displayedFieldsList.indexOf("Base") > -1
      )
        error += tempString + "Base";
      if (
        this.state.ManualInvoice.Activity == "" &&
        this.props.displayedFieldsList.indexOf("Activity") > -1
      )
        error += tempString + "Activity";
      if (this.props.displayedFieldsList.indexOf("TimeIn") > -1)
        if (this.state.ManualInvoice.TimeIn1 == "")
          error += tempString + "Time In";

      if (this.props.displayedFieldsList.indexOf("TimeIn") > -1) {
        if (this.state.ManualInvoice.TimeIn1.length < 3)
          error += "<br/>- Time In must be at least 3 letters";
        else {
          if (!this.state.ManualInvoice.TimeIn1.includes(":")) {
            ManualInvoice.TimeIn1 = this.addStr(
              this.state.ManualInvoice.TimeIn1,
              this.state.ManualInvoice.TimeIn1.length - 2,
              ":"
            );
          }
        }
      }

      if (this.props.displayedFieldsList.indexOf("TimeOut") > -1)
        if (this.state.ManualInvoice.TimeOut1 == "")
          error += tempString + "Time Out";

      if (this.props.displayedFieldsList.indexOf("TimeOut") > -1)
        if (this.state.ManualInvoice.TimeOut1.length < 3)
          error += "<br/>- Time Out must be at least 3 letters";
        else {
          if (!this.state.ManualInvoice.TimeOut1.includes(":")) {
            ManualInvoice.TimeOut1 = this.addStr(
              this.state.ManualInvoice.TimeOut1,
              this.state.ManualInvoice.TimeOut1.length - 2,
              ":"
            );
          }
        }

      if (this.props.displayedFieldsList.indexOf("TimeIn2") > -1)
        if (this.state.ManualInvoice.TimeIn1 == "")
          error += tempString + "Time In 2";

      if (this.props.displayedFieldsList.indexOf("TimeIn2") > -1)
        if (this.state.ManualInvoice.TimeIn2.length < 3)
          error += "<br/>- Time In 2 must be at least 3 letters";
        else {
          if (!this.state.ManualInvoice.TimeIn2.includes(":")) {
            ManualInvoice.TimeIn2 = this.addStr(
              this.state.ManualInvoice.TimeIn2,
              this.state.ManualInvoice.TimeIn2.length - 2,
              ":"
            );
          }
        }

      if (this.props.displayedFieldsList.indexOf("TimeOut2") > -1)
        if (this.state.ManualInvoice.TimeIn1 == "")
          error += tempString + "Time Out 2";

      if (this.props.displayedFieldsList.indexOf("TimeOut2") > -1)
        if (this.state.ManualInvoice.TimeOut2.length < 3)
          error += "<br/>- Time Out 2 must be at least 3 letters";
        else {
          if (!this.state.ManualInvoice.TimeOut2.includes(":")) {
            ManualInvoice.TimeOut2 = this.addStr(
              this.state.ManualInvoice.TimeOut2,
              this.state.ManualInvoice.TimeOut2.length - 2,
              ":"
            );
          }
        }

      if (error == "") {
        error = "OK";
      }

      this.setState({ ManualInvoice: ManualInvoice, error: null });
      return error;
    };

    async addUpdateManualInvoiceDetails() {
      const error = this.CheckMandatoryFields();
      if (error == "OK") {
        const manualInvoice = _.cloneDeep(this.state.ManualInvoice);
        if (!manualInvoice.isEdit) {
          manualInvoice.ActivityDates = this.state.ActivityDates;
        } else if (this.state.ActivityDates && "toDate" in this.state.ActivityDates) {
          manualInvoice.ActivityDate = this.state.ActivityDates.toDate().toISOString();
        }
        if (!this.state.pickerValues) {
          throw new Error("Unexpected state: Date Picker values are not set");
        }
        manualInvoice.Multiple = this.state.pickerValues.multiple;
        manualInvoice.Range = this.state.pickerValues.range;
        await api.Invoice.AddUpdateManualInvoiceDetails(manualInvoice);
        this.props.onSubmitted();
      } else
        this.setState({
          MandatoryFiles: error,
        });
    };

    addUpdateManualInvoiceDetailsWithLock(event: React.FormEvent) {
      event.preventDefault();
      this.setState({ submitting: true });
      this.addUpdateManualInvoiceDetails()
        .finally(() => this.setState({ submitting: false }));
    }

    _selectProject(e: React.ChangeEvent<HTMLSelectElement>) {
      const ManualInvoice = _.cloneDeep(this.state.ManualInvoice);
      ManualInvoice.Project = e.target.value;
      this.setState({ ManualInvoice: ManualInvoice, error: null });
    };

    _selectBase(e: React.ChangeEvent<HTMLSelectElement>) {
      const ManualInvoice = _.cloneDeep(this.state.ManualInvoice);
      ManualInvoice.Base = e.target.value;
      this.setState({ ManualInvoice: ManualInvoice, error: null });
    };

    _selectActivity(e: React.ChangeEvent<HTMLSelectElement>) {
      const ManualInvoice = _.cloneDeep(this.state.ManualInvoice);
      ManualInvoice.Activity = e.target.value;
      if (ManualInvoice.AddonFees.includes(ManualInvoice.Activity)) {
        const list = this.state.ManualInvoice.AddonFees;
        ManualInvoice.AddonFees = list.filter((item) => item !== e.target.value);
        ManualInvoice.AddonFee = ManualInvoice.AddonFees.join(";");
      }
      this.setState({ ManualInvoice: ManualInvoice, error: null });
    };

    _selectAddonFee(e: React.ChangeEvent<HTMLSelectElement>) {
      if (!this.state.ManualInvoice.AddonFees.includes(e.target.value)) {
        const ManualInvoice = _.cloneDeep(this.state.ManualInvoice);
        ManualInvoice.AddonFees.push(e.target.value);
        ManualInvoice.AddonFee = ManualInvoice.AddonFees.join(";");
        this.setState({ ManualInvoice: ManualInvoice, error: null });
      }
    };

    //Return list of projects
    // TODO: same code in Claim.tsx - should move to component
    _getProjects() {
      if (!this.state.navCompanyProjects) {
          return <option disabled={true} className="status-loading-animated-ellipsis">Loading Projects</option>;
      }
      const projects = [];
      projects.push(
        <option key="PRJ00" value="">
          Select Project
        </option>
      );

      _.sortBy(this.state.navCompanyProjects, "Code").forEach((p) => {
        projects.push(
          <option key={p.Code} value={p.Code}>
            {p.Name}
          </option>
        );
      });

      return projects;
    };

    //Return list of Bases
    _getBases() {
      if (!this.state.allNavBases) {
          return <option disabled={true} className="status-loading-animated-ellipsis">Loading Bases</option>;
      }
      return [
          <option key="" value="">Select Base</option>,
          ..._.sortBy(this.state.allNavBases, "Code").map(
              (p) => <option value={p.Code} key={p.Code}>
                  {p.Code} - {p.Name}
              </option>
          ),
      ];
    };

    //Return list of Activities
    _getActivities() {
      if (!this.state.allNavActivities) {
          return <option disabled={true} className="status-loading-animated-ellipsis">Loading Activities</option>;
      }
      const options = [];

      options.push(
        <option key="PRJ00" value="">
          Select Activity
        </option>
      );
      const applicableActivities = this.state.allNavActivities
        .filter(a => !a.Name.startsWith("Add-on/"));
      _.sortBy(applicableActivities, "Code").forEach((p) => {
        if (p.Code !== "" && this.props.activityOptionsList.includes(p.Code))
          options.push(
            <option key={p.Code} value={p.Code}>
              {p.Name}
            </option>
          );
      });

      return options;
    };

    //Used in old version
    _getHour() {
      const hours = [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
      ];
      const hour = [];
      for (let i = 0; i < hours.length; i++) {
        hour.push(
          <option key={hours[i]} value={hours[i]}>
            {hours[i]}
          </option>
        );
      }
      return hour;
    };

    //Used in old version
    _getMin() {
      const min = [];
      const mins = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09"];
      for (let i = 0; i < mins.length; i++) {
        min.push(
          <option key={mins[i]} value={mins[i]}>
            {mins[i]}
          </option>
        );
      }
      for (let i = 10; i < 60; i++) {
        min.push(
          <option key={i} value={i}>
            {i}
          </option>
        );
      }
      return min;
    };

    _updateAdditionalDay(e: React.ChangeEvent<HTMLInputElement>) {
      const ManualInvoice = _.cloneDeep(this.state.ManualInvoice);
      ManualInvoice.AdditionalDay = e.target.checked;
      this.setState({ ManualInvoice: ManualInvoice, error: null });
    };

    _updateFlying(e: React.ChangeEvent<HTMLInputElement>) {
      const ManualInvoice = _.cloneDeep(this.state.ManualInvoice);
      ManualInvoice.Flying = e.target.checked;
      this.setState({ ManualInvoice: ManualInvoice, error: null });
    };

    _updateAircraftReg(e: React.ChangeEvent<HTMLInputElement>) {
      const ManualInvoice = _.cloneDeep(this.state.ManualInvoice);
      ManualInvoice.AircraftReg = e.target.value;
      this.setState({ ManualInvoice: ManualInvoice, error: null });
    };

    _updateFlighNumber(e: React.ChangeEvent<HTMLInputElement>) {
      const ManualInvoice = _.cloneDeep(this.state.ManualInvoice);
      ManualInvoice.FlightNumber = e.target.value;
      this.setState({ ManualInvoice: ManualInvoice, error: null });
    };

    _updatePaymentONOFF(e: React.ChangeEvent<HTMLInputElement>) {
      const ManualInvoice = _.cloneDeep(this.state.ManualInvoice);
      ManualInvoice.PaymentOnOff = e.target.checked;
      this.setState({ ManualInvoice: ManualInvoice, error: null });
    };

    _updateSoldOffDay(e: React.ChangeEvent<HTMLInputElement>) {
      const ManualInvoice = _.cloneDeep(this.state.ManualInvoice);
      ManualInvoice.SoldOffDay = e.target.checked;
      this.setState({ ManualInvoice: ManualInvoice, error: null });
    };

    _updatePositioningHours(e: React.ChangeEvent<HTMLInputElement>) {
      const ManualInvoice = _.cloneDeep(this.state.ManualInvoice);
      ManualInvoice.PositioningHours = e.target.checked;
      this.setState({ ManualInvoice: ManualInvoice, error: null });
    };

    _updatePerDiem(e: React.ChangeEvent<HTMLInputElement>) {
      const ManualInvoice = _.cloneDeep(this.state.ManualInvoice);
      ManualInvoice.PerDiem = e.target.checked;
      this.setState({ ManualInvoice: ManualInvoice, error: null });
    };

    _updateTimeIn1(e: React.ChangeEvent<HTMLInputElement>) {
      const ManualInvoice = _.cloneDeep(this.state.ManualInvoice);
      ManualInvoice.TimeIn1 = e.target.value.replace(/[^0-9:]/g, "");
      this.setState({ ManualInvoice: ManualInvoice, error: null });
      // this.dateDifference(ManualInvoice.TimeIn1, ManualInvoice.TimeOut1);
    };

    _updateTimeIn2(e: React.ChangeEvent<HTMLInputElement>) {
      const ManualInvoice = _.cloneDeep(this.state.ManualInvoice);
      ManualInvoice.TimeIn2 = e.target.value.replace(/[^0-9:]/g, "");
      this.setState({ ManualInvoice: ManualInvoice, error: null });
    };

    _updateTimeOut2(e: React.ChangeEvent<HTMLInputElement>) {
      const ManualInvoice = _.cloneDeep(this.state.ManualInvoice);
      ManualInvoice.TimeOut2 = e.target.value.replace(/[^0-9:]/g, "");
      this.setState({ ManualInvoice: ManualInvoice, error: null });
    };

    _updateTimeOut1(e: React.ChangeEvent<HTMLInputElement>) {
      const ManualInvoice = _.cloneDeep(this.state.ManualInvoice);
      ManualInvoice.TimeOut1 = e.target.value.replace(/[^0-9:]/g, "");
      this.setState({ ManualInvoice: ManualInvoice, error: null });
      // this.dateDifference(ManualInvoice.TimeIn1, ManualInvoice.TimeOut1)
    };

    _updateBlcok(e: React.ChangeEvent<HTMLInputElement>) {
      const ManualInvoice = _.cloneDeep(this.state.ManualInvoice);
      ManualInvoice.BlockHours = e.target.value.replace(/[^0-9:]/g, "");
      this.setState({ ManualInvoice: ManualInvoice, error: null });
    };

    _updateOverTimeHH(e: React.ChangeEvent<HTMLSelectElement>) {
      const ManualInvoice = _.cloneDeep(this.state.ManualInvoice);
      let HH = ManualInvoice.OverTime;
      if (HH == "" || HH == null) HH = e.target.value + ":00";
      else {
        const t = HH.split(":");
        HH = e.target.value + ":" + t[1];
      }
      ManualInvoice.OverTime = HH;
      this.setState({ ManualInvoice: ManualInvoice, error: null });
    };

    _updateOverTimeMM(e: React.ChangeEvent<HTMLSelectElement>) {
      const ManualInvoice = _.cloneDeep(this.state.ManualInvoice);
      let HH = ManualInvoice.OverTime;
      if (HH == "") HH = "00:" + e.target.value;
      else {
        const t = HH.split(":");
        HH = t[0] + ":" + e.target.value;
      }
      ManualInvoice.OverTime = HH;
      this.setState({ ManualInvoice: ManualInvoice, error: null });
    };

    _updateOver12HH(e: React.ChangeEvent<HTMLSelectElement>) {
      const ManualInvoice = _.cloneDeep(this.state.ManualInvoice);
      let HH = ManualInvoice.Over12Hours;
      if (HH == "") HH = e.target.value + ":00";
      else {
        const t = HH.split(":");
        HH = e.target.value + ":" + t[1];
      }
      ManualInvoice.Over12Hours = HH;
      this.setState({ ManualInvoice: ManualInvoice, error: null });
    };

    _updateOver12MM(e: React.ChangeEvent<HTMLSelectElement>) {
      const ManualInvoice = _.cloneDeep(this.state.ManualInvoice);
      let HH = ManualInvoice.Over12Hours;
      if (HH == "") HH = "00:" + e.target.value;
      else {
        const t = HH.split(":");
        HH = t[0] + ":" + e.target.value;
      }
      ManualInvoice.Over12Hours = HH;
      this.setState({ ManualInvoice: ManualInvoice, error: null });
    };

    /** a lot of copypaste from _getActivities() here */
    _getAddonFees() {
      if (!this.state.allNavActivities) {
          return <option disabled={true} className="status-loading-animated-ellipsis">Loading Activities</option>;
      }
      const options = [];
      options.push(
        <option key="PRJ00" value="">
          Select Activity 2
        </option>
      );
      const list = this.props.activityOptionsList;
      const selectedActivity = this.state.ManualInvoice.Activity;
      if (list != undefined) {
        const applicableActivities = this.state.allNavActivities
          .filter(a => a.Name.startsWith("Add-on/"));
        _.sortBy(applicableActivities, "Code").forEach((p) => {
          if (p.Code !== "" && list.includes(p.Code)) {
            options.push(
              <option
                disabled={selectedActivity === p.Code}
                key={p.Code}
                value={p.Code}
              >
                {p.Name}
              </option>
            );
          }
        });
      }
      return options;
    };

    _getMonthName(month: number) {
      return getMonthFullName(month + 1);
    };

    render() {
      const list = this.props.displayedFieldsList;
      let overtimeHH = "00";
      let overtimMM = "00";
      if (this.state.ManualInvoice.OverTime.split(":")[0] != undefined)
        overtimeHH = this.state.ManualInvoice.OverTime.split(":")[0].trim();
      if (this.state.ManualInvoice.OverTime.split(":")[1] != undefined)
        overtimMM = this.state.ManualInvoice.OverTime.split(":")[1].trim();
      let monthForLastDay = this.props.month;
      if (monthForLastDay == 12) monthForLastDay = 0;
      return (
        <div className="container manualinvoice">
          <div className="row">
            <h2 className="monthHeading">
              {getMonthFullName(this.props.month) + " " + this.props.year}
            </h2>
            <div className="col-xs-12">
              <div className="deadlineBackContainer col-xs-12">
                <a onClick={(e) => this.showManualInvoiceDetailsTable()}>
                  <img className="back" src="/img/back.png" alt={"back"}/>
                </a>
                <span className="deadlineSpan">
                  <img
                    className="deadline"
                    style={{ display: "inline-block" }}
                    src="/img/deadline.png"
                  />
                  Deadline: {this.props.DeadlineDay}{" "}
                  {this._getMonthName(monthForLastDay).toUpperCase()}
                </span>
              </div>
              <form className="col-xs-12 form-horizontal logTimeForm" onSubmit={(e) => this.addUpdateManualInvoiceDetailsWithLock(e)}>
                <div className="form-group">
                  <label className="col-sm-2 control-label">
                    Activity Date:{" "}
                  </label>
                  <div className="col-sm-4 datePickerContainer">
                    <Calendar
                      {...this.state.pickerValues}
                      autocomplete="off"
                      onPropsChange={(e) => this.changePickerValues(e)}
                      value={!this.state.ManualInvoice.isEdit ?
                        this.state.ActivityDates :
                        this.state.ActivityDate}
                      onChange={(e) => this.setState({ ActivityDates: e })}
                      dateFormat="DD.MM.YYYY"
                      weekStartDayIndex={1}
                      maxDate={
                        new Date(
                          this.props.year,
                          this.props.month,
                          0
                        )
                      }
                      minDate={
                        new Date(
                          this.getMinDate().MinAllowedYear,
                          this.getMinDate().MinAllowedMonth,
                          1
                        )
                      }
                      plugins={!this.state.ManualInvoice.isEdit ? [
                        <DatePanel eachDaysInRange formatFunction={this.formatDate}/>,
                        <Settings
                          position="bottom"
                          modes={["multiple", "range"]}
                          calendars={["gregorian"]}
                          disabledList={["other", "locale", "calendar"]}
                          defaultActive="mode"
                          names={{ "range": "Range", "multiple": "Multiple" } as SettingsProps["names"]}
                        />,
                      ] : undefined}
                    />
                  </div>

                </div>
                <div className="form-group">
                  <div
                    className={list.indexOf("FlightNumber") > -1 ? "" : "hideMe"}
                  >
                    <label className="col-sm-2 control-label">
                      Flight Number:{" "}
                    </label>
                    <div className="col-sm-4">
                      <input
                        className="form-control"
                        maxLength={20 /* note, loadmasters use this field for activity description -_- */}
                        onChange={(e) => this._updateFlighNumber(e)}
                        value={this.state.ManualInvoice.FlightNumber}
                      ></input>
                    </div>
                  </div>
                  <div
                    className={list.indexOf("AircraftReg") > -1 ? "" : "hideMe"}
                  >
                    <label className="col-sm-2 control-label">
                      Aircraft Reg:{" "}
                    </label>
                    <div className="col-sm-4">
                      <input
                        className="form-control"
                        onChange={(e) => this._updateAircraftReg(e)}
                        value={this.state.ManualInvoice.AircraftReg}
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className={list.indexOf("Base") > -1 ? "" : "hideMe"}>
                    <label className="col-sm-2 control-label">Base: </label>
                    <div className="col-sm-4">
                      <select
                        className="form-control"
                        onChange={(e) => this._selectBase(e)}
                        value={this.state.ManualInvoice.Base}
                      >
                        {this._getBases()}
                      </select>
                    </div>
                  </div>
                  <div className={list.indexOf("Project") > -1 ? "" : "hideMe"}>
                    <label className="col-sm-2 control-label">Project: </label>
                    <div className="col-sm-4">
                      <select
                        className="form-control"
                        onChange={(e) => this._selectProject(e)}
                        value={this.state.ManualInvoice.Project}
                      >
                        {this._getProjects()}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className={list.indexOf("Activity") > -1 ? "" : "hideMe"}>
                    <label className="col-sm-2 control-label">Activities: </label>
                    <div className="col-sm-4">
                      <select
                        className="form-control"
                        onChange={(e) => this._selectActivity(e)}
                        value={this.state.ManualInvoice.Activity}
                      >
                        {this._getActivities()}
                      </select>
                    </div>
                  </div>
                  <div className={list.indexOf("AddonFee") > -1 ? "" : "hideMe"}>
                    <label className="col-sm-2 control-label">Activity 2: </label>
                    <div className="col-sm-4">
                      <select
                        className="form-control"
                        disabled={!this.canAddExtraActivity()}
                        onChange={(e) => this._selectAddonFee(e)}
                        value={this.state.ManualInvoice.AddonFee}
                      >
                        {this._getAddonFees()}
                      </select>
                    </div>
                  </div>
                </div>
                {this.state.ManualInvoice.AddonFees.length > 0 ? (
                  <div className="form-group">
                    <div
                      className={list.indexOf("Activity") > -1 ? "" : "hideMe"}
                    >
                      <label className="col-sm-2 control-label"> </label>
                      <div className="col-sm-4"></div>
                    </div>
                    <div
                      className={list.indexOf("AddonFee") > -1 ? "" : "hideMe"}
                    >
                      <label className="col-sm-2 control-label">
                        Selected fees:
                      </label>
                      <div className="col-sm-4 addOnFeeContainer">
                        {this.generateSelectedAddonFeeList()}
                      </div>
                    </div>
                  </div>
                ) : null}

                <div className="form-group">
                  <div className={list.indexOf("TimeIn") > -1 ? "" : "hideMe"}>
                    <label className="col-sm-2 control-label">Time In:</label>
                    <div className="col-sm-4">
                      <input
                        className="form-control"
                        value={this.state.ManualInvoice.TimeIn1}
                        onChange={(e) => this._updateTimeIn1(e)}
                        placeholder="00:00"
                        pattern={/^([01]?\d|2[0-3]):?[0-5]\d$/.source}
                        maxLength={5}
                      ></input>
                    </div>
                  </div>
                  <div className={list.indexOf("TimeOut") > -1 ? "" : "hideMe"}>
                    <label className="col-sm-2 control-label">Time Out: </label>
                    <div className="col-sm-4">
                      <input
                        className="form-control"
                        value={this.state.ManualInvoice.TimeOut1}
                        onChange={(e) => this._updateTimeOut1(e)}
                        placeholder="23:59"
                        pattern={/^([01]?\d|2[0-3]):?[0-5]\d$/.source}
                        maxLength={5}
                      ></input>

                      {/*<div className="col-sm-4">*/}
                      {/*  /!* <input type="checkbox" onChange={(e) => this._updateFlying} checked={this.state.ManualInvoice.Flying} className="form-control" /> *!/*/}

                      {/*</div>*/}
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className={list.indexOf("TimeIn2") > -1 ? "" : "hideMe"}>
                    <label className="col-sm-2 control-label">Time In2: </label>
                    <div className="col-sm-4">
                      <input
                        className="form-control half"
                        value={this.state.ManualInvoice.TimeIn2}
                        placeholder="00:00"
                        pattern={/^([01]?\d|2[0-3]):?[0-5]\d$/.source}
                        onChange={(e) => this._updateTimeIn2(e)}
                        maxLength={5}
                      ></input>
                    </div>
                  </div>

                  <div className={list.indexOf("TimeOut2") > -1 ? "" : "hideMe"}>
                    <label className="col-sm-2 control-label">Time Out2: </label>
                    <div className="col-sm-4">
                      <input
                        className="form-control half"
                        value={this.state.ManualInvoice.TimeOut2}
                        placeholder="23:59"
                        pattern={/^([01]?\d|2[0-3]):?[0-5]\d$/.source}
                        onChange={(e) => this._updateTimeOut2(e)}
                        maxLength={5}
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div
                    className={list.indexOf("Over12Hours") > -1 ? "" : "hideMe"}
                  >
                    <label className="col-sm-2 control-label">
                      Over 12 Hours:{" "}
                    </label>
                    <div className="col-sm-4">
                      <select
                        onChange={(e) => this._updateOver12HH(e)}
                        defaultValue={
                          this.state.ManualInvoice.Over12Hours.split(":")[0]
                        }
                        className="form-control half"
                      >
                        {this._getHour()}
                      </select>

                      <select
                        onChange={(e) => this._updateOver12MM(e)}
                        defaultValue={
                          this.state.ManualInvoice.Over12Hours.split(":")[1]
                        }
                        className="form-control half"
                      >
                        {this._getMin()}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div
                    className={list.indexOf("BlockHours") > -1 ? "" : "hideMe"}
                  >
                    <label className="col-sm-2 control-label">
                      Block Hours:{" "}
                    </label>
                    <div className="col-sm-4">
                      <input
                        className="form-control half"
                        value={this.state.ManualInvoice.BlockHours}
                        placeholder="00:00"
                        onChange={(e) => this._updateBlcok(e)}
                        maxLength={5}
                      ></input>
                    </div>
                  </div>

                  <div className={list.indexOf("OverTime") > -1 ? "" : "hideMe"}>
                    <label className="col-sm-2 control-label">Overtime: </label>
                    <div className="col-sm-4">
                      <select
                        onChange={(e) => this._updateOverTimeHH(e)}
                        defaultValue={overtimeHH}
                        className="form-control half"
                      >
                        {this._getHour()}
                      </select>

                      <select
                        onChange={(e) => this._updateOverTimeMM(e)}
                        defaultValue={overtimMM}
                        className="form-control half"
                      >
                        {this._getMin()}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className={list.indexOf("AddDay") > -1 ? "" : "hideMe"}>
                    <label className="col-sm-2 control-label">ADD. Day: </label>
                    <div className="col-sm-4">
                      <input
                        type="checkbox"
                        onChange={(e) => this._updateAdditionalDay(e)}
                        checked={this.state.ManualInvoice.AdditionalDay}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className={list.indexOf("Flying") > -1 ? "" : "hideMe"}>
                    <label className="col-sm-2 control-label">Flying: </label>
                    <div className="col-sm-4">
                      <input
                        type="checkbox"
                        onChange={(e) => this._updateFlying(e)}
                        checked={this.state.ManualInvoice.Flying}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <div
                    className={list.indexOf("PaymentONOFF") > -1 ? "" : "hideMe"}
                  >
                    <label className="col-sm-2 control-label">Payment: </label>
                    <div className="col-sm-4">
                      <input
                        type="checkbox"
                        onChange={(e) => this._updatePaymentONOFF(e)}
                        checked={this.state.ManualInvoice.PaymentOnOff}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div
                    className={list.indexOf("SoldOffDay") > -1 ? "" : "hideMe"}
                  >
                    <label className="col-sm-2 control-label">
                      Sold off day:{" "}
                    </label>
                    <div className="col-sm-4">
                      <input
                        type="checkbox"
                        onChange={(e) => this._updateSoldOffDay(e)}
                        checked={this.state.ManualInvoice.SoldOffDay}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div
                    className={
                      list.indexOf("PositioningHours") > -1 ? "" : "hideMe"
                    }
                  >
                    <label className="col-sm-2 control-label">
                      Positioning hours:{" "}
                    </label>
                    <div className="col-sm-4">
                      <input
                        type="checkbox"
                        onChange={(e) => this._updatePositioningHours(e)}
                        checked={this.state.ManualInvoice.PositioningHours}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className={list.indexOf("PerDiem") > -1 ? "" : "hideMe"}>
                    <label className="col-sm-2 control-label">Per diem: </label>
                    <div className="col-sm-4">
                      <input
                        type="checkbox"
                        onChange={(e) => this._updatePerDiem(e)}
                        checked={this.state.ManualInvoice.PerDiem}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>

                <div className="row errorMessage">
                  <span
                    className="content"
                    dangerouslySetInnerHTML={{ __html: this.state.MandatoryFiles }}
                  ></span>
                </div>
                <div className="row">
                  <a
                    className="btn btn-default"
                    onClick={() => this.showManualInvoiceDetailsTable()}
                  >
                    Cancel
                  </a>
                  <button
                    className="btn btn-warning"
                    type="submit"
                    disabled={this.state.submitting}
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      );
    };
}