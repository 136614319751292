import * as React from "react";
import { useState } from "react";
import api from "../../features/Api";
import { amountDifferenceSinceLastConfirmation, getNewTotalText } from "../../utils/feeStatementInvoiceUtils";
import type { FeeStatementInvoiceConfirmationReportFormProps } from "../../types/feeStatementTypes";

export default function FeeStatementInvoiceConfirmationReportToClientForm(
    { confirmationData, processNewStatusMessage }: FeeStatementInvoiceConfirmationReportFormProps
) {
    const {
        locator,
        partyConfirmations,
        fullPayslip,
        companyData
    } = confirmationData;
    const [responseToClientComment, setResponseToClientComment] = useState<string>();
    const [onlyForClient, setOnlyForClient] = useState(true);
    const lastConfirmation = partyConfirmations?.CLIENT ?? null;
    const amountDifference  = amountDifferenceSinceLastConfirmation(fullPayslip, lastConfirmation);

    const clientDiscrepancyFixedDefaultComment = () => {
        return `The data in invoice was amended according to your input. ${getNewTotalText(companyData, fullPayslip)}`;
    };

    const reportFixedClientReportedDiscrepancy = () => {
        if (!responseToClientComment?.trim() && !amountDifference) {
            alert("Total in Navision not updated - please explain why this discrepancy should be considered resolved.");
            return;
        }
        const discrepancyFixedComment = responseToClientComment?.trim() || clientDiscrepancyFixedDefaultComment();
        const whenSuccess = api.Invoice.ReportFixedClientReportedInconsistencyInNavInvoice({
            ...locator,
            discrepancyFixedComment: discrepancyFixedComment,
            onlyFor: onlyForClient ? "CLIENT" : null,
        });
        processNewStatusMessage(whenSuccess, {
            ...locator,
            confirming_party: "CLIENT",
            status: "CONFIRMED_BY_CLIENT",
        }, {
            author_role: "OFFICER",
            message_text: discrepancyFixedComment,
        });
    };

    const replyToClient = () => {
        if (!responseToClientComment?.trim()) {
            alert("Write reply comment.");
            return;
        }

        const whenSuccess = api.Invoice.ReplyToClientReportedDiscrepancy({
            ...locator,
            replyComment: responseToClientComment,
            onlyFor: onlyForClient ? "CLIENT" : null,
        });
        processNewStatusMessage(whenSuccess, {
            ...locator,
            confirming_party: "CLIENT",
            status: "CONFIRMED",
        }, {
            author_role: "OFFICER",
            message_text: responseToClientComment,
        });
    };

    return <form className="rejectionFixedForm">
        <textarea
            required={!amountDifference}
            value={responseToClientComment}
            onChange={e => setResponseToClientComment(e.target.value)}
            placeholder={amountDifference
                ? clientDiscrepancyFixedDefaultComment()
                : "Total in Navision not updated - please explain why this discrepancy should be considered resolved"}
            rows={4}
        ></textarea>
        <label style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", columnGap: "9px" }}>
            <span>Show this Message Only to Client</span>
            <input type="checkbox" checked={onlyForClient} onChange={event => setOnlyForClient(event.target.checked)}/>
        </label>
        <button
            onClick={replyToClient}
            type="button"
            className={`btn-primary rejectionFixedBtn ${!amountDifference ? "highlighted-button" : ""}`}
        >Ask Client a Question
        </button>
        <button
            onClick={reportFixedClientReportedDiscrepancy}
            type="button"
            className={`btn-primary rejectionFixedBtn ${amountDifference ? "highlighted-button" : ""}`}
        >Mark as Resolved
        </button>
    </form>;
}