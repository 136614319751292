
import {
  loadCompaniesWise,
  loadCompanyActivitySetup,
  loadJobTitlesWise,
  loadCompanyActivitySetupAndJobtitle, deleteCompanyActivitySetupJobtitle, addCompanyActivitySetup
} from "../features/Company";

import ErrorPage from "./ErrorPage";

let React = require('react');
import {connect, Provider} from "react-redux";

import {confirmAlert} from 'react-confirm-alert';
import {loadActivitiesWise} from "../features/Activity"; // Import
let _ = require('lodash');
import InvoiceMenu from '../components/InvoiceMenu';

const mapStateToProps = (state) => {
  return {
    profile: state.profile,
    activity: state.activity,
    company: state.company,
  };
}
const mapDispatchToProps = (dispatch) => {
  return {
    loadProfile: (payload) => dispatch(loadProfile(payload)),
    loadCompaniesWise: () => dispatch(loadCompaniesWise()),
    loadActivitiesWise: () => dispatch(loadActivitiesWise()),
    loadJobTitlesWise: () => dispatch(loadJobTitlesWise()),
    loadCompanyActivitySetup: (payload) => dispatch(loadCompanyActivitySetup(payload)),
    loadCompanyActivitySetupAndJobtitle: (payload) => dispatch(loadCompanyActivitySetupAndJobtitle(payload)),
    deleteCompanyActivitySetupJobtitle: (payload) => dispatch(deleteCompanyActivitySetupJobtitle(payload)),
    addCompanyActivitySetup: (payload) => dispatch(addCompanyActivitySetup(payload)),
  }
};
export const views = {
  Table: 0,
  Form: 1
}

class InvoicesUsersActivities extends React.Component {
  //displayName: 'InvoicesUsersActivities',
  constructor(props) {
    super(props);
    require("./InvoicesUsersActivities.css");
    this.state = {
      madeFirstCall: false,
      currentPage: 1,
      employeesPerPage: 10,
      isAdmin: this.props.isAdmin,
      viewForm: views.Table,
      viewUpdateFormName: "",
      viewNewFormName: "",
      SelectedActivities: [],
      SelectedCompany: '',
      SelectedJobTitle: '',
      sortBy: {
        column: "",
        order: ""
      }
    };
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.loading) {
      return false;
    }
    return true;
  };

  componentDidMount() {
    this.props.loadActivitiesWise().unwrap()
      .then(() => {
        this.setState({loading: false});
      })
      .catch(() => {
      })

    this.props.loadJobTitlesWise().unwrap()
      .then(() => {
        this.setState({loading: false});
      })
      .catch(() => {
      })
    this.props.loadCompaniesWise().unwrap()
      .then(() => {
        this.setState({loading: false});
      })
      .catch(() => {
      })
    if (!this.state.madeFirstCall)
      this.setState({
        madeFirstCall: true,
        view: "All",
        ShowAdminsFields: "none"
      });
  };

  order(employees) {
    if (this.state.sortBy.column != "") {
      return _.orderBy(employees, [this.state.sortBy.column], [this.state.sortBy.order]);
    }

    return employees;
  };

  handleSort(e) {
    const headerId = e.target.id;

    this.setState({
      sortBy: {
        column: headerId.substr(2),
        order: this.state.sortBy.order == "desc" ? "asc" : "desc"
      }
    });

    _.forEach(e.target.parentElement.children, function (c) {
      c.className = "";
    });

    e.target.className = this.state.sortBy.order == "desc" ? "asc" : "desc";
  };

  createSelectItems() {
    let items = [];
    if (this.props.company.companiesWise != null) {

      items.push(<option key={-1} value="">Choose Client</option>);
      for (let i = 0; i < parseInt(this.props.company.companiesWise.length); i++) {
        let comp = this.props.company.companiesWise[i];
        items.push(<option key={comp.Code} value={comp.Code}>{comp.DisplayName}</option>);
      }
    }
    return items;
  };

  createSelectJobTitleItems() {
    let items = [];
    items.push(<option key="-1" value="">Choose Jobtitle</option>);
    this.props.company.jobTitlesWise.map((e) => {
      if (e.Code !== "")
        items.push(<option key={e.Code}>{e.Name}</option>);
    });

    return items;
  };

  showActivityForm(e) {
    let job = "";
    if (e.target.attributes["data-jobtitle"] !== undefined)
      job = e.target.attributes["data-jobtitle"].value;
    if (!this.state.loading) {
      this.setState({
        viewUpdateFormName: e.target.attributes["data-companyname"].value,
        SelectedJobTitle: job,
        SelActivities: undefined,
        viewForm: views.Form
      });
    }
    const payload = {
      companyName: this.state.SelectedCompany,
      jobTitle: job
    }
    this.props.loadCompanyActivitySetupAndJobtitle(payload).unwrap()
      .then((originalPromiseResult) => {
        this.setState({loading: false, companyActivitySetup: originalPromiseResult});
      })
      .catch(() => {
      })
  };

  _ChangeCompany(e) {
    if (!this.state.loading) {
      this.setState({
        viewNewFormName: e.target.value,
        SelectedCompany: e.target.value
      });
    }
    this.props.loadCompanyActivitySetup(e.target.value).unwrap()
      .then((originalPromiseResult) => {
        this.setState({loading: false, activitySetup: originalPromiseResult});
      })
      .catch(() => {
      })
  };

  loadCompanyActivitySetup(company) {
    this.props.loadCompanyActivitySetup(company).unwrap()
      .then(() => {
        this.setState({loading: false});
      })
      .catch(() => {
      })
  }

  _generateCompanyList() {
    return (
      <div className="col-xs-6"
           style={{paddingLeft: "0", display: this.state.ShowAdminsFields == "table-cell" ? "none" : "table-cell"}}>
        <select onChange={(e) => this._ChangeCompany(e)} value={this.state.SelectedCompany}
                style={{fontSize: "20px", width: "100%"}}>
          {this.createSelectItems()}
        </select>
      </div>
    );
  };

  _generateJobTitleList() {
    return (
      <div className="row" style={{display: this.state.ShowAdminsFields == "table-cell" ? "none" : "table-cell"}}>
        <select onChange={(e) => this._ChangeJobtitle(e)} value={this.state.SelectedJobTitle}
                style={{fontSize: "20px", minWidth: "250px", width: "auto"}}>
          {this.createSelectJobTitleItems()}
        </select>
      </div>
    );
  };

  handleChange(e, code) {
    let list = this.state.companyActivitySetup
    if (!this.state.loading) {
      if (e.target.checked) {
        if (!list.includes(code)) {
          list.concat(code);
          this.setState({
            companyActivitySetup: this.state.companyActivitySetup.concat(code)
          });
        }
      } else {
        let filteredArray = list.filter(item => item !== code);
        this.setState({companyActivitySetup: filteredArray});
      }
    }

  };

  _ChangeJobtitle(e) {
    if (!this.state.loading) {
      this.setState({
        SelectedJobTitle: e.target.value,
        SelActivities: undefined
      });
    }
  };

  _generateActivityList() {
    let items = [];
    if (this.state.companyActivitySetup !== null && this.props.activity.activities.length > 0) {
      if (this.state.SelectedJobTitle !== "") {
        for (let i = 0; i < parseInt(this.props.activity.activities.length); i++) {
          if (this.props.activity.activities[i].Code !== "") {
            let checked = false;
            if (this.state.companyActivitySetup !== undefined && this.state.companyActivitySetup.length > 0) {
              checked = this.state.companyActivitySetup.includes(this.props.activity.activities[i].Code);
            }
            items.push(<div className="col-sm-9" style={{fontSize: "20px", minWidth: "250px"}}><input
              type="checkbox"
              checked={checked}
              onChange={(e) => this.handleChange(e, this.props.activity.activities[i].Code)}
              style={{display: "inline-block"}}
              className="form-control"/> {this.props.activity.activities[i].Name}
            </div>);
          }
        }
        return (
          <div>
            {items}
          </div>
        );
      }
    }
  };

  _getHeader() {
    return (<div className="invoice-header">
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <h1>Activities - Setup</h1>
          </div>
        </div>
      </div>
    </div>);
  };

  _createInvoiceMenu() {
    return (<InvoiceMenu selected="Activity"/>);
  };

  showTable() {
    if (!this.state.loading) {
      this.setState({
        viewForm: views.Table
      });
    }
  };

  deleteActivity(e) {
    const comp = e.target.attributes["data-company"].value;
    const job = e.target.attributes["data-jobtitle"].value;
    const payload = {
      companyName: comp,
      jobTitle: job
    };
    confirmAlert({
      title: 'Delete',
      message: 'Are you sure to delete: ' + e.target.attributes["data-jobtitle"].value + " from company: " + e.target.attributes["data-company"].value,
      buttons: [
        {
          label: 'Delete',
          onClick: () => {

            this.props.deleteCompanyActivitySetupJobtitle(payload).unwrap()
              .then(() => {
                this._createFieldsTable();
                this.loadCompanyActivitySetup(comp)
              })
              .catch(() => {
              })
          }
        },
        {
          label: 'Cancel',
          onClick: () => alert('Click No')
        }
      ]
    });
    this.setState({
      view: views.Table
    });
  };

  _saveActivities() {
    let activities = "";
    for (let i = 0; i < this.state.companyActivitySetup.length; i++) {
      if (this.state.companyActivitySetup[i] !== "")
        activities = activities + this.state.companyActivitySetup[i] + '|';
    }
    if (activities === "")
      activities = "null";
    const payload = {
      companyName: this.state.viewUpdateFormName,
      jobTitle: this.state.SelectedJobTitle,
      activity: activities
    }
    this.props.addCompanyActivitySetup(payload).unwrap()
      .then(() => {
      })
      .catch(() => {
      })

    if (!this.state.loading) {
      this.setState({
        SelectedActivities: [],
        SelectedJobTitle: '',
        viewForm: views.Table
      });
    }
    this.loadCompanyActivitySetup(this.state.SelectedCompany)
  };

  _createActivityTable() {
    const self = this;
    let records = self.props.company.companyActivitySetup && self.props.company.companyActivitySetup.length > 0 ? self.props.company.companyActivitySetup.map((r) => {
      return (<tbody>
      <tr key={r.id}>

        <td>{r.JobTitle}</td>
        <td><a data-companyname={r.CompanyName} data-jobtitle={r.JobTitle} onClick={(e) => this.showActivityForm(e)}
               href="#">Edit</a></td>
        <td><a style={{display: "inline-block"}}
               data-company={r.CompanyName} data-jobtitle={r.JobTitle} onClick={(e) => this.deleteActivity(e)}
               href="#">Delete</a>
        </td>
      </tr>
      {/* <tr><td colSpan="3">{r.Activites.split('|').map((k) => <span className="fieldList">{k}</span> )}</td></tr> */}
      </tbody>);
    }) : this.state.SelectedCompany == "" ? <tbody>
    <tr>
      <td>Select Client</td>
    </tr>
    </tbody> : <tbody>
    <tr>
      <td>No Department assigned</td>
    </tr>
    </tbody>;
    return (<div>
      <div className="col-xs-12">
        <div className="col-xs-9">

          <div className="row">
            <h3>Client</h3>
          </div>
          <div className="row">
            {this._generateCompanyList()}
          </div>
        </div>
        <div className="col-xs-3">
          <span className="btn btn-warning"
                style={{marginTop: "30px", display: this.state.viewNewFormName == "" ? "none" : "inline-block"}}
                data-companyname={this.state.viewNewFormName}
                onClick={(e) => this.showActivityForm(e)}>Assign new Department</span>

        </div>
      </div>
      <div className="col-xs-12">
        <div className="table-responsive admin">
          <div className="header">
            <h3>
              Departments
            </h3>
          </div>
          <table className="invoices invoice-months table table-striped table-condensed">


            {records}

          </table>
        </div>
      </div>
    </div>);
  };

  _createActivityForm() {



    return (
      <div className="row col-xs-12">
        <div className="col-xs-12">
          <h3>Select Department for company: {this.state.viewUpdateFormName}</h3>
          <hr/>
          {this._generateJobTitleList()}
          <br/><br/>
        </div>

        <div className="col-xs-12">
          <h3>Select Activities</h3>
          <hr/>
          <div className=" col-md-12">
            <div className="form-group">
              {this._generateActivityList()}
            </div>
          </div>
        </div>
        <div className="row col-md-12 action-buttons" style={{marginTop: "40px"}}>
          <a className="btn btn-warning" href="#"
             style={{display: this.state.SelectedJobTitle == "" ? "none" : "inline-block"}}
             onClick={(e) => this._saveActivities(e)}>Save</a>
          <a className="btn btn-warning" href="#" onClick={(e) => this.showTable(e)}>Cancel</a>
        </div>
        <div className="row col-md-12">

        </div>
      </div>);
  };

  hasAdminPrivileges() {
    const cleanIsAdmin = this.state.isAdmin === "True" || this.state.isAdmin === "true";
    return cleanIsAdmin;
  };

  render() {
    if (!this.hasAdminPrivileges()) {
      return <ErrorPage/>
    }
    let employees = this.state.loadCompaniesWise !== undefined ? this.order(this.state.loadCompaniesWise) : [];

    let indexOfLastEmployee = this.state.currentPage * this.state.employeesPerPage;
    let indexOfFirstEmployee = indexOfLastEmployee - this.state.employeesPerPage;
    employees ? employees.slice(indexOfFirstEmployee, indexOfLastEmployee) : null;
    let content = null;
    if (this.state.viewForm == views.Form)
      content = this._createActivityForm();
    else
      content = this._createActivityTable();

    if (!this.state.madeFirstCall || this.props.company.companiesWise == undefined) {
      return (<div className="invoice-wrapper">
        {this._getHeader()}
        <div className="container">
          <div className="row">
            <div className="col-xs-12">
              <h1
                className="loading">{!this.state.madeFirstCall ? "Loading list of Activities..." : "No Activities found"}</h1>
            </div>
          </div>
        </div>
      </div>);
    } else {
      return (
        <div className="invoice-wrapper">
          {this._getHeader()}
          <div className="container">
            <div className="row">
              <div className="col-xs-3 invocie-menu">
                {this._createInvoiceMenu()}

              </div>

              <div className="content-wrapper">
                <div className="col-xs-9">
                  {content}
                </div>
              </div>
            </div>
          </div>

        </div>
      );
    }
  }
}

const wrapper = (props) => {
  return <Provider store={window.store}>{<InvoicesUsersActivities {...props}/>}</Provider>;
}
module.exports = connect(
  mapStateToProps,
  mapDispatchToProps
)(wrapper);
