import * as React from "react";
import type { CompanyName } from "../features/Api";
import api from "../features/Api";


import type { AbsoluteMonth } from "../utils/dates";
import  { getMonthEndDate,getMonthStartDate } from "../utils/dates";
import { getPastMonth } from "../utils/dates";
import { strcmp } from "../utils/dates";
import type { FeeEntryType,
    ImpersonalSalaryJournalRecord,
    NamedSalaryJournalRecord,
    PersonMatch
} from "../features/ExternalRostersApi";
import externalRostersApi from "../features/ExternalRostersApi";
import NavJournalRecordsTable, { getOfficerNotesText } from "../components/NavJournalRecordsTable";
import type { PayEntry } from "../types/NavisionPayslip";
import { getIataToIana, getOfficerFlightDeckFeesMapping } from "../features/StaticData";
import type { PersonMonthRosterLocator
} from "../features/roster/air_atlanta/FeeCalculatorUtils";
import {
    fetchCalculationDataForPerson,
    fixManually,
    isCabinCrewDepartment,
    isFlightDeckDepartment,
    isMaintenanceDepartment
} from "../features/roster/air_atlanta/FeeCalculatorUtils";
import { calculateFeesForFlightDeckPerson,getPrecedingUnterminatedRotationDays } from "../features/roster/air_atlanta/FeeCalculatorFlightDeck";
import { calculateFeesForCabinCrewPerson } from "../features/roster/air_atlanta/FeeCalculatorCabinCrew";
import type { OfficerFlightDeckFeesPerson } from "../types/Api/Contract";
import { brand, toCrewCodeUc } from "../utils/typing";

type CalculatedPerson = {
    person: PersonMatch,
    journalRecords: NamedSalaryJournalRecord[],
};

type Props = {};
type State = {
    calculatedPeople: CalculatedPerson[],
    year: number,
    month: number,
    loading: boolean,
    includeFlightDeck: boolean,
    includeCabinCrew: boolean,
    includeMtx: boolean,
    COMPANYNAME: CompanyName,
    codeToNavInvoiceItems: Record<string, PayEntry[]> | null,
    officerFlightDeckFeesMapping: Record<string, OfficerFlightDeckFeesPerson> | null,
    rosterRetrievalTime: string,
    totalPeople: number | null,
    peopleInitiated: number,
    forceLatestRoster: boolean,
};

function isCabinCrew(person: PersonMatch) {
    return isCabinCrewDepartment(person.navEntry.GlobalDimension1Code);
}

function isFlightDeck(person: PersonMatch) {
    return isFlightDeckDepartment(person.navEntry.GlobalDimension1Code);
}

function isMtx(person: PersonMatch) {
    return isMaintenanceDepartment(person.navEntry.GlobalDimension1Code);
}

export default class InvoiceRosterCalculationAirAtlanta extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        require("./InvoiceRosterCalculationAirAtlanta.css");
        const search = new URLSearchParams(window.location.search);
        const COMPANYNAME = search.get("COMPANYNAME");
        if (!COMPANYNAME) {
            throw new Error("COMPANYNAME not supplied in URL");
        }
        let { year, month } = getPastMonth(new Date());
        const yearStr = search.get("year");
        const monthStr = search.get("month");
        year = yearStr ? +yearStr : year;
        month = monthStr ? +monthStr : month;
        this.state = {
            calculatedPeople: [],
            year: year,
            month: month,
            loading: true,
            includeFlightDeck: true,
            includeCabinCrew: true,
            includeMtx: true,
            COMPANYNAME: brand(COMPANYNAME),
            codeToNavInvoiceItems: null,
            officerFlightDeckFeesMapping: null,
            rosterRetrievalTime: new Date().toISOString(),
            totalPeople: null,
            peopleInitiated: 0,
            forceLatestRoster: search.get("forceLatestRoster") === "true",
        };
        this.fetchData();
    }

    private get latestRosterLink() {
        const url = new URL(window.location.href);
        url.searchParams.set("forceLatestRoster", "true");
        return url;
    }

    private get invoicedInAirborne() {
        const yearMonthStr = this.yearMonth.year + "-" + String(this.yearMonth.month).padStart(2, "0");
        return yearMonthStr <= "2024-02"
            && this.state.COMPANYNAME === "HEL - AAIUSD";
    }

    private fetchData() {
        externalRostersApi.getActiveMhcPeople({
            COMPANYNAME: this.state.COMPANYNAME,
            clientId: 429,
        }).then(async (people) => {
            this.setState({
                totalPeople: people.length,
            });
            let i = 0;
            for (const person of people) {
                this.setState({
                    peopleInitiated: ++i,
                });
                if (!isFlightDeck(person) && !isCabinCrew(person) && !isMtx(person)) {
                    continue; // only flight deck, mtx and cabin crew are supported for now
                }
                const journalRecords = await this.calculateFeesForPerson(person);
                this.setState(prevState => ({
                    calculatedPeople: [
                        ...prevState.calculatedPeople,
                        { person, journalRecords },
                    ],
                }));
            }
        }).finally(() => this.setState({ loading: false }));
        api.Invoice.all_invoice_items_in_payroll({
            company: this.state.COMPANYNAME,
            ...this.yearMonth,
            department: 0,
        }).then(async items => {
            if (this.invoicedInAirborne) {
                // all CCM were moved to Helo database,
                items.push(...await api.Invoice.all_invoice_items_in_payroll({
                    company: brand("Airborne - Malta"),
                    ...this.yearMonth,
                    department: 0,
                }));
            }
            if (items.length === 0) {
                return; // nothing to validate against: start of the month
            }
            const codeToNavInvoiceItems: Record<string, PayEntry[]> = {};
            for (const item of items) {
                codeToNavInvoiceItems[item.Nr_.toUpperCase()] = codeToNavInvoiceItems[item.Nr_.toUpperCase()] ?? [];
                codeToNavInvoiceItems[item.Nr_.toUpperCase()].push(item);
            }
            this.setState({ codeToNavInvoiceItems });
        });
        getOfficerFlightDeckFeesMapping(this.state.year, this.state.month)
            .then(officerFlightDeckFeesMapping => this.setState({ officerFlightDeckFeesMapping }));
    }

    private get yearMonth(): AbsoluteMonth {
        return this.state;
    }

    private async calculateFeesForPerson(person: PersonMatch): Promise<NamedSalaryJournalRecord[]> {
        const employeeCode = toCrewCodeUc(person.navEntry.No_);
        if (person.navEntry.Status) {
            console.log("skipping an inactive person:: " + employeeCode + " " + person.navEntry.Status);
            return []; // inactive
        }
        const rosterLocator: PersonMonthRosterLocator = {
            ...person,
            ...this.yearMonth,
        };
        const personification = {
            crew_code: employeeCode,
            full_name: person.navEntry.FirstName + " " + person.navEntry.LastName,
        };
        const journalRecords: ImpersonalSalaryJournalRecord[] = [];
        if (!person.rdbEntry) {
            journalRecords.push(...fixManually(rosterLocator, "RDB Missing"));
        }
        const companyPerson = {
            ...person,
            navEntry: {
                ...person.navEntry,
                CompanyName: this.state.COMPANYNAME,
            },
        };
        const extraData = await fetchCalculationDataForPerson(
            rosterLocator, companyPerson, this.state.forceLatestRoster
        );
        this.setState(state => ({
            rosterRetrievalTime: extraData.personRoster.retrieved_time < state.rosterRetrievalTime
                ? extraData.personRoster.retrieved_time : state.rosterRetrievalTime,
        }));
        extraData.personRoster.retrieved_time;

        const params = { ...rosterLocator, ...extraData };
        if (isFlightDeck(person)) {
            const iataToIana = await getIataToIana();
            const officerMapping = await getOfficerFlightDeckFeesMapping(this.state.year, this.state.month);
            const officerFees = officerMapping[employeeCode] ?? null;
            const precedingUnterminatedRotationDays = await getPrecedingUnterminatedRotationDays({
                employeeCode: toCrewCodeUc(person.navEntry.No_),
                company: this.state.COMPANYNAME,
                year: this.state.year,
                month: this.state.month,
            });
            const params = { ...rosterLocator, ...extraData, precedingUnterminatedRotationDays };
            const calculated = calculateFeesForFlightDeckPerson(params, iataToIana, officerFees);
            if (calculated.length === 0) {
                calculated.push({
                    fee_entry_type: brand<FeeEntryType>(9000),
                    description: "Fix Manually - No Fees from Calculation",
                    units: "1",
                    payment_per_unit: "0",
                    service_start_time: getMonthStartDate(this.yearMonth),
                    service_end_time: getMonthEndDate(this.yearMonth),
                });
            }
            journalRecords.push(...calculated);
        } else if (isCabinCrew(person)) {
            const iataToIana = await getIataToIana();
            journalRecords.push(...calculateFeesForCabinCrewPerson(params, iataToIana));
        } else if (isMtx(person)) {
            const sheetData = await api.Invoice.GetManualInvoiceByNavLocator({
                year: this.state.year,
                company: this.state.COMPANYNAME,
                month: this.state.month,
                employeeCode: employeeCode,
            });
            if (sheetData && sheetData.CalculatedJournalRecords) {
                journalRecords.push(...sheetData.CalculatedJournalRecords);
            } else {
                journalRecords.push(...fixManually(params, "Work Report not Submitted"));
            }
        } else {
            journalRecords.push(...fixManually(params, "Unsupported department: " + person.navEntry.GlobalDimension1Code));
        }
        return journalRecords.map(jr => ({ ...jr, ...personification }));
    }

    private async insertJournalRecordsToNav() {
        this.setState({ loading: true });
        try {
            await api.Invoice.insert_salary_journal_records({
                company: this.state.COMPANYNAME,
                records: this.sortedCalculatedPeople
                    .filter(cp => isCabinCrew(cp.person))
                    .flatMap(cp => cp.journalRecords)
                    .map((r, i) => ({ ...r, id: (i + 1) * 1000, batch_name: "ROST_CCM" })),
            });
            await api.Invoice.insert_salary_journal_records({
                company: this.state.COMPANYNAME,
                records: this.sortedCalculatedPeople
                    .filter(cp => isFlightDeck(cp.person))
                    .flatMap(cp => cp.journalRecords)
                    .map((r, i) => ({ ...r, id: (i + 1) * 1000, batch_name: "ROST_FD" })),
            });
            // MTX salary journal entries are inserted on the moment of .xlsx submission
            // await api.Invoice.insert_salary_journal_records({
            //     company: this.state.COMPANYNAME,
            //     records: this.sortedCalculatedPeople
            //         .filter(cp => isMtx(cp.person))
            //         .flatMap(cp => cp.journalRecords)
            //         .map((r, i) => ({ ...r, id: (i + 1) * 1000, batch_name: "ROST_MTX" })),
            // });
        } finally {
            this.setState({ loading: false });
        }
    }

    private get sortedCalculatedPeople() {
        const filtered = this.state.calculatedPeople.filter(cp => {
            if (!this.state.includeCabinCrew && isCabinCrew(cp.person)) {
                return false;
            }
            if (!this.state.includeFlightDeck && isFlightDeck(cp.person)) {
                return false;
            }
            if (!this.state.includeMtx && isMtx(cp.person)) {
                return false;
            }
            return true;
        });
        return filtered.sort((a,b) => {
            const aFix = a.journalRecords.find(jr => jr.fee_entry_type === 9000);
            const bFix = b.journalRecords.find(jr => jr.fee_entry_type === 9000);
            if (aFix && bFix) {
                // put "Fix Manually" records without extra units lines in the beginning
                if (a.journalRecords.length === 1 && b.journalRecords.length > 1) {
                    return -1;
                } else if (b.journalRecords.length === 1 && a.journalRecords.length > 1) {
                    return 1;
                } else {
                    return strcmp(aFix.description, bFix.description);
                }
            } else if (aFix) {
                return -1;
            } else if (bFix) {
                return 1;
            }
            const aHasZeros = a.journalRecords.some(jr => +jr.units === 0 || +jr.payment_per_unit === 0);
            const bHasZeros = b.journalRecords.some(jr => +jr.units === 0 || +jr.payment_per_unit === 0);
            if (aHasZeros && !bHasZeros) {
                return -1;
            } else if (bHasZeros && !aHasZeros) {
                return 1;
            }
            const aNotes = getOfficerNotesText(this.state.officerFlightDeckFeesMapping ?? {}, a.person);
            const bNotes = getOfficerNotesText(this.state.officerFlightDeckFeesMapping ?? {}, b.person);
            if (aNotes && !bNotes) {
                return -1;
            } else if (bNotes && !aNotes) {
                return 1;
            }
            return 0;
        });
    }

    render() {
        return <div>
            <h1>ROST_FD/ROST_CCM Salary Journal batch generated from Air Atlanta Roster API</h1>
            <p>On this page you can re-calculate Salary Journal from current roster</p>
            <header>
                <div className="status-data-row">
                    <span>{this.state.COMPANYNAME}</span>
                    <span>Roster date: {this.state.rosterRetrievalTime}</span>
                    {!this.state.forceLatestRoster && <a href={this.latestRosterLink.href}><button>Latest Roster</button></a>}
                </div>
                <div className="action-buttons">
                    <span>{this.state.peopleInitiated} / {this.state.totalPeople ?? "?"}</span>
                    <button className="submit-to-nav" disabled={this.state.loading} onClick={() => this.insertJournalRecordsToNav()}>Submit ROST_FD/CCM to NAV</button>
                </div>
                <div className="filters-row">
                    <label>
                        <span>Flight Deck</span>
                        <input type="checkbox" checked={this.state.includeFlightDeck} onChange={e => this.setState({ includeFlightDeck: e.target.checked })}/>
                    </label>
                    <label>
                        <span>Cabin Crew</span>
                        <input type="checkbox" checked={this.state.includeCabinCrew} onChange={e => this.setState({ includeCabinCrew: e.target.checked })}/>
                    </label>
                    <label>
                        <span>MTX</span>
                        <input type="checkbox" checked={this.state.includeMtx} onChange={e => this.setState({ includeMtx: e.target.checked })}/>
                    </label>
                </div>
            </header>
            <NavJournalRecordsTable
                calculatedPeople={this.sortedCalculatedPeople}
                codeToNavInvoiceItems={this.state.codeToNavInvoiceItems}
                codeToOfficerNotes={this.state.officerFlightDeckFeesMapping ?? {}}
                makeLink={jr => "/Home/FeeStatementInvoiceConfirmation?" + new URLSearchParams({
                    company: this.invoicedInAirborne ? "Airborne - Malta" : this.state.COMPANYNAME,
                    year: String(this.yearMonth.year),
                    month: String(this.yearMonth.month),
                    employeeCode: jr.crew_code,
                })}
            />
        </div>;
    }
}
