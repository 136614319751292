import { rowsToObjects } from "../../../utils/dataHelpers";

/** this mapping is not static - should switch to ConfigurationData_GetReferenceActivities in future */
const referenceActivities = rowsToObjects(
    [ "UniqueID", "Name"                                              , "ShortCode" , "WholeDay" , "Active" , "DataType"       , "Type"           , "DutyType" , "TimeMode"   , "ReferenceGroup"            , "DefaultStartTime" , "DefaultEndTime" , "DefaultLength"  ],
    [          2, "AOG"                                               , "AOG"       , false      , true     , "Vehicle"        , "Unknown"        , "None"     , "UTC"        , "Maintenance"               , -2147483648        , -2147483648      , -2147483648      ],
    [          4, "Deadhead Other Airline"                            , "DH"        , false      , false    , "Human Resource" , "Transport"      , "Duty"     , "UTC"        , "Air Positioning"           , -2147483648        , -2147483648      , -2147483648      ],
    [          5, "AAI Ground Transport (Duty)"                       , "GTD"       , false      , true     , "Human Resource" , "Transport"      , "Duty"     , "UTC"        , "Ground Positioning"        , -2147483648        , -2147483648      , -2147483648      ],
    [          6, "B747-400 SIM"                                      , "S74"       , false      , true     , "Human Resource" , "Simulator"      , "Duty"     , "UTC"        , "Simulator"                 , -2147483648        , -2147483648      ,             390  ],
    [          8, "Illness"                                           , "ILL"       , false      , true     , "Human Resource" , "Illness"        , "None"     , "UTC"        , "Illness"                   , -2147483648        , -2147483648      , -2147483648      ],
    [          9, "Training"                                          , "TRN"       , false      , false    , "Human Resource" , "Unknown"        , "Duty"     , "UTC"        , "Training"                  ,                540 ,             1020 , -2147483648      ],
    [         10, "OPC Check"                                         , "OPC"       , false      , false    , "Human Resource" , "Simulator"      , "Duty"     , "UTC"        , "Simulator"                 , -2147483648        , -2147483648      ,             240  ],
    [         11, "Office Day"                                        , "OFC"       , false      , false    , "Human Resource" , "Unknown"        , "Duty"     , "Local Time" , "Other"                     ,                540 ,             1020 , -2147483648      ],
    [         12, "Hotel"                                             , "HTL"       , false      , true     , "Human Resource" , "Hotel"          , "None"     , "Local Time" , "Hotel"                     , -2147483648        , -2147483648      , -2147483648      ],
    [         13, "Working on Day Off"                                , "WOFF"      , false      , false    , "Human Resource" , "Compensation"   , "None"     , "UTC"        , "Other"                     ,                 60 ,              120 , -2147483648      ],
    [         14, "Standby Afternoon 15-23"                           , "SBA"       , false      , true     , "Human Resource" , "StandBy"        , "Duty"     , "Local Time" , "Standby"                   ,                900 ,             1380 , -2147483648      ],
    [         15, "Ground School"                                     , "GRD"       , false      , false    , "Human Resource" , "Training"       , "Duty"     , "Local Time" , "Training"                  ,                540 ,             1020 , -2147483648      ],
    [         17, "Unfit For Flight"                                  , "UFF"       , false      , false    , "Human Resource" , "Illness"        , "None"     , "Local Time" , "Illness"                   , -2147483648        , -2147483648      , -2147483648      ],
    [         18, "Spare Aircraft"                                    , "SPARE"     , false      , false    , "Vehicle"        , "Unknown"        , "None"     , "UTC"        , "Non-flying items"          , -2147483648        , -2147483648      , -2147483648      ],
    [         19, "Maintenance"                                       , "MX"        , false      , true     , "Vehicle"        , "Unknown"        , "None"     , "UTC"        , "Maintenance"               , -2147483648        , -2147483648      , -2147483648      ],
    [         20, "Vacation Paid"                                     , "VAC"       , true       , true     , "Human Resource" , "Vacation"       , "None"     , "UTC"        , "Vacation/Leave"            , -2147483648        , -2147483648      ,            1440  ],
    [         21, "Duty"                                              , "DUT"       , false      , true     , "Human Resource" , "Shift"          , "Duty"     , "UTC"        , "Duties"                    , -2147483648        , -2147483648      , -2147483648      ],
    [         22, "Longterm Leave"                                    , "LLV"       , true       , true     , "Human Resource" , "Vacation"       , "None"     , "UTC"        , "Vacation/Leave"            , -2147483648        , -2147483648      , -2147483648      ],
    [         25, "Unknown"                                           , "OTH"       , false      , false    , "Unknown"        , "Unknown"        , "None"     , "UTC"        , null                        , -2147483648        , -2147483648      , -2147483648      ],
    [         26, "Unknown"                                           , "UNK"       , false      , false    , "Human Resource" , "Unknown"        , "Duty"     , "UTC"        , "Other"                     , -2147483648        , -2147483648      , -2147483648      ],
    [         27, "Flight With FDP"                                   , "FLT"       , false      , true     , "Human Resource" , "Unknown"        , "Duty"     , "UTC"        , "Other"                     , -2147483648        , -2147483648      , -2147483648      ],
    [         28, "12 Hour Shift"                                     , "12h"       , false      , true     , "Human Resource" , "Shift"          , "Duty"     , "UTC"        , "Duties"                    , -2147483648        , -2147483648      ,             720  ],
    [         30, "AAI Ground Transport (Excl. Duty)"                 , "GT"        , false      , true     , "Human Resource" , "Transport"      , "None"     , "UTC"        , "Ground Positioning"        , -2147483648        , -2147483648      , -2147483648      ],
    [         31, "8 Hour Shift"                                      , "8h"        , false      , true     , "Human Resource" , "Shift"          , "Duty"     , "UTC"        , "Duties"                    , -2147483648        , -2147483648      ,             480  ],
    [         32, "Technical"                                         , "TEC"       , false      , true     , "Vehicle"        , "Unknown"        , "None"     , "UTC"        , "Maintenance"               , -2147483648        , -2147483648      , -2147483648      ],
    [         33, "Back in Service"                                   , "BIS"       , false      , true     , "Vehicle"        , "Unknown"        , "None"     , "UTC"        , "Maintenance"               , -2147483648        , -2147483648      ,              15  ],
    [         40, "A Check B777"                                      , "A77"       , false      , true     , "Vehicle"        , "Unknown"        , "None"     , "UTC"        , "Maintenance"               , -2147483648        , -2147483648      , -2147483648      ],
    [         41, "18M"                                               , "18M"       , false      , true     , "Vehicle"        , "Unknown"        , "None"     , "UTC"        , "Maintenance"               , -2147483648        , -2147483648      , -2147483648      ],
    [         42, "C Check"                                           , "C"         , false      , true     , "Vehicle"        , "Unknown"        , "None"     , "UTC"        , "Maintenance"               , -2147483648        , -2147483648      , -2147483648      ],
    [         43, "D Check"                                           , "D"         , false      , true     , "Vehicle"        , "Unknown"        , "None"     , "UTC"        , "Maintenance"               , -2147483648        , -2147483648      , -2147483648      ],
    [         44, "AD Note"                                           , "AD"        , false      , true     , "Vehicle"        , "Unknown"        , "None"     , "UTC"        , "Maintenance"               , -2147483648        , -2147483648      , -2147483648      ],
    [         45, "Borescope"                                         , "BSI"       , false      , true     , "Vehicle"        , "Unknown"        , "None"     , "UTC"        , "Maintenance"               , -2147483648        , -2147483648      ,             240  ],
    [         46, "Inspection"                                        , "INS"       , false      , true     , "Vehicle"        , "Unknown"        , "None"     , "UTC"        , "Maintenance"               , -2147483648        , -2147483648      ,             240  ],
    [         47, "Engine Change"                                     , "ENG"       , false      , true     , "Vehicle"        , "Unknown"        , "None"     , "UTC"        , "Maintenance"               , -2147483648        , -2147483648      ,             240  ],
    [         48, "Delayed Output"                                    , "DEL"       , false      , true     , "Vehicle"        , "Unknown"        , "None"     , "UTC"        , "Maintenance"               , -2147483648        , -2147483648      , -2147483648      ],
    [         49, "Internal Cleaning"                                 , "ICL"       , false      , true     , "Vehicle"        , "Unknown"        , "None"     , "UTC"        , "Maintenance"               , -2147483648        , -2147483648      ,             240  ],
    [         50, "External Cleaning"                                 , "ECL"       , false      , true     , "Vehicle"        , "Unknown"        , "None"     , "UTC"        , "Maintenance"               , -2147483648        , -2147483648      ,             240  ],
    [         51, "Acceptance Check"                                  , "ACC"       , false      , true     , "Vehicle"        , "Unknown"        , "None"     , "UTC"        , "Maintenance"               , -2147483648        , -2147483648      ,             240  ],
    [         52, "B777-200/300 SIM"                                  , "S77"       , false      , true     , "Human Resource" , "Simulator"      , "Duty"     , "UTC"        , "Simulator"                 , -2147483648        , -2147483648      ,             390  ],
    [         53, "Crew Resource Management"                          , "CRM"       , false      , true     , "Human Resource" , "Training"       , "Duty"     , "UTC"        , "Training"                  , -2147483648        , -2147483648      ,             300  ],
    [         54, "Emergency Safety Triennial B747"                   , "ET4"       , false      , true     , "Human Resource" , "Training"       , "Duty"     , "UTC"        , "Training"                  , -2147483648        , -2147483648      ,             255  ],
    [         55, "Emergency Safety Triennial B777"                   , "ET7"       , false      , true     , "Human Resource" , "Training"       , "Duty"     , "UTC"        , "Training"                  , -2147483648        , -2147483648      ,             255  ],
    [         56, "Course"                                            , "COU"       , false      , true     , "Human Resource" , "Training"       , "Duty"     , "UTC"        , "Training"                  , -2147483648        , -2147483648      , -2147483648      ],
    [         57, "CPAT / COMPLY - Computer Based Training"           , "CBT"       , false      , true     , "Human Resource" , "Training"       , "Duty"     , "UTC"        , "Training"                  , -2147483648        , -2147483648      ,             480  ],
    [         58, "Standardisation"                                   , "STI"       , false      , true     , "Human Resource" , "Training"       , "Duty"     , "UTC"        , "Training"                  , -2147483648        , -2147483648      , -2147483648      ],
    [         59, "Dangerous Goods"                                   , "DGR"       , false      , true     , "Human Resource" , "Training"       , "Duty"     , "UTC"        , "Training"                  , -2147483648        , -2147483648      , -2147483648      ],
    [         60, "AAE Conversion Course"                             , "AAE"       , false      , true     , "Human Resource" , "Training"       , "Duty"     , "UTC"        , "Training"                  , -2147483648        , -2147483648      ,              60  ],
    [         61, "First Aid"                                         , "AID"       , false      , true     , "Human Resource" , "Training"       , "Duty"     , "UTC"        , "Training"                  , -2147483648        , -2147483648      , -2147483648      ],
    [         62, "Safety Management System"                          , "SMS"       , false      , true     , "Human Resource" , "Training"       , "Duty"     , "UTC"        , "Training"                  , -2147483648        , -2147483648      , -2147483648      ],
    [         63, "Aeroplane visit B777"                              , "AV7"       , false      , true     , "Human Resource" , "Training"       , "Duty"     , "UTC"        , "Cabin Training"            , -2147483648        , -2147483648      , -2147483648      ],
    [         64, "Aeroplane visit B747"                              , "AV4"       , false      , true     , "Human Resource" , "Training"       , "Duty"     , "UTC"        , "Cabin Training"            , -2147483648        , -2147483648      , -2147483648      ],
    [         65, "Fire and Smoke"                                    , "FAS"       , false      , true     , "Human Resource" , "Training"       , "Duty"     , "UTC"        , "Cabin Training"            , -2147483648        , -2147483648      , -2147483648      ],
    [         66, "Conversion B777"                                   , "CT7"       , false      , true     , "Human Resource" , "Training"       , "Duty"     , "UTC"        , "Cabin Training"            , -2147483648        , -2147483648      , -2147483648      ],
    [         67, "Conversion B747"                                   , "CT4"       , false      , true     , "Human Resource" , "Training"       , "Duty"     , "UTC"        , "Cabin Training"            , -2147483648        , -2147483648      , -2147483648      ],
    [         68, "Recurrent General"                                 , "RAN"       , false      , true     , "Human Resource" , "Training"       , "Duty"     , "UTC"        , "Cabin Training"            , -2147483648        , -2147483648      , -2147483648      ],
    [         69, "Uniform Fitting"                                   , "UFI"       , false      , true     , "Human Resource" , "Training"       , "Duty"     , "UTC"        , "Cabin Training"            , -2147483648        , -2147483648      , -2147483648      ],
    [         70, "Initial Training General"                          , "ITG"       , false      , true     , "Human Resource" , "Training"       , "Duty"     , "UTC"        , "Cabin Training"            , -2147483648        , -2147483648      , -2147483648      ],
    [         71, "Refresher Training B777"                           , "RT7"       , false      , true     , "Human Resource" , "Training"       , "Duty"     , "UTC"        , "Cabin Training"            , -2147483648        , -2147483648      , -2147483648      ],
    [         72, "Refresher Training B747"                           , "RT4"       , false      , true     , "Human Resource" , "Training"       , "Duty"     , "UTC"        , "Cabin Training"            , -2147483648        , -2147483648      , -2147483648      ],
    [         73, "Service Training"                                  , "SRT"       , false      , true     , "Human Resource" , "Training"       , "Duty"     , "UTC"        , "Cabin Training"            , -2147483648        , -2147483648      , -2147483648      ],
    [         74, "Security Awereness Course"                         , "SEC"       , false      , true     , "Human Resource" , "Training"       , "Duty"     , "UTC"        , "Cabin Training"            , -2147483648        , -2147483648      , -2147483648      ],
    [         75, "Water Survival Training"                           , "WET"       , false      , true     , "Human Resource" , "Training"       , "Duty"     , "UTC"        , "Cabin Training"            , -2147483648        , -2147483648      , -2147483648      ],
    [         76, "Medical Aspects/First Aid"                         , "FAT"       , false      , true     , "Human Resource" , "Training"       , "Duty"     , "UTC"        , "Cabin Training"            , -2147483648        , -2147483648      , -2147483648      ],
    [         77, "Senior Cabin Training"                             , "STC"       , false      , true     , "Human Resource" , "Training"       , "Duty"     , "UTC"        , "Senior Cabin Training"     , -2147483648        , -2147483648      , -2147483648      ],
    [         78, "Recurrent Senior Training"                         , "STR"       , false      , true     , "Human Resource" , "Training"       , "Duty"     , "UTC"        , "Senior Cabin Training"     , -2147483648        , -2147483648      , -2147483648      ],
    [         79, "CRM Trainer Initial"                               , "CTI"       , false      , true     , "Human Resource" , "Training"       , "Duty"     , "UTC"        , "Cabin Instructor Training" , -2147483648        , -2147483648      , -2147483648      ],
    [         80, "Cabin Line Trainer"                                , "CLT"       , false      , true     , "Human Resource" , "Training"       , "Duty"     , "UTC"        , "Cabin Instructor Training" , -2147483648        , -2147483648      , -2147483648      ],
    [         81, "CRM Trainer Refresher"                             , "CTR"       , false      , true     , "Human Resource" , "Training"       , "Duty"     , "UTC"        , "Cabin Instructor Training" , -2147483648        , -2147483648      , -2147483648      ],
    [         82, "Instructional Techniques"                          , "TTT"       , false      , true     , "Human Resource" , "Training"       , "Duty"     , "UTC"        , "Cabin Instructor Training" , -2147483648        , -2147483648      , -2147483648      ],
    [         83, "TTT First Aid"                                     , "TTF"       , false      , true     , "Human Resource" , "Training"       , "Duty"     , "UTC"        , "Cabin Instructor Training" , -2147483648        , -2147483648      , -2147483648      ],
    [         84, "TTT Dangerous Goods"                               , "TTD"       , false      , true     , "Human Resource" , "Training"       , "Duty"     , "UTC"        , "Cabin Instructor Training" , -2147483648        , -2147483648      , -2147483648      ],
    [         85, "TTT Fire and Smoke"                                , "TFS"       , false      , true     , "Human Resource" , "Training"       , "Duty"     , "UTC"        , "Cabin Instructor Training" , -2147483648        , -2147483648      , -2147483648      ],
    [         86, "TTT Security"                                      , "TTS"       , false      , true     , "Human Resource" , "Training"       , "Duty"     , "UTC"        , "Cabin Instructor Training" , -2147483648        , -2147483648      , -2147483648      ],
    [         87, "21/14 Rotation"                                    , "R14"       , true       , true     , "Human Resource" , "Day off"        , "None"     , "UTC"        , "Rotations"                 ,                  0 , -2147483648      ,            1440  ],
    [         88, "24/12 Rotation"                                    , "R12"       , true       , true     , "Human Resource" , "Day off"        , "None"     , "UTC"        , "Rotations"                 ,                  0 , -2147483648      ,            1440  ],
    [         89, "Rotation Cabin Crew"                               , "RCC"       , true       , true     , "Human Resource" , "Day off"        , "None"     , "UTC"        , "Rotations"                 ,                  0 , -2147483648      ,            1440  ],
    [         90, "VAC Day Paid Back"                                 , "VPB"       , true       , true     , "Human Resource" , "Vacation"       , "None"     , "UTC"        , "Vacation/Leave"            , -2147483648        , -2147483648      ,            1440  ],
    [         91, "VAC Over 60"                                       , "O60"       , true       , true     , "Human Resource" , "Vacation"       , "None"     , "UTC"        , "Vacation/Leave"            , -2147483648        , -2147483648      ,            1440  ],
    [         92, "Vacation Unpaid"                                   , "VAU"       , true       , true     , "Human Resource" , "Vacation"       , "None"     , "UTC"        , "Vacation/Leave"            , -2147483648        , -2147483648      ,            1440  ],
    [         93, "Unapproved Vacation Request"                       , "UVR"       , true       , true     , "Human Resource" , "Vacation"       , "None"     , "UTC"        , "Vacation/Leave"            , -2147483648        , -2147483648      ,            1440  ],
    [         94, "Unpaid Leave"                                      , "ULV"       , true       , true     , "Human Resource" , "Vacation"       , "None"     , "UTC"        , "Vacation/Leave"            , -2147483648        , -2147483648      , -2147483648      ],
    [         95, "Parental Leave"                                    , "PTL"       , true       , true     , "Human Resource" , "Vacation"       , "None"     , "UTC"        , "Vacation/Leave"            , -2147483648        , -2147483648      , -2147483648      ],
    [         96, "Emergency Leave"                                   , "EML"       , true       , true     , "Human Resource" , "Vacation"       , "None"     , "UTC"        , "Vacation/Leave"            , -2147483648        , -2147483648      , -2147483648      ],
    [         97, "On Pay Off Per Diem"                               , "PXP"       , true       , true     , "Human Resource" , "Day off"        , "None"     , "UTC"        , "Days"                      , -2147483648        , -2147483648      ,             240  ],
    [         98, "Day for Day"                                       , "DFD"       , false      , true     , "Human Resource" , "Unknown"        , "None"     , "UTC"        , "Days"                      , -2147483648        , -2147483648      ,             120  ],
    [         99, "Flex Day"                                          , "FLX"       , false      , true     , "Human Resource" , "Unknown"        , "None"     , "UTC"        , "Days"                      , -2147483648        , -2147483648      ,             120  ],
    [        100, "Flex Day Paid Back"                                , "FPB"       , true       , true     , "Human Resource" , "Day off"        , "None"     , "UTC"        , "Days"                      , -2147483648        , -2147483648      , -2147483648      ],
    [        101, "Bought Day"                                        , "BOD"       , false      , true     , "Human Resource" , "Unknown"        , "None"     , "UTC"        , "Days"                      , -2147483648        , -2147483648      ,             120  ],
    [        102, "2 For 1"                                           , "2F1"       , false      , true     , "Human Resource" , "Unknown"        , "None"     , "UTC"        , "Days"                      , -2147483648        , -2147483648      ,             120  ],
    [        103, "End Of Contract"                                   , "EOC"       , true       , true     , "Human Resource" , "Day off"        , "None"     , "UTC"        , "Days"                      , -2147483648        , -2147483648      , -2147483648      ],
    [        104, "Fatique"                                           , "FTG"       , false      , true     , "Human Resource" , "Illness"        , "None"     , "UTC"        , "Illness"                   , -2147483648        , -2147483648      , -2147483648      ],
    [        105, "Illness On VAC"                                    , "ILV"       , true       , true     , "Human Resource" , "Illness"        , "None"     , "UTC"        , "Illness"                   , -2147483648        , -2147483648      , -2147483648      ],
    [        106, "Illness Sick Child"                                , "ILC"       , true       , true     , "Human Resource" , "Illness"        , "None"     , "UTC"        , "Illness"                   , -2147483648        , -2147483648      , -2147483648      ],
    [        109, "Commercial Flight Duty"                            , "CFD"       , false      , true     , "Human Resource" , "Transport"      , "Duty"     , "UTC"        , "Air Positioning"           , -2147483648        , -2147483648      , -2147483648      ],
    [        110, "Extra Contribution Days"                           , "ECD"       , false      , true     , "Human Resource" , "Unknown"        , "None"     , "UTC"        , "Days"                      , -2147483648        , -2147483648      ,             120  ],
    [        111, "Delayed Reporting"                                 , "DLRP"      , false      , true     , "Human Resource" , "Unknown"        , "None"     , "UTC"        , "Non-flying items"          , -2147483648        , -2147483648      , -2147483648      ],
    [        112, "Hotel SV"                                          , "HSV"       , false      , true     , "Human Resource" , "Hotel"          , "None"     , "Local Time" , "Hotel"                     , -2147483648        , -2147483648      , -2147483648      ],
    [        117, "Apartments"                                        , "APP"       , false      , true     , "Human Resource" , "Hotel"          , "None"     , "Local Time" , "Hotel"                     , -2147483648        , -2147483648      , -2147483648      ],
    [        118, "Hotel Magma"                                       , "HTM"       , false      , true     , "Human Resource" , "Hotel"          , "None"     , "Local Time" , "Hotel"                     , -2147483648        , -2147483648      , -2147483648      ],
    [        119, "Hotel Network"                                     , "HTN"       , false      , true     , "Human Resource" , "Hotel"          , "None"     , "Local Time" , "Hotel"                     , -2147483648        , -2147483648      , -2147483648      ],
    [        120, "Notes"                                             , "NTS"       , false      , true     , "Unknown"        , "Unknown"        , "None"     , "UTC"        , "Other"                     , -2147483648        , -2147483648      , -2147483648      ],
    [        121, "Requested Off Day"                                 , "ROF"       , true       , true     , "Human Resource" , "Day off"        , "None"     , "UTC"        , "Days"                      , -2147483648        , -2147483648      ,            1440  ],
    [        122, "Waiting for License "                              , "WFL"       , false      , true     , "Human Resource" , "Day off"        , "None"     , "UTC"        , "Days"                      , -2147483648        , -2147483648      ,            1440  ],
    [        123, "Day Off After Nightshift"                          , "OFN"       , false      , true     , "Human Resource" , "Day off"        , "None"     , "UTC"        , "Days"                      ,                480 ,                0 , -2147483648      ],
    [        124, "Shortened Workweek"                                , "SW"        , false      , true     , "Human Resource" , "Shift"          , "Duty"     , "UTC"        , "Duties"                    , -2147483648        , -2147483648      , -2147483648      ],
    [        125, "Day Off"                                           , "OFF"       , true       , true     , "Human Resource" , "Day off"        , "None"     , "Local Time" , "Days"                      ,                  0 , -2147483648      ,            1440  ],
    [        126, "CPAT / COMPLY - Computer Based Training Home Base" , "CBO"       , false      , true     , "Human Resource" , "Training"       , "Duty"     , "UTC"        , "Training"                  , -2147483648        , -2147483648      ,             480  ],
    [        127, "Legal Exemption Start"                             , "LES"       , false      , true     , "Unknown"        , "Unknown"        , "None"     , "UTC"        , "Other"                     , -2147483648        , -2147483648      , -2147483648      ],
    [        128, "Legal Exemption End"                               , "LEE"       , false      , true     , "Unknown"        , "Unknown"        , "None"     , "UTC"        , "Other"                     , -2147483648        , -2147483648      , -2147483648      ],
    [        129, "Winter Vacation"                                   , "WVAC"      , true       , true     , "Human Resource" , "Vacation"       , "None"     , "UTC"        , "Vacation/Leave"            , -2147483648        , -2147483648      ,            1440  ],
    [        131, "Standby Callout"                                   , "SCO"       , false      , true     , "Human Resource" , "StandBy"        , "Duty"     , "Local Time" , "Standby"                   , -2147483648        , -2147483648      , -2147483648      ],
    [        132, "Positioning Own Time"                              , "POT"       , false      , true     , "Human Resource" , "Transport"      , "Duty"     , "UTC"        , "Ground Positioning"        , -2147483648        , -2147483648      , -2147483648      ],
    [        133, "Standby Day 07-15"                                 , "SBD"       , false      , true     , "Human Resource" , "StandBy"        , "Duty"     , "Local Time" , "Standby"                   ,                420 ,              900 , -2147483648      ],
    [        134, "Standby Night 23-15"                               , "SBN"       , false      , true     , "Human Resource" , "StandBy"        , "Duty"     , "Local Time" , "Standby"                   ,               1380 ,              900 , -2147483648      ],
    [        135, "Airport standby"                                   , "ASBY"      , false      , true     , "Human Resource" , "StationStandBy" , "Duty"     , "Local Time" , "Standby"                   , -2147483648        , -2147483648      , -2147483648      ],
    [        136, "Fatigue Risk Management"                           , "FRM"       , false      , true     , "Human Resource" , "Training"       , "Duty"     , "UTC"        , "Training"                  , -2147483648        , -2147483648      ,             480  ],
    [        137, "Evacuation Slide Training B747"                    , "CTS"       , false      , true     , "Human Resource" , "Training"       , "Duty"     , "UTC"        , "Training"                  , -2147483648        , -2147483648      , -2147483648      ],
    [        138, "Evacuation Slide Training B777"                    , "CTS7"      , false      , true     , "Human Resource" , "Training"       , "Duty"     , "UTC"        , "Training"                  , -2147483648        , -2147483648      , -2147483648      ],
    [        139, "Doors and Exits B747"                              , "DAE"       , false      , true     , "Human Resource" , "Training"       , "Duty"     , "UTC"        , "Training"                  , -2147483648        , -2147483648      , -2147483648      ],
    [        140, "Doors and Exits B777"                              , "DAE7"      , false      , true     , "Human Resource" , "Training"       , "Duty"     , "UTC"        , "Training"                  , -2147483648        , -2147483648      , -2147483648      ],
    [        141, "Conversion General"                                , "CTG"       , false      , true     , "Human Resource" , "Training"       , "Duty"     , "UTC"        , "Cabin Training"            , -2147483648        , -2147483648      , -2147483648      ],
    [        142, "Classroom B747"                                    , "CL4"       , false      , true     , "Human Resource" , "Training"       , "None"     , "UTC"        , "Training"                  ,                480 ,              960 , -2147483648      ],
    [        143, "Classroom B777"                                    , "CL7"       , false      , true     , "Human Resource" , "Training"       , "None"     , "UTC"        , "Training"                  ,                480 ,              960 , -2147483648      ],
    [        144, "Compound"                                          , "COM"       , false      , true     , "Human Resource" , "Hotel"          , "None"     , "Local Time" , "Hotel"                     , -2147483648        , -2147483648      , -2147483648      ],
    [        145, "Saudi Visa Renewal "                               , "SVV"       , true       , true     , "Human Resource" , "Day off"        , "None"     , "Local Time" , "Days"                      , -2147483648        , -2147483648      ,             480  ],
    [        146, "Emergency Annual B777 AAE"                         , "EEA7"      , false      , true     , "Human Resource" , "Training"       , "Duty"     , "UTC"        , "Training"                  , -2147483648        , -2147483648      ,             180  ],
    [        147, "Emergency Annual B747 "                            , "EA4"       , false      , true     , "Human Resource" , "Training"       , "Duty"     , "UTC"        , "Training"                  , -2147483648        , -2147483648      ,             180  ],
    [        148, "21/21 Rotation"                                    , "R21"       , true       , true     , "Human Resource" , "Day off"        , "None"     , "UTC"        , "Rotations"                 ,                  0 , -2147483648      ,            1440  ],
    [        149, "Azzam Compound"                                    , "AZZ"       , false      , true     , "Human Resource" , "Hotel"          , "None"     , "Local Time" , "Hotel"                     , -2147483648        , -2147483648      , -2147483648      ],
    [        150, "Aqua Compound"                                     , "AQU"       , false      , true     , "Human Resource" , "Hotel"          , "None"     , "Local Time" , "Hotel"                     , -2147483648        , -2147483648      , -2147483648      ],
    [        151, "Sharbatly Compound"                                , "SHV"       , false      , true     , "Human Resource" , "Hotel"          , "None"     , "Local Time" , "Hotel"                     , -2147483648        , -2147483648      , -2147483648      ],
    [        152, "Delayed Reporting First Call"                      , "DLRP1"     , false      , true     , "Human Resource" , "Unknown"        , "None"     , "UTC"        , "Non-flying items"          , -2147483648        , -2147483648      , -2147483648      ],
    [        153, "Weight and Balance"                                , "WBB"       , false      , true     , "Unknown"        , "Training"       , "Duty"     , "UTC"        , "Training"                  , -2147483648        , -2147483648      ,             240  ],
    [        155, "A Check B747"                                      , "A74"       , false      , true     , "Vehicle"        , "Unknown"        , "None"     , "UTC"        , "Maintenance"               , -2147483648        , -2147483648      , -2147483648      ],
    [        156, "Loyality Contract Rotation "                       , "RLO"       , true       , true     , "Unknown"        , "Day off"        , "None"     , "UTC"        , null                        , -2147483648        , -2147483648      , -2147483648      ],
    [        157, "Loadmaster duty"                                   , "LMD"       , false      , true     , "Human Resource" , "Unknown"        , "Duty"     , "UTC"        , "Other"                     , -2147483648        , -2147483648      , -2147483648      ],
    [        158, "Saudi Visa Extension"                              , "SVX"       , true       , true     , "Human Resource" , "Day off"        , "None"     , "Local Time" , "Days"                      ,                  0 , -2147483648      , -2147483648      ],
    [        159, "Hard Days"                                         , "HRD"       , false      , true     , "Human Resource" , "Unknown"        , "None"     , "UTC"        , null                        , -2147483648        , -2147483648      ,             120  ],
    [        160, "Performance"                                       , "PER"       , false      , true     , "Human Resource" , "Training"       , "Duty"     , "UTC"        , "Training"                  , -2147483648        , -2147483648      ,             180  ],
    [        161, "Magma Commercial Flight Tickets"                   , "MCF"       , false      , true     , "Human Resource" , "Transport"      , "Duty"     , "UTC"        , "Air Positioning"           , -2147483648        , -2147483648      , -2147483648      ],
    [        163, "Network commercial flight tickets"                 , "NCF"       , false      , true     , "Human Resource" , "Transport"      , "Duty"     , "UTC"        , "Air Positioning"           , -2147483648        , -2147483648      , -2147483648      ],
    [        165, "Saudia commercial flight tickets"                  , "SCF"       , false      , true     , "Human Resource" , "Transport"      , "Duty"     , "UTC"        , "Air Positioning"           , -2147483648        , -2147483648      , -2147483648      ],
    [        167, "Air Atlanta commercial flight tickets"             , "ACF"       , false      , true     , "Human Resource" , "Transport"      , "Duty"     , "UTC"        , "Air Positioning"           , -2147483648        , -2147483648      , -2147483648      ],
    [        169, "Train (Duty)"                                      , "RW"        , false      , true     , "Human Resource" , "Transport"      , "Duty"     , "UTC"        , "Ground Positioning"        , -2147483648        , -2147483648      , -2147483648      ],
    [        170, "Saudia Deadhead Flights "                          , "SDF"       , false      , true     , "Human Resource" , "Transport"      , "Duty"     , "UTC"        , "Air Positioning"           , -2147483648        , -2147483648      , -2147483648      ],
    [        171, "Magma Ground Transport (Duty)"                     , "MGTD"      , false      , true     , "Human Resource" , "Transport"      , "Duty"     , "UTC"        , "Ground Positioning"        , -2147483648        , -2147483648      , -2147483648      ],
    [        172, "Network Ground Transport (Duty)"                   , "NGTD"      , false      , true     , "Human Resource" , "Transport"      , "Duty"     , "UTC"        , "Ground Positioning"        , -2147483648        , -2147483648      , -2147483648      ],
    [        173, "Magma Ground Transport (excl. Duty)"               , "MGT"       , false      , true     , "Human Resource" , "Transport"      , "Duty"     , "UTC"        , "Ground Positioning"        , -2147483648        , -2147483648      , -2147483648      ],
    [        174, "Network Ground Transport (excl. Duty)"             , "NGT"       , false      , true     , "Human Resource" , "Transport"      , "Duty"     , "UTC"        , "Ground Positioning"        , -2147483648        , -2147483648      , -2147483648      ],
);

export type ReferenceActivity = (typeof referenceActivities)[number];

export type ReferenceActivityUniqueId = ReferenceActivity["UniqueID"];

const idToActivity: ReadonlyMap<number, ReferenceActivity> = new Map(
    referenceActivities.map(ra => [ra.UniqueID, ra])
);

export default idToActivity;