
import {loadCompaniesWise} from "../features/Company";
import {loadProfile} from "../features/Profile";

let React = require('react');
import {connect, Provider} from "react-redux";
import ReactModal from 'react-modal';
import {addNewDeadline, getAllDeadlineDays, updateDeadlines} from "../features/Invoice";

let _ = require('lodash');
import InvoiceMenu from '../components/InvoiceMenu';
import ErrorPage from '../views/ErrorPage';
import {getMonthFullName} from "../utils/dates";

const mapStateToProps = (state) => {
  return {
    profile: state.profile,
    company: state.company,
    invoice: state.invoice,
  };
}
const mapDispatchToProps = (dispatch) => {
  return {
    loadProfile: (payload) => dispatch(loadProfile(payload)),
    loadCompaniesWise: () => dispatch(loadCompaniesWise()),
    updateDeadlines: (payload) => dispatch(updateDeadlines(payload)),
    getAllDeadlineDays: (payload) => dispatch(getAllDeadlineDays(payload)),
    addNewDeadline: (payload) => dispatch(addNewDeadline(payload)),
  }
};
export const views = {
  Table: 0,
  Form: 1
}

class InvoicesDeadlineDays extends React.Component {
  //displayName: 'InvoicesDeadlineDays',

  constructor(props) {
    super(props);
    this.state = {
      nameOrCrewcode: "",
      madeFirstCall: false,
      editable: false,
      currentPage: 1,
      SelectedCompany: '',
      employeesPerPage: 10,
      isAdmin: this.props.isAdmin,
      newYear: new Date().getFullYear(),
      newMonth: new Date().getMonth(),
      newContractor: new Date().getDay(),
      newClosing: new Date().getDay(),
      showAddDeadlineModal: false,
      newApprover: new Date().getDay(),
      newTenth: new Date().getDay(),
      viewForm: views.Table,
      sortBy: {
        column: "",
        order: ""
      }
    };
  };

  componentDidMount() {
    this.props.loadCompaniesWise().unwrap()
      .then(() => {
        this.setState({loading: false});
      })
      .catch(() => {
      })

    const payload = {
      id: this.props.id,
      code: this.props.code
    };
    this.props.loadProfile(payload).unwrap()
      .then(() => {
        this.setState({loading: false});
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log("loadProfile rejected", rejectedValueOrSerializedError);
      })
      .then(() => {
        this.setState({loading: false});
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log("loadProfile rejected", rejectedValueOrSerializedError);
      })
    if (!this.state.madeFirstCall)
      this.setState({
        madeFirstCall: true,
        view: "All",
        ShowAdminsFields: "none"
      });
  };

  order(employees) {
    if (this.state.sortBy.column != "") {
      return _.orderBy(employees, [this.state.sortBy.column], [this.state.sortBy.order]);
    }

    return employees;
  };

  handleSort(e) {
    var headerId = e.target.id;

    this.setState({
      sortBy: {
        column: headerId.substr(2),
        order: this.state.sortBy.order == "desc" ? "asc" : "desc"
      }
    });

    _.forEach(e.target.parentElement.children, function (c) {
      c.className = "";
    });

    e.target.className = this.state.sortBy.order == "desc" ? "asc" : "desc";
  };

  _ChangeCompany(e) {
    if (!this.state.loading) {
      this.setState({
        viewNewFormName: e.target.value,
        SelectedCompany: e.target.value

      });
    }
    this._generateDeadlineDays(e.target.value);
  };

  createSelectItems() {
    let items = [];
    if (this.props.company.companiesWise != null) {

      items.push(<option key={-1} value="">Choose Client</option>);
      for (let i = 0; i < parseInt(this.props.company.companiesWise.length); i++) {
        let comp = this.props.company.companiesWise[i];
        items.push(<option key={comp.Code} value={comp.Id}>{comp.DisplayName}</option>);
      }
    }
    return items;
  };

  _generateCompanyList() {
    return (
      <div className="col-xs-9"
           style={{paddingLeft: "0", display: this.state.ShowAdminsFields == "table-cell" ? "none" : "table-cell"}}>
        <select onChange={(e) => this._ChangeCompany(e)} value={this.state.SelectedCompany}
                style={{fontSize: "20px", width: "100%"}}>
          {this.createSelectItems()}
        </select>
      </div>
    );
  };

  _generateDeadlineDays(companyId) {
    this.props.getAllDeadlineDays(companyId).unwrap()
      .then((result) => {
        this.setState({deadlinedays: result});
      })
      .catch(() => {
      })
  };

  _getHeader() {
    return (<div className="invoice-header">
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <h1>Deadline days</h1>
          </div>
        </div>
      </div>
    </div>);
  };

  _createInvoiceMenu() {
    return (<InvoiceMenu selected="Deadline"/>);
  };

  handlePageChange(pageNumber) {
    this.setState({currentPage: pageNumber});
  };

  _filterByNameCrewDeptcode(e) {
    this.setState({nameOrCrewcode: e.target.value});
  };

  generateAddDeadlineModal() {
    return (<div>
      <ReactModal isOpen={this.state.showAddDeadlineModal} contentLabel="Add Deadline Modal"
      style={{
        content: {
          background: 'auto',
          border: 'none'
        }
      }}>
        <div className='addDeadlineModalContainer'>
          <div className="addDeadlineModal">
            <div className='paragraphContainer'>
              <div className="innerInvoiceActionContainer">
                <div>
                  <div className="frontPageStatusInfo">New Deadline:</div>
                </div>
              </div>
            </div>
            <div className="newDeadlineAttribute">
              <label className="col-sm-6 control-label">Year: </label>
              <div className="col-sm-6">
                <input type="number" onChange={(e) => this.changeNewYear(e)} value={this.state.newYear}
                       className="form-control"/>
              </div>
            </div>
            <div className="newDeadlineAttribute">
              <label className="col-sm-6 control-label">Month: </label>
              <div className="col-sm-6">
                <input type="number" onChange={(e) => this.changeNewMonth(e)} value={this.state.newMonth}
                       className="form-control"/>
              </div>
            </div>
            <div className="newDeadlineAttribute">
              <label className="col-sm-6 control-label">Contractor: </label>
              <div className="col-sm-6">
                <input type="number" onChange={(e) => this.changeContractor(e)} value={this.state.newContractor}
                       className="form-control"/>
              </div>
            </div>
            <div className="newDeadlineAttribute">
              <label className="col-sm-6 control-label">Contractor portal closing date (not published): </label>
              <div className="col-sm-6">
                <input type="number" onChange={(e) => this.changePortal(e)} value={this.state.newClosing}
                       className="form-control"/>
              </div>
            </div>
            <div className="newDeadlineAttribute">
              <label className="col-sm-6 control-label">Approver: </label>
              <div className="col-sm-6">
                <input type="number" onChange={(e) => this.changeApprover(e)} value={this.state.newApprover}
                       className="form-control"/>
              </div>
            </div>
            <div className="newDeadlineAttribute">
              <label className="col-sm-6 control-label">10th working day: </label>
              <div className="col-sm-6">
                <input type="number" onChange={(e) => this.changeTenth(e)} value={this.state.newTenth}
                       className="form-control"/>
              </div>
            </div>
            <div>
              <button className="btn btn-warning newDeadlineButton"
                      onClick={() => {
                        this.onCancelExplainModal();
                      }}>
                Cancel
              </button>

              <button className="btn btn-primary newDeadlineButton"
                      onClick={() => {
                        this.addNewDeadline();
                      }}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
    </div>)
  };

  changeNewYear(e) {
    this.setState({newYear: parseInt(e.target.value)})
  };

  changeNewMonth(e) {
    this.setState({newMonth: parseInt(e.target.value)})
  };

  changeContractor(e) {
    this.setState({newContractor: parseInt(e.target.value)})
  };

  changePortal(e) {
    this.setState({newClosing: parseInt(e.target.value)})
  };

  changeApprover(e) {
    this.setState({newApprover: parseInt(e.target.value)})
  };

  changeTenth(e) {
    this.setState({newTenth: parseInt(e.target.value)})
  };

  onCancelExplainModal() {
    this.setState({
      showAddDeadlineModal: false,
    });
    this.setState({
      CommentText: ""
    });
  };

  addNewDeadline() {
    let newDeadline = {
      Year: this.state.newYear,
      Month: this.state.newMonth,
      Day: this.state.newContractor,
      ExtraDays: this.state.newClosing,
      SixthWorkingDay: this.state.newApprover,
      TenthWorkingDay: this.state.newTenth,
      CompanyId: this.state.SelectedCompany,
    }

    this.props.addNewDeadline(newDeadline).unwrap()
      .then(() => {
        this._generateDeadlineDays(this.state.SelectedCompany);
        this.setState({showAddDeadlineModal: false});
      })
      .catch(() => {
      })
  };

  _generateFilters() {
    return (
      <div>
        <div className="col-xs-4 select search">
          <input type="text" placeholder="Search for client" value={this.state.nameOrCrewcode}
                 onChange={(e) => this._filterByNameCrewDeptcode(e)}/>
        </div>
      </div>
    );
  };

  hasAdminPrivileges() {
    var cleanIsAdmin = this.state.isAdmin === "True" || this.state.isAdmin === "true";
    return cleanIsAdmin;
  };

  editDeadlines() {
    this.setState({editable: true})
  };

  showDeadline() {
    this.setState({showAddDeadlineModal: true})
  };

  saveChanges() {
    this.setState({editable: false})
    this.props.updateDeadlines(this.state.deadlinedays).unwrap()
      .then(() => {
        this.setState({loading: false});
      })
  };

  cancelChanges() {
    this._generateDeadlineDays(this.state.SelectedCompany)
    this.setState({editable: false})
  };

  changeYear(e, id) {
    let newYear = e.target.value;
    let deadlineDays = _.cloneDeep(this.state.deadlinedays);
    deadlineDays = deadlineDays.map((day) => {
      if (day.ID === id) {
        day.Year = newYear;
      }
      return day;
    });
    this.setState({deadlinedays: deadlineDays, error: null});
  };

  changeMonth(e, id) {
    let newMonth = e.target.value;
    let deadlineDays = _.cloneDeep(this.state.deadlinedays);
    deadlineDays = deadlineDays.map((day) => {
      if (day.ID === id) {
        day.Month = newMonth;
      }
      return day;
    });
    this.setState({deadlinedays: deadlineDays, error: null});
  };

  changeDay(e, id) {
    let newDay = e.target.value;
    let deadlineDays = _.cloneDeep(this.state.deadlinedays);
    deadlineDays = deadlineDays.map((day) => {
      if (day.ID === id) {
        day.Day = newDay;
      }
      return day;
    });
    this.setState({deadlinedays: deadlineDays, error: null});
  };

  changeExtraDays(e, id) {
    let newExtraDays = e.target.value;

    let deadlineDays = _.cloneDeep(this.state.deadlinedays);
    deadlineDays = deadlineDays.map((day) => {
      if (day.ID === id) {
        day.ExtraDays = newExtraDays;
      }
      return day;
    });
    this.setState({deadlinedays: deadlineDays, error: null});
  };

  changeSixthWorkingDay(e, id) {
    let newSixthDay = e.target.value;
    let deadlineDays = _.cloneDeep(this.state.deadlinedays);
    deadlineDays = deadlineDays.map((day) => {
      if (day.ID === id) {
        day.SixthWorkingDay = newSixthDay;
      }
      return day;
    });
    this.setState({deadlinedays: deadlineDays, error: null});
  };

  changeTenthWorkingDay(e, id) {
    let newTenthDay = e.target.value;
    let deadlineDays = _.cloneDeep(this.state.deadlinedays);
    deadlineDays = deadlineDays.map((day) => {
      if (day.ID === id) {
        day.TenthWorkingDay = newTenthDay;
      }
      return day;
    });
    this.setState({deadlinedays: deadlineDays, error: null});
  };

  render() {

    let companiesWise = this.props.company.companiesWise != null && this.props.company.companiesWise.length > 0 ? this.order(this.props.company.companiesWise) : [];
    companiesWise = _.filter(companiesWise, d => d.Name.toUpperCase().includes(this.state.nameOrCrewcode.toUpperCase()));

    let indexOfLastEmployee = this.state.currentPage * this.state.employeesPerPage;
    let indexOfFirstEmployee = indexOfLastEmployee - this.state.employeesPerPage;
    companiesWise ? companiesWise.slice(indexOfFirstEmployee, indexOfLastEmployee) : null;
    let deadlinesetup = null;
    if (this.state.deadlinedays != undefined) {
      let com = this.state.deadlinedays;
      deadlinesetup = com ? com.map((e, index) => {
        return (<DeadlineDay changeMonth={this.changeMonth.bind(this)} changeDay={this.changeDay.bind(this)} changeYear={this.changeYear.bind(this)}
                             changeExtraDays={this.changeExtraDays.bind(this)} changeSixthWorkingDay={this.changeSixthWorkingDay.bind(this)}
                             changeTenthWorkingDay={this.changeTenthWorkingDay.bind(this)} editable={this.state.editable} day={e}
                             key={index}/>);
      }) : [];
    }
    let addDeadlineModal = this.generateAddDeadlineModal();
    if (!this.state.madeFirstCall) {
      return (<div className="invoice-wrapper admin-wrapper">
        {this._getHeader()}
        <div className="container">
          <div className="row">
            <div className="col-xs-12">
              <h1 className="loading">{!this.state.madeFirstCall ? "Loading list of Clients..." : "No Setup found"}</h1>
            </div>
          </div>
        </div>
      </div>);
    } else {

      if (!this.hasAdminPrivileges()) {
        return <ErrorPage/>
      }
      return (<div className="invoice-wrapper admin-wrapper">
          {this._getHeader()}
          <div className="container">
            <div className="row">
              <div className="col-xs-3 invocie-menu">
                {this._createInvoiceMenu()}
              </div>
              <div className="col-xs-9 Info">
                {/* <p>
              <span>When contractors belonging to these clients login for the first </span><br/>
              <span>time they will see the parts selected in this list. </span>
              </p> */}
              </div>
              <div className="col-xs-9">
                {/* {this._generateFilters()} */}
              </div>
              <div>
                {this._generateCompanyList()}
              </div>

              {this.state.SelectedCompany !== '' ?
                <div>
                  <div className="col-xs-9 deadlineContainer">
                    {this.state.editable ?
                      <div><a className="btn btn-warning" href="#" onClick={(e) => this.cancelChanges(e)}>Cancel</a> <a
                        className="btn btn-primary" href="#" onClick={(e) => this.saveChanges(e)}>Save</a></div> :
                      <a style={{display: "inline-block"}}
                         className="btn btn-primary" href="#" onClick={(e) => this.editDeadlines(e)}>Edit</a>}
                    <div className="table-responsive">
                      <table className="invoices invoice-months table table-striped table-condensed">
                        <thead>
                        <tr>
                          <th className="noSort" id="thYear">Year</th>
                          <th className="noSort" id="thMonth">Month</th>
                          <th className="noSort" id="thDay">Contractor</th>
                          <th className="noSort" id="thDay">Contractor portal closing date (not published)</th>
                          <th className="noSort" id="thDay">Approver</th>
                          <th className="noSort" id="thDay">10th working day</th>
                        </tr>
                        </thead>
                        <tbody id="deadlineTable">
                        {deadlinesetup}
                        </tbody>
                      </table>
                    </div>
                    {this.state.editable ? null :
                      <a style={{display: "inline-block"}}
                         className="btn btn-primary" href="#" onClick={(e) => this.showDeadline(e)}>Add deadline</a>}
                  </div>
                  <div className="row col-xs-12">
                    <div className="col-xs-3">
                    </div>
                    <div className="col-xs-9 text-right">

                    </div>
                  </div>
                </div> : <div className="chooseClientContainer"><h2>Please choose a client</h2></div>}
            </div>
          </div>
          {addDeadlineModal}
        </div>
      );
    }
  }
}

export const DeadlineDay = (props) => {
  let day = props.day;
  let nextMonth = day.Month;
  if (nextMonth == 12)
    nextMonth = 0;
  const monthName = getMonthFullName(nextMonth + 1);
  return (day !== null ?
    <tr>
      {props.editable ?
        <td><input type="text" onChange={(e) => {
          props.changeYear(e, day.ID)
        }} value={day.Year}/></td> :
        <td>{day.Year}</td>}
      {props.editable ? <td><input type="text" onChange={(e) => {
          props.changeMonth(e, day.ID)
        }} value={day.Month}/></td> :
        <td>{getMonthFullName(day.Month) + " (" + day.Month + ")"}</td>}
      {props.editable ? <td><input type="text" onChange={(e) => {
          props.changeDay(e, day.ID)
        }} value={day.Day}/>{monthName}</td> :
        <td>{day.Day + " " + monthName}</td>}
      {props.editable ? <td><input type="text" onChange={(e) => {
          props.changeExtraDays(e, day.ID)
        }} value={day.ExtraDays}/>{monthName}</td> :
        <td>{day.ExtraDays + " " + monthName}</td>}
      {props.editable ?
        <td><input type="text" onChange={(e) => {
          props.changeSixthWorkingDay(e, day.ID)
        }} value={day.SixthWorkingDay}/>{monthName}</td> :
        <td>{day.SixthWorkingDay + " " + monthName}</td>}
      {props.editable ?
        <td><input type="text" onChange={(e) => {
          props.changeTenthWorkingDay(e, day.ID)
        }} value={day.TenthWorkingDay}/>{monthName}</td> :
        <td>{day.TenthWorkingDay + " " + monthName}</td>}
    </tr> : null);
}

const wrapper = (props) => {
  return <Provider store={window.store}>{<InvoicesDeadlineDays {...props}/>}</Provider>;
}
module.exports = connect(
  mapStateToProps,
  mapDispatchToProps
)(wrapper);
