import type { JsonValue } from "../types/utility";
import { neverNull } from "./typing";

const extractFromByteArray = (byteArray: Uint8Array, first: boolean) => {
    const lines = new TextDecoder("utf-8").decode(byteArray).split("\n");
    const remainderStr = lines.pop(); // incomplete or ending "]"
    const items = [];
    for (const line of lines) {
        let itemStr;
        const wasFirst = first;
        if (first) {
            first = false;
            itemStr = line.replace(/^\s*\[\s*/, "");
        } else {
            itemStr = line.replace(/^\s*,\s*/, "");
        }
        if (itemStr.length === line.length) {
            throw new Error("Malformed JSON: missing [ or , at line " + wasFirst + " - " + JSON.stringify(line));
        }
        items.push(JSON.parse(itemStr));
    }
    const remainder = new TextEncoder().encode(remainderStr);
    return { items, remainder };
};

const extractCompleteItems = function*(parts: Uint8Array[], first: boolean): Iterable<JsonValue> {
    let prefix = new Uint8Array(0);
    let part;
    while (part = parts.shift()) {
        const joined = new Uint8Array(prefix.length + part.length);
        joined.set(prefix);
        joined.set(part, prefix.length);
        const { items, remainder } = extractFromByteArray(joined, first);
        for (const item of items) {
            first = false;
            yield item;
        }
        prefix = remainder;
    }
    if (prefix.length > 0) {
        parts.unshift(prefix);
    }
};

/**
 * works only for a particularly formatted JSON responses: an array with
 * line break after every element, comma and closing ] after the line break
 * [{"foo": "bar"}
 * ,{"foo": "baz"}
 * ,{"foo": "bam"}
 * ]
 * creates an Async iterator over the elements that can be used to continuously
 * process the results without first waiting for the whole response to load
 */
export default async function* JsonResponseAsyncIterator<
    T extends JsonValue = JsonValue
>(rs: Response): AsyncGenerator<T> {
    const reader = (rs.body ?? neverNull()).getReader();
    const parts: Uint8Array[] = [];
    let first = true;
    while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        parts.push(value);
        for (const item of extractCompleteItems(parts, first)) {
            first = false;
            yield item as T;
        }
    }
};
