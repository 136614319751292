import type { DimensionFields, NavCompanyData } from "../types/NavisionPayslip";
import { brand } from "./typing";
import type { IataAirport } from "../types/utility";

export default class {
    constructor(
        private readonly navCompanyData: { companySpecificFields: NavCompanyData["companySpecificFields"] },
        private readonly source: DimensionFields
    ) {
    }

    private getCompanySpecificValue(codename: "BASE" | "STARFSSTÖÐ" | "DEPARTMENT" | "DEILD" | "PROJECT" | "VERKEFNI" | "AIRCRAFT" | "FLUGVÉL") {
        const field = (this.navCompanyData.companySpecificFields ?? [])
            .find(f => f["Koti Viddar"] === codename);
        if (field) {
            const fieldName = ("Launa" + field.Code.toLowerCase()) as keyof DimensionFields;
            return this.source[fieldName];
        } else {
            return null;
        }
    }

    public get departmentCode() {
        // in Airborne - Malta company field names are in Icelandic
        return this.getCompanySpecificValue("DEPARTMENT")
            ?? this.getCompanySpecificValue("DEILD");
    }

    public get base() {
        const value = this.getCompanySpecificValue("BASE")
            ?? this.getCompanySpecificValue("STARFSSTÖÐ");
        if (value === "") {
            return value;
        } else if (!value) {
            return null;
        } else {
            return brand<IataAirport>(value);
        }
    }

    public get project() {
        return this.getCompanySpecificValue("PROJECT")
            ?? this.getCompanySpecificValue("VERKEFNI");
    }

    public get aircraft() {
        return this.getCompanySpecificValue("AIRCRAFT")
            ?? this.getCompanySpecificValue("FLUGVÉL");
    }
}