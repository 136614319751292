import { assertSubTree } from "./testingHelpers";
import { getRotationDays } from "../features/roster/air_atlanta/RotationDaysGrouper";
import { fetchPersonRoster } from "../features/roster/air_atlanta/FeeCalculatorUtils";
import { calculateFeesForFlightDeckPerson,getPrecedingUnterminatedRotationDays, getTerminatedRotationBlockHours } from "../features/roster/air_atlanta/FeeCalculatorFlightDeck";
import { brand, neverNull,toCrewCodeUc } from "../utils/typing";
import { FeeEntryType } from "../features/ExternalRostersApi";
import type { ImpersonalSalaryJournalRecord  } from "../features/ExternalRostersApi";
import { rowsToObjects } from "../utils/dataHelpers";
import { getIataToIana } from "../features/StaticData";
import type { IataAirport } from "../types/utility";
import type { OfficerFlightDeckFees } from "../types/Api/Contract";
import type { CrewCode } from "@mhc/utils/types/nav";
import type { CompanyName } from "../features/Api";
import api from "../features/Api";

function Input(year: number, month: number, No_: string, NearestIntAirport: string, officerFees: OfficerFlightDeckFees | null) {
    return {
        year,
        month,
        No_: toCrewCodeUc(brand<CrewCode>(No_)),
        NearestIntAirport: brand<IataAirport>(NearestIntAirport),
        officerFees,
    } as const;
}

const DAILY_FEE = FeeEntryType(200);
const PER_DIEM = FeeEntryType(500);
const OVER_TIME_FD = FeeEntryType(300);
const INSTRUCTOR_FEE = FeeEntryType(670);
const PHONE_AND_INTERNET_ALLOWANCE = FeeEntryType(4005);

type TestCase = {
    title: string,
    input: ReturnType<typeof Input>,
    output: Partial<ImpersonalSalaryJournalRecord>[],
};

const WWCPTINS_12 = { dailyFee: "501", rotationType: "WW", instructorFee: "900", telecommunicationsAllowance: "20" } as const;
const INS2114516 = { dailyFee: "516", rotationType: "21/14", instructorFee: "900", telecommunicationsAllowance: "20" } as const;
const INS2114541 = { dailyFee: "541", rotationType: "21/14", instructorFee: "900", telecommunicationsAllowance: "20" } as const;
const FOAAI_4 = { dailyFee: "311", rotationType: "WW" } as const;
const FO2412311 = { dailyFee: "311", rotationType: "21/14" } as const;
const SFO370 = { dailyFee: "370", rotationType: "WW" } as const;
const FOFIXED_5 = { dailyFee: "319", rotationType: "21/14" } as const;
const CPT329 = { dailyFee: "329", rotationType: "24/12", telecommunicationsAllowance: "20" } as const;
const CPT2412450 = { dailyFee: "450", rotationType: "24/12", telecommunicationsAllowance: "20" } as const;
const WWCPTNEW_6 = { dailyFee: "427", rotationType: "WW", telecommunicationsAllowance: "20" } as const;
const CPTAAI_6 = { dailyFee: "438", rotationType: "WW", telecommunicationsAllowance: "20" } as const;

const testCases: TestCase[] = [
    {
        title: "Sick days should not be paid if they follow an off day, even if it's outside of NIA",
        input: Input(2024, 6, "JOLJ", "SIN", CPT2412450),
        output: rowsToObjects(
            ["fee_entry_type"             , "units", "service_start_time", "service_end_time"],
            [DAILY_FEE                    , "7"    ,         "2024-06-01",       "2024-06-07"],
            [DAILY_FEE                    , "9"    ,         "2024-06-22",       "2024-06-30"],
            [PER_DIEM                     , "7"    ,         "2024-06-01",       "2024-06-07"],
            [PER_DIEM                     , "9"    ,         "2024-06-22",       "2024-06-30"],
            [PHONE_AND_INTERNET_ALLOWANCE , "1"    ,         "2024-06-01",       "2024-06-30"],
        ),
    },
    {
        title: "Never pay Per Diem for days that have PXP",
        input: Input(2024, 6, "AMAM", "JNB", INS2114541),
        output: rowsToObjects(
            ["fee_entry_type"             , "units", "service_start_time", "service_end_time"],
            [DAILY_FEE                    ,  "15"  ,         "2024-06-03",       "2024-06-17"],
            [DAILY_FEE                    ,  "5"   ,         "2024-06-26",       "2024-06-30"],
            [PER_DIEM                     ,  "14"  ,         "2024-06-03",       "2024-06-16"],
            [PER_DIEM                     ,  "4"   ,         "2024-06-27",       "2024-06-30"],
            [INSTRUCTOR_FEE               ,  "1"   ,         "2024-06-01",       "2024-06-30"],
            [PHONE_AND_INTERNET_ALLOWANCE ,  "1"   ,         "2024-06-01",       "2024-06-30"],
        ),
    },
    {
        title: "DUT activity code even in NIA apparently should be subject to Per Diem - only PXP code is not paid Per Diem",
        input: Input(2024, 6, "JCOU", "LIL", WWCPTINS_12),
        output: rowsToObjects(
            ["fee_entry_type"            , "units", "service_start_time", "service_end_time"],
            [DAILY_FEE                   , "14"    ,        "2024-06-01",       "2024-06-14"],
            [DAILY_FEE                   , "6"     ,        "2024-06-25",       "2024-06-30"],
            [PER_DIEM                    , "14"    ,        "2024-06-01",       "2024-06-14"],
            [PER_DIEM                    , "6"     ,        "2024-06-25",       "2024-06-30"],
            [OVER_TIME_FD                , "42.80" ,        "2024-05-13",       "2024-06-11"],
            [INSTRUCTOR_FEE              , "1"     ,        "2024-06-01",       "2024-06-30"],
            [PHONE_AND_INTERNET_ALLOWANCE, "1"     ,        "2024-06-01",       "2024-06-30"],
        ),
    },
    {
        title: "Departure is before midnight by local time - have to count that as an extra day",
        input: Input(2024, 4, "GUGR", "MIA", CPTAAI_6),
        output: rowsToObjects(
            ["fee_entry_type"              , "units", "service_start_time", "service_end_time"],
            [DAILY_FEE                     ,     "8",         "2024-04-16",       "2024-04-23"],
            [DAILY_FEE                     ,     "6",         "2024-04-25",       "2024-04-30"],
            [PER_DIEM                      ,     "8",         "2024-04-16",       "2024-04-23"],
            [PER_DIEM                      ,     "6",         "2024-04-25",       "2024-04-30"],
            [PHONE_AND_INTERNET_ALLOWANCE  ,     "1",         "2024-04-01",       "2024-04-30"],
        ),
    },
    {
        title: "First day of month has no explicit OFF Day activity, but it was spent at NIA and nothing happened and it is not surrounded by duty flights - so it should not receive daily fee. Also PXP example",
        input: Input(2024, 6, "CHFL", "BGO", CPT329),
        output: rowsToObjects(
            ["fee_entry_type"             , "units", "service_start_time", "service_end_time"],
            [DAILY_FEE                    ,    "25",         "2024-06-02",       "2024-06-26"],
            [PER_DIEM                     ,    "23",         "2024-06-03",       "2024-06-25"],
            [PHONE_AND_INTERNET_ALLOWANCE ,     "1",         "2024-06-01",       "2024-06-30"],
        ),
    },
    {
        title: "First day of month has no explicit OFF Day activity, but it was spent at NIA and nothing happened and it is not surrounded by duty flights - so it should not receive daily fee",
        input: Input(2024, 6, "DALA", "FLR", FO2412311),
        output: rowsToObjects(
            ["fee_entry_type", "units", "service_start_time", "service_end_time"],
            [DAILY_FEE       , "24"   ,         "2024-06-02",       "2024-06-25"],
            [DAILY_FEE       , "2"    ,         "2024-06-29",       "2024-06-30"],
            [PER_DIEM        , "24"   ,         "2024-06-02",       "2024-06-25"],
            [PER_DIEM        , "2"    ,         "2024-06-29",       "2024-06-30"],
            [OVER_TIME_FD    , "9.08" ,         "2024-06-01",       "2024-06-25"],
        ),
    },
    {
        title: "One more example of off days without explicit off day activity",
        input: Input(2024, 6, "VOKE", "PMI", INS2114516),
        output: rowsToObjects(
            ["fee_entry_type"            , "units", "service_start_time", "service_end_time"],
            [DAILY_FEE                   ,    "16",         "2024-06-15",       "2024-06-30"],
            [PER_DIEM                    ,    "16",         "2024-06-15",       "2024-06-30"],
            [INSTRUCTOR_FEE              ,     "1",         "2024-06-01",       "2024-06-30"],
            [PHONE_AND_INTERNET_ALLOWANCE,     "1",         "2024-06-01",       "2024-06-30"],
        ),
    },
    {
        title: "End of contract example - should not pay for the days after EOC even though there are no explicit OFF Day activities... should probably consider EOC an end of rotation same as we do with ULV and other activities",
        input: Input(2024, 6, "MPME", "AMS", SFO370),
        output: rowsToObjects(
            ["fee_entry_type", "units", "service_start_time", "service_end_time"],
            [DAILY_FEE       ,     "8",         "2024-06-01",       "2024-06-08"],
            [PER_DIEM        ,     "8",         "2024-06-01",       "2024-06-08"],
        ),
    },
    {
        title: "An example that illustrates that we definitely should not rely on daynumberinrotation: the numbering is messed up here and 7 hours overtime that results in from such calculation is on totally wrong dates",
        input: Input(2024, 6, "TOBE", "VIE", FOAAI_4),
        output: rowsToObjects(
            ["fee_entry_type"             , "units", "service_start_time", "service_end_time"],
            [ DAILY_FEE                   ,    "14",         "2024-06-01",       "2024-06-14"],
            [ PER_DIEM                    ,    "14",         "2024-06-01",       "2024-06-14"],
            [ OVER_TIME_FD                ,  "6.08",         "2024-05-09",       "2024-06-07"],
        ),
    },
    {
        title: "Off day glued to the last day of current month - should pay overtime",
        input: Input(2024, 5, "DAMC", "MAN", WWCPTINS_12),
        output: rowsToObjects(
            ["fee_entry_type"             , "units", "service_start_time", "service_end_time"],
            [ DAILY_FEE                   ,    "22",         "2024-05-10",       "2024-05-31"],
            [ PER_DIEM                    ,    "21",         "2024-05-10",       "2024-05-30"],
            [ OVER_TIME_FD                ,  "4.28",         "2024-05-10",       "2024-05-31"],
            [ INSTRUCTOR_FEE              ,     "1",         "2024-05-01",       "2024-05-31"],
            [ PHONE_AND_INTERNET_ALLOWANCE,     "1",         "2024-05-01",       "2024-05-31"],
        ),
    },
    {
        title: "Off day glued to the last day of previous month - should not pay same overtime again",
        input: Input(2024, 6, "DAMC", "MAN", WWCPTINS_12),
        output: rowsToObjects(
            ["fee_entry_type"             , "units", "service_start_time", "service_end_time"],
            [ DAILY_FEE                   ,    "12",         "2024-06-19",       "2024-06-30"],
            [ PER_DIEM                    ,    "12",         "2024-06-19",       "2024-06-30"],
            [ INSTRUCTOR_FEE              ,     "1",         "2024-06-01",       "2024-06-30"],
            [ PHONE_AND_INTERNET_ALLOWANCE,     "1",         "2024-06-01",       "2024-06-30"],
        ),
    },
    {
        title: "Regardless of whether it's a fixed pattern or not, the rotation overtime is calculated for up to 30 days on duty in a row",
        input: Input(2024, 6, "AMAZ", "FCO", FOFIXED_5),
        output: rowsToObjects(
            ["fee_entry_type"             , "units", "service_start_time", "service_end_time"],
            [DAILY_FEE                    ,    "30",         "2024-06-01",       "2024-06-30"],
            [PER_DIEM                     ,    "30",         "2024-06-01",       "2024-06-30"],
            [OVER_TIME_FD                 ,  "8.27",         "2024-05-22",       "2024-06-20"],
        ),
    },
    {
        title: "Rotation started before preceding month - must make sure we don't pay for same day twice in such cases due to relying on daynumberinrotation",
        input: Input(2024, 6, "PASU", "ARN", WWCPTNEW_6),
        output: rowsToObjects(
            ["fee_entry_type"             , "units", "service_start_time", "service_end_time"],
            [DAILY_FEE                    ,    "16",         "2024-06-01",       "2024-06-16"],
            [PER_DIEM                     ,    "16",         "2024-06-01",       "2024-06-16"],
            [OVER_TIME_FD                 ,  "8.38",         "2024-05-15",       "2024-06-13"],
            [PHONE_AND_INTERNET_ALLOWANCE ,     "1",         "2024-06-01",       "2024-06-30"],
        ),
    },
];

async function testRotationFromPastMonth() {
    const year = 2023;
    const month = 12;
    const navEntry = {
        No_: brand<CrewCode>("CHFL"),
        CompanyName: brand<CompanyName>("Airborne - Malta"),
    };
    const personRoster = await fetchPersonRoster({ year, month }, { navEntry });
    const rotationDays = getRotationDays(personRoster) ?? neverNull("personRoster");
    const precedingUnterminatedRotationDays = await getPrecedingUnterminatedRotationDays({
        employeeCode: toCrewCodeUc(navEntry.No_), company: brand("Airborne - Malta"), year, month,
    });
    const overtimes = getTerminatedRotationBlockHours(rotationDays, precedingUnterminatedRotationDays, 30);
    assertSubTree([
        {
            startDay: { Date: "2023-11-05" },
            endDay: { Date: "2023-12-04" },
            blockHours: 74.6,
        },
        {
            // rotation period is 30 days, so 31st day marks the start of a new rotation
            startDay: { Date: "2023-12-05" },
            endDay: { Date: "2023-12-06" },
        },
    ], overtimes);
}

async function getActualOutput(input: TestCase["input"]): Promise<ImpersonalSalaryJournalRecord[]> {
    const iataToIana = await getIataToIana();

    const whenPersonRoster = api.Roster.getAirAtlantaRoster({
        ...input, employeeCode: input.No_, company: brand("Airborne - Malta"),
    });
    const whenPrecedingUnterminatedRotationDays = getPrecedingUnterminatedRotationDays({
        ...input, employeeCode: input.No_, company: brand("Airborne - Malta"),
    });

    const params = {
        ...input,
        navEntry: {
            ...input,
            JobTitle: "CAPTAIN",
        },
        precedingUnterminatedRotationDays: await whenPrecedingUnterminatedRotationDays,
        personRoster: await whenPersonRoster,
        latestContractData: null,
    };
    return calculateFeesForFlightDeckPerson(params, iataToIana, input.officerFees);
}

export default async function FeeCalculatorFlightDeckTest() {
    let i = 0;
    for (const testCase of testCases) {
        console.info("Processing test case #" + (i++) + ": " + testCase.title);
        const actualOutput = await getActualOutput(testCase.input);
        assertSubTree(testCase.output, actualOutput);
        console.log("                     OK");
    }
    await testRotationFromPastMonth();
};
