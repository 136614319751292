import * as React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import type {
    BoxedPayslip,
    BoxedPayslipAndAllCompany, PayEntry
} from "../types/NavisionPayslip";
import type { COMPANYNAME,
    CompanyName,
    PayrollLocator
} from "../features/Api";
import api from "../features/Api";
import type {
    INavInvoiceConfirmation,
    NavInvoiceConfirmationStatusServer, StoredFinalizationData, StoredFinalizationsMapping,
} from "../types/Api/Invoice";
import DepartmentFilterSelect from "../components/DepartmentFilterSelect";
import CompanyFilterSelect from "../components/CompanyFilterSelect";
import type {
    FilterStatus,
    RankedStatus } from "../utils/invoiceListingUtils";


import { STATUS_FILTERS
} from "../utils/invoiceListingUtils";


import { type AbsoluteMonth, getPastMonth } from "../utils/dates";
import { YearAndMonthFilter } from "../components/YearAndMonthFilter";
import CustomFields from "../utils/CompanySpecificFields";
import { getCurrencyCode, getCurrencySymbol } from "../utils/currency";
import { getMatchingPayroll, isSoePayEntry } from "../features/payrollUtils";
import type { AllCompany } from "../features/StaticData";
import { getAllCompanies, getPayrollFinalizationsMapping } from "../features/StaticData";
import { FeeStatementInvoiceListRow } from "./FeeStatementInvoiceListRow";
import type { Workbook } from "exceljs";
import { IsolatedReact } from "../utils/IsolatedReact";
import ActionsQueue from "../utils/ActionsQueue";
import { ImmutableMap } from "../utils/ImmutableMap";
import DownloadFeesTableButtonPanel from "../components/fee-statement-invoices-list-approver/DownloadFeesTableButtonPanel";
import type { PayEntriesMapping } from "../utils/feeStatementInvoiceUtils";
import { getPayEntries } from "../utils/feeStatementInvoiceUtils";
import _ from "lodash";
import CompanySpecificFields from "../utils/CompanySpecificFields";
import { toCOMPANYNAMEUC } from "../utils/typing";

declare global {
    interface Window {
        WHEN_EXCELJS: Promise<{ Workbook: typeof Workbook }>,
    }
}

function computeCompanyRecords(
    allRecords: BoxedPayslipAndAllCompany[] | undefined,
    filterCompany: CompanyName | undefined
) {
    if (!allRecords) {
        return undefined;
    } else if (!filterCompany) {
        return allRecords;
    } else {
        return allRecords.filter(ar => ar.allCompany.COMPANYNAME === filterCompany);
    }
}

function computeDepartmentRecords(
    records: BoxedPayslipAndAllCompany[] | undefined,
    filterDepartment: number
) {
    if (!records) {
        return undefined;
    }
    if (!filterDepartment) {
        return records;
    }
    return records.filter(r => {
        const code = +(new CustomFields(r.allCompany, r.payslip).departmentCode || "0");
        return +code === filterDepartment;
    });
}

function computeRecordToStatus(
    locator: PayrollLocator,
    records: BoxedPayslip[],
    employeeCodeToConfirmations: Record<string, INavInvoiceConfirmation[]>,
    navCompanyData: AllCompany,
    payrollFinalizations: StoredFinalizationsMapping<StoredFinalizationData>
): RecordToStatus {
    const entries: [BoxedPayslip, RankedStatus][] = [];
    for (const record of records) {
        const crewCode = record.payslip.Kennitala.toUpperCase();
        const confirmations = employeeCodeToConfirmations[crewCode] ?? [];
        const confirmation =
            confirmations.find(c => c.confirming_party === "CLIENT") ??
            confirmations.find(c => c.confirming_party === "CONTRACTOR");
        let status: RankedStatus;
        if (!confirmation) {
            const department = +(new CustomFields(navCompanyData, record.payslip).departmentCode || "0");
            const finalization = getMatchingPayroll(payrollFinalizations, { ...locator, department });
            if (finalization) {
                status = "SENT_TO_CONTRACTOR_FOR_CONFIRMATION";
            } else {
                status = "NOT_FINALIZED";
            }
        } else {
            status = confirmation.status;
        }
        entries.push([record, status]);
    }
    return ImmutableMap.create<BoxedPayslip, RankedStatus>(entries);
}

function computeFilterStatusToCount(
    departmentRecords: BoxedPayslip[] | undefined,
    filterStatusToRecords: ReadonlyMap<BoxedPayslip, RankedStatus> | undefined
): Record<FilterStatus, number> | undefined {
    if (!departmentRecords || !filterStatusToRecords) {
        return undefined;
    }
    const result: Record<FilterStatus, number> = {
        APPROVED: 0, PENDING: 0, REJECTED: 0,
    };
    for (const record of departmentRecords) {
        const status = filterStatusToRecords.get(record);
        const filter = STATUS_FILTERS.find(f => f.statusCheck(status));
        if (filter) {
            ++result[filter.filterType];
        }
    }
    return result;
}

/** reusing same array instance to not trigger rerender */
const EMPTY_ARRAY = Object.freeze([] as const);

function computeFrequentFeeEntryTypes(
    records: BoxedPayslipAndAllCompany[] | undefined,
    payEntriesMapping: PayEntriesMapping
): readonly { id: number, description: string }[] {
    if (!records) {
        return EMPTY_ARRAY;
    }
    const idToLastDescription: Record<number, string> = {};
    const idToOccurrences: Record<number, number> = {};
    for (const record of records) {
        const typeIds = new Set<number>();
        for (const transfer of getPayEntries(record, payEntriesMapping) ?? []) {
            if (transfer.Tegund === 2 || +transfer.Einingar === 1) {
                continue;
            }
            const id = transfer.Faerslutegund;
            typeIds.add(id);
            idToLastDescription[id] = transfer["Texti a launasedli"];
        }
        for (const id of typeIds) {
            idToOccurrences[id] = idToOccurrences[id] ?? 0;
            idToOccurrences[id] += 1;
        }
    }
    return Object.entries(idToOccurrences)
        .filter(entry => !isSoePayEntry(+entry[0]))
        .sort((a,b) => b[1] - a[1]).slice(0, 5)
        .map(a => ({
            id: +a[0],
            description: idToLastDescription[+a[0]],
        }));
}

type RecordToStatus = ImmutableMap<BoxedPayslip, RankedStatus>;

function useStateRecordToStatus() {
    const [recordToStatus, setRecordToStatus] = useState<RecordToStatus>();
    const recordToStatusRef = useRef<RecordToStatus>();
    return {
        get current() {
            return recordToStatusRef.current;
        },
        get reactive() {
            return recordToStatus;
        },
        set both(value: RecordToStatus | undefined) {
            recordToStatusRef.current = value;
            setRecordToStatus(value);
        },
    };
}

async function fetchRecordToStatus(yearMonth: AbsoluteMonth, allCompany: AllCompany) {
    const payrollLocator = { ...yearMonth, company: allCompany.COMPANYNAME };
    const whenEmployeeCodeToConfirmations = api.Invoice
        .GetNavInvoiceConfirmationsListForApprover(payrollLocator)
        .then(list => _.groupBy(list, c => c.employee_code.toUpperCase()));
    const whenRecords = api.Invoice
        .GetFeeStatementInvoicesListForApprover(payrollLocator);
    const whenPayrollFinalizationsMapping = getPayrollFinalizationsMapping();

    const employeeCodeToConfirmations = await whenEmployeeCodeToConfirmations;
    const records = await whenRecords;
    const payrollFinalizationsMapping = await whenPayrollFinalizationsMapping;

    return computeRecordToStatus(
        payrollLocator, records, employeeCodeToConfirmations,
        allCompany, payrollFinalizationsMapping
    );
}

async function getAllApprovalCompanies(): Promise<AllCompany[]> {
    const allCompanies = await getAllCompanies();
    const approvalCompanies = window.EMPLOYEE_INFO?.ApprovalCompaniesList ?? [];
    return approvalCompanies.flatMap(companyId => {
        const allCompany = Object.values(allCompanies).find(
            ac => ac.portalEntry && ac.portalEntry.Id === companyId
        );
        if (!allCompany) {
            return EMPTY_ARRAY;
        } else {
            return [allCompany];
        }
    });
}

async function getCompaniesWithRecords(yearMonth: AbsoluteMonth, allApprovalCompanies: AllCompany[]) {
    const companiesWithRecordsToStatus = await Promise.all(
        allApprovalCompanies.map(async allCompany => {
            const recordToStatus = await fetchRecordToStatus(yearMonth, allCompany);
            return { allCompany, recordToStatus };
        })
    );
    const allRecordToStatusEntries: [BoxedPayslipAndAllCompany, RankedStatus][] = [];
    const allRecords: BoxedPayslipAndAllCompany[] = [];
    for (const { allCompany, recordToStatus } of companiesWithRecordsToStatus) {
        for (const [record, status] of recordToStatus.entries()) {
            const accompaniedRecord = { ...record, allCompany };
            allRecordToStatusEntries.push([accompaniedRecord, status]);
            allRecords.push(accompaniedRecord);
        }
    }
    const allRecordToStatus = ImmutableMap.create(allRecordToStatusEntries);
    allRecords.sort((a,b) => {
        const aStatus = allRecordToStatus.get(a);
        const bStatus = allRecordToStatus.get(b);
        const aRank = STATUS_FILTERS.findIndex(sf => sf.statusCheck(aStatus));
        const bRank = STATUS_FILTERS.findIndex(sf => sf.statusCheck(bStatus));
        return aRank - bRank;
    });
    return [allRecords, allRecordToStatus] as const;
}

function computeCommonCurrencySymbol(companyRecords: BoxedPayslipAndAllCompany[] | undefined) {
    const isoCodes = new Set<string>();
    for (const { allCompany } of companyRecords ?? []) {
        isoCodes.add(getCurrencyCode(allCompany.glSetup["LCY Code"]));
    }
    if (isoCodes.size === 1) {
        return getCurrencySymbol([...isoCodes][0]);
    } else {
        return "¤";
    }
}

const AIRCRAFT_NOT_STORED = "Other";
type AIRCRAFT_NOT_STORED = typeof AIRCRAFT_NOT_STORED;

function getAircraft(record: BoxedPayslipAndAllCompany): string | AIRCRAFT_NOT_STORED {
    return new CompanySpecificFields(record.allCompany, record.payslip).aircraft || AIRCRAFT_NOT_STORED;
}

function computeAircraftOptions(
    departmentRecords: BoxedPayslipAndAllCompany[] | undefined
): ReadonlySet<string | AIRCRAFT_NOT_STORED> {
    if (!departmentRecords) {
        return new Set();
    }
    const aircraftOptions = new Set<string | AIRCRAFT_NOT_STORED>();
    for (const record of departmentRecords) {
        aircraftOptions.add(getAircraft(record));
    }
    return aircraftOptions;
}

function computeFilterAircraft(
    filterAircraftSelected: string | undefined,
    aircraftOptions: ReadonlySet<string | AIRCRAFT_NOT_STORED>
): string {
    if (!filterAircraftSelected || !aircraftOptions.has(filterAircraftSelected)) {
        return "";
    } else {
        return filterAircraftSelected;
    }
}

function computeAircraftRecords(
    departmentRecords: BoxedPayslipAndAllCompany[] | undefined,
    filterAircraft: string | AIRCRAFT_NOT_STORED
) {
    if (!departmentRecords) {
        return undefined;
    } else if (!filterAircraft) {
        return departmentRecords;
    } else {
        return departmentRecords.filter(dr => getAircraft(dr) === filterAircraft);
    }
}

export default function FeeStatementInvoicesListForApprover() {
    const { WHEN_EXCELJS } = window;
    const [yearMonth, setYearMonth] = useState<AbsoluteMonth>(getPastMonth(new Date()));
    const retrievalQueue = useRef(ActionsQueue()).current;
    const [filterStatus, setFilterStatus] = useState<FilterStatus>("PENDING");
    const [filterDepartment, setFilterDepartment] = useState(0);
    const [filterCompany, setFilterCompany] = useState<CompanyName>();

    const [loadingRecords, setLoadingRecords] = useState(true);

    const recordToStatus = useStateRecordToStatus();
    const [allRecords, setAllRecords] = useState<BoxedPayslipAndAllCompany[]>();
    const [payEntriesMapping, setPayEntriesMapping] = useState(
        ImmutableMap.create<COMPANYNAME, Record<number, PayEntry[]>>()
    );
    const companyRecords = IsolatedReact.useMemo(computeCompanyRecords, [allRecords, filterCompany]);
    const currencySymbol = IsolatedReact.useMemo(computeCommonCurrencySymbol, [companyRecords]);
    const departmentRecords = IsolatedReact.useMemo(computeDepartmentRecords, [companyRecords, filterDepartment]);

    const aircraftOptions = IsolatedReact.useMemo(
        computeAircraftOptions, [departmentRecords]
    );
    const [filterAircraftSelected, setFilterAircraftSelected] = useState<string>();
    const filterAircraft = IsolatedReact.useMemo(computeFilterAircraft, [filterAircraftSelected, aircraftOptions]);
    const aircraftRecords = IsolatedReact.useMemo(computeAircraftRecords, [departmentRecords, filterAircraft]);

    const filterStatusToCount = IsolatedReact.useMemo(computeFilterStatusToCount, [aircraftRecords, recordToStatus.reactive]);
    const statusCount = !filterStatusToCount ? undefined : filterStatusToCount[filterStatus];

    const getStatus = (record: BoxedPayslip) => {
        return !recordToStatus.reactive ? "DATA_LOADING" : recordToStatus.reactive.get(record) ?? "DATA_LOADING";
    };

    const frequentFeeEntryTypes = IsolatedReact.useMemo(
        computeFrequentFeeEntryTypes, [aircraftRecords, payEntriesMapping]
    );

    const onStatusUpdate = useCallback(
        (record: BoxedPayslip, status: NavInvoiceConfirmationStatusServer) => {
            if (!recordToStatus.current) {
                return;
            }
            recordToStatus.both = recordToStatus.current.set(record, status);
        },
        []
    );

    useEffect(() => {
        require("./FeeStatementInvoicesListForApprover.css");
        require("./InvoiceStatuses.css");
    }, []);

    useEffect(() => {
        retrievalQueue.enqueue(async () => {
            setAllRecords(undefined);
            recordToStatus.both = undefined;
            setLoadingRecords(true);
            try {
                const allApprovalCompanies = await getAllApprovalCompanies();
                const [allRecords, allRecordToStatus] = await getCompaniesWithRecords(yearMonth, allApprovalCompanies);
                setAllRecords(allRecords);
                recordToStatus.both = allRecordToStatus;
                await Promise.all(allApprovalCompanies.map(async allCompany => {
                    const company = allCompany.COMPANYNAME;
                    const payEntries = await api.Invoice.GetPayrollPayEntriesForApprover({ ...yearMonth, company });
                    setPayEntriesMapping(prevState => prevState.set(toCOMPANYNAMEUC(company), payEntries));
                }));
            } finally {
                setLoadingRecords(false);
            }
        });
    }, [yearMonth]);

    const handleCompanyChange = (value: CompanyName | null) => {
        setFilterDepartment(0);
        setFilterCompany(value ?? undefined);
    };

    const handlePayrollLocatorChange = function<
        TProp extends keyof typeof yearMonth
    >(value: (typeof yearMonth)[TProp], propertyName: TProp) {
        setYearMonth({ ...yearMonth, [propertyName]: value });
    };

    const showAircraftFilter = aircraftOptions.size > 1 ||
        aircraftOptions.size === 1 && [...aircraftOptions][0] !== AIRCRAFT_NOT_STORED;

    return <div className="client-automated-invoice-page">
            <div className="fee-statement-toolbar">
                {loadingRecords ? <span className="status-loading-animated-ellipsis">Loading Pay Entries</span> : <DownloadFeesTableButtonPanel
                    WHEN_EXCELJS={WHEN_EXCELJS}
                    departmentRecords={aircraftRecords}
                    payEntriesMapping={payEntriesMapping}
                    fileNameSuffix={`${
                        filterCompany ? filterCompany + " " : ""
                    }${
                        filterDepartment ? filterDepartment + " " : ""
                    }${yearMonth.month}-${yearMonth.year}`}
                />}
                {!filterStatusToCount ? <div className="status-loading-animated-ellipsis">Loading Statuses</div> : <div className="status-filter">
                    {STATUS_FILTERS.map(item => {
                        return <button
                            key={item.filterType}
                            data-status={item.filterType}
                            onClick={() => setFilterStatus(item.filterType)}
                            className={filterStatus === item.filterType ? "active" : ""}
                        >
                            {item.buttonText} ({filterStatusToCount[item.filterType]})
                        </button>;
                    })}
                </div>}
                <div className="fee-statement-filter-input-fields">
                    <YearAndMonthFilter
                        year={yearMonth.year}
                        month={yearMonth.month}
                        onMonthChange={(month) => handlePayrollLocatorChange(month, "month")}
                        onYearChange={(year) => handlePayrollLocatorChange(year, "year")}
                    />
                    <CompanyFilterSelect
                        allowNull={true}
                        onChange={(company) => handleCompanyChange(company)}
                        company={filterCompany ?? null}
                    />
                    {companyRecords && <DepartmentFilterSelect
                        company={filterCompany ?? null}
                        department={filterDepartment}
                        onChange={setFilterDepartment}
                        records={companyRecords}
                    />}
                    {showAircraftFilter && <label>
                        <span>Aircraft:</span>
                        {aircraftOptions.size === 1
                            ? <span>{[...aircraftOptions][0]}</span> : <select
                                className="form-element"
                                value={filterAircraft}
                                onChange={e => setFilterAircraftSelected(e.target.value)}
                            >
                                <option value="">*</option>
                                {[...aircraftOptions]
                                    .map(aircraft => <option key={aircraft} value={aircraft}>{aircraft}</option>)}
                            </select>}
                    </label>}
                </div>
            </div>

            <div className="fee-statement-table-wrapper">
                <table data-filter-status={filterStatus}>
                    <thead>
                    <tr>
                        <th className="number-field">#</th>
                        <th>Crew Code</th>
                        <th className="plainTextField">Name</th>
                        {frequentFeeEntryTypes.map(typeRecord => <th
                            className="number-field"
                            key={typeRecord.id}
                        >{typeRecord.description}</th>)}
                        <th className="number-field">Other {currencySymbol}</th>
                        <th className="number-field">Total {currencySymbol}</th>
                        <th className="text-centered-column-item">Status</th>
                        {filterStatus !== "REJECTED" && <th className="text-centered-column-item">Actions</th>}
                    </tr>
                    </thead>
                    <tbody>
                    {aircraftRecords && statusCount ? aircraftRecords.map((fullRecord, i) => {
                        const status = getStatus(fullRecord);
                        return <FeeStatementInvoiceListRow
                            key={fullRecord.payslip.Numer}
                            rowData={fullRecord}
                            payEntries={getPayEntries(fullRecord, payEntriesMapping)}
                            index={i}
                            status={status}
                            yearMonth={yearMonth}
                            showActions={filterStatus !== "REJECTED"}
                            onStatusUpdate={onStatusUpdate}
                            frequentFeeEntryTypes={frequentFeeEntryTypes}
                            currencySymbol={getCurrencySymbol(fullRecord.allCompany.glSetup["LCY Code"]) || "¤"}
                        />;
                    }) : loadingRecords ? <tr className="table-content-loading">
                        <td colSpan={6} className="status-loading-animated-ellipsis">Loading records</td>
                    </tr> : statusCount === 0 ? <tr className="table-content-loading">
                        <td colSpan={6}>No invoices in status {filterStatus}</td>
                    </tr> : <tr className="table-content-loading">
                        <td colSpan={6}>Failed to retrieve invoices</td>
                    </tr>}
                    </tbody>
                </table>
            </div>
    </div>;
}
