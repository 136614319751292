import StatusComment from "./StatusComment";
import * as React from "react";

import type InvoiceComment from "../types/Api/InvoiceComment";
import api from "../features/Api";

type Props = {
  invoiceId: number,
};

type State = {
  History: InvoiceComment[],
};

export default class InvoiceStatuses extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      History: [],
    };
  };

  componentDidMount() {
    api.Invoice.GetInvoiceStatusHistory({ id: this.props.invoiceId })
      .then((data: InvoiceComment[]) => {
        this.setState({
          History: data
        });
      });
  };

  render() {
    const comments = this.state.History.map((c) => {
      return (<StatusComment comment={c} key={c.Id}/>);
    });

    return (<div className="comments">
      {comments}
    </div>);
  }
}