import * as React from "react";
import { useState } from "react";
import api from "../../features/Api";
import type {
    INavInvoiceConfirmation,
    INavInvoiceConfirmationBase,
    NavInvoiceConfirmationStatusServer, NavInvoiceMessageModelBase,
} from "../../types/Api/Invoice";
import { amountDifferenceSinceLastConfirmation, getNewTotalText } from "../../utils/feeStatementInvoiceUtils";
import type {
    FeeStatementInvoiceConfirmationReportFormProps,
} from "../../types/feeStatementTypes";

const DISCREPANCY_FIXED_DEFAULT_COMMENT = "Thanks for your report, the data in invoice was amended according to your input. Please confirm the invoice if everything is alright now.";

export type FeeStatementInvoiceConfirmationReplyFormProps = FeeStatementInvoiceConfirmationReportFormProps & {
    confirmationData: {
        partyConfirmations: NonNullable<FeeStatementInvoiceConfirmationReportFormProps["confirmationData"]["partyConfirmations"]>,
    },
    appendNewMessage: (message: NavInvoiceMessageModelBase) => void,
};

export default function FeeStatementInvoiceConfirmationReplyForm(
    { confirmationData, appendNewMessage, processNewStatusMessage }: FeeStatementInvoiceConfirmationReplyFormProps
) {
    const {
        locator,
        fullPayslip,
        partyConfirmations,
        companyData
    } = confirmationData;
    const [discrepancyFixedComment, setDiscrepancyFixedComment] = useState<string>("");
    const [processingRequest, setProcessingRequest] = useState(false);

    const assertRepliableContractorReport = () => {
        const lastConfirmation = partyConfirmations.CONTRACTOR;
        if (!lastConfirmation || !window.EMPLOYEE_INFO?.IsAdmin) {
            return null;
        } else if (lastConfirmation.status === "DISCREPANCY_REPORTED") {
            return lastConfirmation;
        }
        if (lastConfirmation.status === "CONFIRMED" &&
            amountDifferenceSinceLastConfirmation(fullPayslip, lastConfirmation) < 0
        ) {
            return lastConfirmation;
        }
        return null;
    };

    const asRepliableContractorReport = assertRepliableContractorReport();

    const addComment = async () => {
        if (!discrepancyFixedComment.trim()) {
            alert("Please, enter the text of your comment.");
            return;
        }
        setProcessingRequest(true);
        const role = window.EMPLOYEE_INFO?.IsAdmin ? "OFFICER" : window.EMPLOYEE_INFO?.IsClient ? "CLIENT" : "CONTRACTOR";
        await api.Invoice.AddCommentToNavInvoice({
            ...locator,
            commentText: discrepancyFixedComment,
        }).then(() => {
            setDiscrepancyFixedComment("");
            appendNewMessage({
                author_role: role,
                message_text: discrepancyFixedComment,
            });
        }).finally(() => {
            setProcessingRequest(false);
        });
    };

    const reportFixedDiscrepancy = (lastConfirmation: INavInvoiceConfirmationBase | INavInvoiceConfirmation) => {
        const amountDifference = amountDifferenceSinceLastConfirmation(fullPayslip, lastConfirmation);
        if (!amountDifference && !discrepancyFixedComment.trim()) {
            alert("Write a comment explaining why contractor's report was not valid.");
            return;
        }
        const clientDiscrepancyFixedComment = discrepancyFixedComment.trim() || discrepancyFixedDefaultComment();
        setProcessingRequest(true);
        const whenSuccess = api.Invoice.ReportFixedInconsistencyInNavInvoice({
            ...locator,
            contractorEmail: fullPayslip.payslip["E-Mail"],
            contractorFirstName: fullPayslip.payslip["First Name"],
            subsidiaryDisplayName: companyData.company.Name,
            previousStatus: lastConfirmation.status as NavInvoiceConfirmationStatusServer,
            totalChanged: !!amountDifference,
            discrepancyFixedComment: clientDiscrepancyFixedComment,
        }).then(() => {
            setDiscrepancyFixedComment("");
        }).finally(() => {
            setProcessingRequest(false);
        });
        processNewStatusMessage(whenSuccess, {
            ...lastConfirmation,
            status: "DISCREPANCY_FIXED",
        }, {
            author_role: "OFFICER",
            message_text: clientDiscrepancyFixedComment,
        });
    };

    const discrepancyFixedDefaultComment = () => {
        return `${DISCREPANCY_FIXED_DEFAULT_COMMENT} ${getNewTotalText(companyData, fullPayslip)}`;
    };

    const getPlaceholderText = () => {
        return asRepliableContractorReport ? amountDifferenceSinceLastConfirmation(fullPayslip, asRepliableContractorReport)
            ? discrepancyFixedDefaultComment()
            : "Total in Navision not updated - please explain why contractor's report was not valid" : "Write a comment";
    };

    return <form className="rejectionFixedForm">
        <textarea
            value={discrepancyFixedComment}
            disabled={processingRequest}
            onChange={e => setDiscrepancyFixedComment(e.target.value)}
            placeholder={getPlaceholderText()}
            rows={4}
        ></textarea>
        <div className="commenting-buttons-wrapper">
            <button
                type="button"
                onClick={addComment}
                className="leave-comment-button"
                disabled={processingRequest}
            >Leave a comment
            </button>
            {asRepliableContractorReport && <button
                type="button"
                onClick={() => reportFixedDiscrepancy(asRepliableContractorReport)}
                className="send-back-button"
                disabled={processingRequest}
            >Send Back to Contractor
            </button>}
        </div>
    </form>;
}